import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'msc-comment',
    templateUrl: './comment.component.html',
    providers: [DatePipe],
})

export class CommentComponent {
    @Input() comment: any;
    @Input() me: any;
    @Input() companyOwner: any;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();

    constructor(
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        if (this.comment.created_at) {
            this.comment.created_at = this.datePipe.transform(new Date(this.comment?.created_at), DATE_DEFAULT_FORMAT);
        }
    }

    onDeleteComment(): void {
        this.onDelete.emit(this.comment?.id);
    }

    checkCanDelete(): boolean {
        if (this.companyOwner) {
            return true;
        }
        if (this.me && this.comment?.account) {
            return this.me?.id === this.comment?.account?.id;
        }
    }

    getDate(date: string): string {
        return this.datePipe.transform(new Date(date), DATE_DEFAULT_FORMAT);
    }
}
