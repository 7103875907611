import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media, MediaProvider } from '@stuplay';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'media-holder',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.less']
})
export class MediaComponent {
    @Input() media: Media;
    @Input() cover: string;
    @Input() mini: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() pointer: boolean = false;
    @Input() previewFile: boolean = false;
    @Input() downloadable: boolean = true;
    @Input() reloadUrl: boolean;
    @Output() mediaStatus: EventEmitter<boolean> = new EventEmitter();
    status: number;
    init: boolean = false;

    constructor(
        private mediaProvider: MediaProvider,
        private modalService: NgbModal,
    ) { }

    ngOnChanges(): void {
        if (this.media && this.media?.type !== 'EMBED' && this.media?.status !== 1) {
            if (this.media?.type === 'IMG' && this.media?.storage === 'ext') {
                this.status = 1;
            } else {
                this.mediaProvider.getStatus(this.media?.id).subscribe((finalStatus: any) => {
                    if (finalStatus.status === 0) {
                        const interval = setInterval(() => {
                            this.mediaProvider.getStatus(this.media?.id).subscribe((media: any) => {
                                if (media.status === -1) {
                                    this.mediaStatus.emit(false);
                                    this.status = -1;
                                    clearInterval(interval);
                                } else if (media.status === 1) {
                                    this.mediaProvider.getMedia(this.media?.id).subscribe((media: any) => {
                                        this.status = 1;
                                        this.media = media;
                                        this.mediaStatus.emit(true);
                                    });
                                    clearInterval(interval);
                                } else {
                                    this.status = 0;
                                }
                            });
                        }, 2000);
                    } else {
                        this.status = finalStatus.status;
                    }
                });
            }
        } else {
            this.status = 1;
        }
        this.init = true;
    }

    modalOpen(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom', centered: true };
        if (this.mini) {
            this.modalService.open(content, options);
        }
    }
}
