import { StuplayConfig } from "../stuplay-config";
import { HttpClient } from '@angular/common/http';
import { CourseCamp } from "../course-camp/course-camp";

export class Camp {
    id: number;
    name: string;
    slug: string;
    theme: string;
    isPaused: boolean;
    headline: string;
    courseCampCount: number;
    usersCount: number;
    welcomeText: string;
    isPublic: boolean;
    currency: string;
    published: boolean;
    courseCamps: CourseCamp[];

    constructor(camp: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(camp);
    }

    extends(camp: any): Camp {
        Object.assign(this, camp);
        if (this.courseCamps && this.courseCamps.length > 0) {
            this.courseCamps = this.courseCamps.map((courseCamp) => new CourseCamp(courseCamp, this.http, this.stuplayConfig));
        }

        return this;
    }
}