import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { Media } from '@stuplay';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'media-button-upload',
    templateUrl: './media-button-upload.component.html',
})

export class MediaButtonUploadComponent {
    @Input() type: string = '';
    @Input() hidden: boolean = false;
    @Input() media: Media;
    @Output() updateMedia = new EventEmitter();
    @Output() deleteMedia = new EventEmitter();
    @Output() mediaModalOpen: EventEmitter<boolean> = new EventEmitter();
    @Output() onError = new EventEmitter();
    @ViewChild('downloadableMedia', { static: false }) aDownload: ElementRef;

    constructor(
        private renderer: Renderer2,
        private modalService: NgbModal,
    ) { }

    modalOpen(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
        this.mediaModalOpen.emit();
    }

    modalClose() {
        this.modalService.dismissAll();
        this.mediaModalOpen.emit();
    }

    onUpdateMedia(media: any) {
        if (this.media !== media && media?.id) {
            this.media = media;
            this.updateMedia.emit(this.media);
        } else {
            this.onError.emit();
        }

        this.modalService.dismissAll();
    }

    deletedMedia(media: any) {
        this.media = media;
        this.deleteMedia.emit(this.media);
    }

    displayMedia(): void {
        const event = new MouseEvent('click', { bubbles: true });
        (this.aDownload.nativeElement as any)['dispatchEvent'].apply(this.aDownload.nativeElement, [event]); // migration of code beneath
        // this.renderer.invokeElementMethod(this.aDownload.nativeElement, 'dispatchEvent', [event]);
    }

    dynamicUrl(media: any): string {
        let url: any;
        if (media.type === 'IMG') {
            url = `${environment.envVar.STORAGE_URL}/${media?.url}?size=raw`;
        } else {
            url = `${environment.envVar.STORAGE_URL}/${media?.originalUrl}`;
        }

        return url;
    }
}
