import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';
import { RedirectService } from '../../services/redirect.service';

export interface Toast {
    message: string,
    type: string,
    duration: number,
    redirect: string
}

@Component({
    selector: 'msc-toast',
    templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit {
    toasts: Toast[];

    constructor(private toastService: ToastService, private redirectService: RedirectService) {
        this.toasts = [];
    }

    ngOnInit(): void {
        this.toastService.listen().subscribe((toast) => {
            this.manageToast(toast);
        });
    }

    manageToast(toast: Toast): void {
        this.toasts.push(toast);

        const timeOut = setTimeout(() => {
            this.dismiss(toast);
            this.handleRedirect(toast.redirect);
            clearTimeout(timeOut);
        }, toast.duration);
    }

    dismiss(toast: Toast): void {
        const index = this.toasts.findIndex((data) => {
            return data === toast;
        });

        this.toasts.splice(index, 1)
    }

    handleRedirect(app: string) {
        if (app === 'camp') {
            this.redirectService.navigate('app', ['login', 'company']);
        } else if (app === 'studio') {
            this.redirectService.navigate('studio', []);
        }
    }
}
