<div [ngClass]="'question__list-style_' + question.param.listStyle" *ngIf="!questionSession.doneAt">
    <ng-container *ngIf="!isRadio">
        <label for="checkbox{{i}}" class="choice checkbox__container"
            *ngFor="let choice of internalQuestionSession?.answer.choices; let i = index"
            [ngClass]="{'choice_selected': choice.state}">
            <span class="choice__text">{{choice.choice}}</span>
            <label for="checkbox{{i}}" class="templab-checkbox">
                <input type="checkbox" class="templab-checkbox__input" id="checkbox{{i}}" name="choice{{choice.choice}}"
                    [(ngModel)]="choice.state" (change)="onChange()">
                <span class="templab-checkbox__checkmark"></span>
            </label>
        </label>
    </ng-container>
    <ng-container *ngIf="isRadio">
        <label for="radio{{i}}" class="choice"
            *ngFor="let choice of internalQuestionSession?.answer.choices; let i = index"
            [ngClass]="{'choice_selected': choice.state}">
            <span class="choice__text">{{choice.choice}}</span>
            <label for="radio{{i}}" class="input">
                <input type="radio" class="input-radiobox" id="radio{{i}}" name="radio" [checked]="choice.state"
                    (change)="singleSelect(choice)">
            </label>
        </label>
    </ng-container>
</div>

<div class="" *ngIf="questionSession.doneAt">
    <div class="m-flex m-actions">
        <div class="m-flex m-flex-3 m-switch">
            <div class="m-switch-item" [ngClass]="{'item--active': !solution}" (click)="changeTab()">
                <span class="">
                    {{ 'words.my-answer' | translate }}
                </span>
            </div>

            <div class="m-switch-item" [ngClass]="{'item--active': solution}" (click)="changeTab()">
                <span class="">
                    {{ 'words.solution' | translate }}
                </span>
            </div>
        </div>

        <div class="m-flex-7 m-flex--end m-ratio">
            {{ 'words.result' | translate }} : {{questionSession.ratio}}%  
        </div>
    </div>

    <div class="m-flex" *ngFor="let answer of questionSession.answer.choices; let index=index;">
        <div class="m-flex-1 m-data">
            <label class="" style="font-size: 1.5rem;">
                <input type="checkbox" *ngIf="!isRadio"
                    [ngModel]="!solution ? answer.state : questionSession.question?.content?.choices[index]?.state"
                    disabled>
                <input type="radio" *ngIf="isRadio"
                    [checked]="!solution ? answer.state : questionSession.question?.content?.choices[index]?.state"
                    disabled>
            </label>
        </div>

        <div class="m-flex m-flex-9 m-answer m-items-center">
            <span style="margin: 0 0 0 1rem;">{{ answer.choice }}</span>
        </div>
    </div>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>