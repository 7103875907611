<div class=" slider-progress-header">
    <!--barra de progress-->
    <div class="session__progress ">
        <span class="progress-bar">
            <span class="progress"
                [ngStyle]="{'width.%': (navigation.currentCourseSubscriptionSession.progress === null) ? 0 : navigation.currentCourseSubscriptionSession.progress}">
                <span class="progress-percentage">{{ navigation.currentCourseSubscriptionSession.progress === null ? 0 :
                    navigation.currentCourseSubscriptionSession.progress }} %</span>
            </span>
        </span>
    </div>
</div>

<!--code original-->
<div class="slide__navigate ">
    <div class="session__progress d-none ">
        <span class="progress-bar">
            <span class="progress"
                [ngStyle]="{'width.%': (navigation.currentCourseSubscriptionSession.progress === null) ? 0 : navigation.currentCourseSubscriptionSession.progress}"></span>
        </span>

        <span class="progress-percentage">{{ navigation.currentCourseSubscriptionSession.progress === null ? 0 :
            navigation.currentCourseSubscriptionSession.progress }} %</span>
    </div>
    <!--previous -- next-->
    <div class="slide__actions">
        <div class="slide__navigate__previous">
            <a class="" (click)="goPrev()">
                <i class="icon icon-arrow-ios-back-outline"></i>
                {{ 'word.previous' | translate }}
            </a>
        </div>

        <div class="slide__navigate__next" [ngClass]="{'disabled': disabled}">
            <a class="" (click)="goNext()">
                {{ 'word.next' | translate }}
                <i class="icon icon-arrow-ios-forward-outline"></i>
            </a>
        </div>
    </div>
</div>