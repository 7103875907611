import { Component } from '@angular/core';
import { Course, Survey, SurveyQuestion, SurveyProvider, CourseSubscriptionSession } from '@stuplay';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserProvider } from '../../../utils/services/user.provider';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-survey',
    templateUrl: './survey.component.html'
})

export class SurveyComponent {
    public courseSubscriptionSession: CourseSubscriptionSession;
    public course: Course | any;
    public company: any;
    survey: Survey;
    indexSelected: number = 0;
    rating: number = 2;
    stateNA: boolean[] = [];
    isAnonyme: boolean = true;
    user: any;
    preview: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private surveyProvider: SurveyProvider,
        private userProvider: UserProvider,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) {
        this.route.parent.data.subscribe(data => {
            this.course = data.course;
            this.courseSubscriptionSession = data.courseSubscriptionSession;
        });
    }

    ngOnInit() {
        this.company = this.storageService.get('company');

        if (this.course?.id) {
            this.userProvider.getCurrentUser().subscribe((res) => {
                this.user = res;
            });

            this.surveyProvider.getSurvey(this.course?.id, this.company?.id).subscribe((survey) => {
                this.survey = survey;
            });
        }
    }

    changeStateNA(question: any, content: any, index: number): void {
        this.stateNA[index] = !this.stateNA[index];
        this.saveSurveyQuestionAnswer(question, content, this.stateNA[index])
    }

    saveSurveyQuestionAnswer(question: SurveyQuestion, content: any, notApplicable: boolean): void {
        /*if (question.surveyQuestionAnswers[0].content === content) {
            content = null;
        }*/
        question.surveyQuestionAnswers[0].content = content;
        question.surveyQuestionAnswers[0].save({ course_id: this.course?.id, survey_question_id: question?.id, content: content, not_applicable: notApplicable }, question?.id).subscribe();
    }

    nextGroup() {
        this.indexSelected += 1;
        this.stateNA = [];
    }

    previousGroup() {
        this.indexSelected -= 1;
        this.stateNA = [];
    }

    checkRating(question: any, rating: any): boolean {
        if (question.surveyQuestionAnswers && question.surveyQuestionAnswers[0] && question.surveyQuestionAnswers[0].content) {
            return question.surveyQuestionAnswers[0].content === rating;
        } else {
            return false
        }
    }

    redirectToStats() {
        this.router.navigate(['course', this.course?.id, 'results']);
    }

    submitSurvey() {
        const params = {
            course_id: this.course?.id,
            email: this.user?.email,
            is_anonyme: this.isAnonyme
        };

        this.survey.submit(params).subscribe(() => {
            this.courseSubscriptionSession.doneSurvey = true;
        });
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }
}
