import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'result'
})
export class ResultPipe implements PipeTransform {
    transform(value: any): string {
        return (value === null) ? 'N/A' : value + '%';
    }
}
