import { Component, Input } from '@angular/core';
import { Media } from '@stuplay';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'media-sound',
    templateUrl: './sound.component.html',
    styleUrls: ['./sound.component.less']
})
export class MediaSoundComponent {
    @Input() media: Media;
    @Input() size: number;
    stockageUrl: string = environment.envVar.STORAGE_URL;

    constructor() {}

    onClick(e) {
        e.preventDefault();
    }
}
