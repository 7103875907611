<div class="alert-toast">
    <div *ngFor="let toast of toasts" [ngStyle]="{'animation': 'fadein 0.5s, fadeout 0.5s '+ (toast.duration - 500) / 1000 +'s'}" [class]="'alert-toast__message alert-toast__message--'+ toast.type" (click)="dismiss(toast)">
        <span class="alert-toast__content">
            {{ 'player.toast.'+ toast.message | translate }}
        </span>

<!--        <a class="alert-toast__close">-->
<!--            <i class="icon icon&#45;&#45;close"></i>-->
<!--        </a>-->
    </div>
</div>
