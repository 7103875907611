import { Component, OnDestroy, OnInit } from '@angular/core';
import { SectionContent, Video, SectionContentSession } from '@stuplay';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-video',
    templateUrl: 'video.component.html'
})
export class VideoComponent implements OnInit, OnDestroy {
    video: Video;
    videoSubscription: Subscription;
    sectionContent: SectionContent;
    sectionContentSession: SectionContentSession;

    constructor(
        private route: ActivatedRoute,
        public timerService: TimerService,
        private storageService: StorageService,
    ) {
        this.videoSubscription = this.route.paramMap.subscribe(() => {
            this.video = this.route.snapshot.data.video.video;
            this.sectionContent = this.route.snapshot.data.video;
            this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
            this.timerInit();
        });
    }

    ngOnInit(): void {
        this.sectionContent = this.route.snapshot.data.video;
        this.video = this.route.snapshot.data.video.video;
        this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
    }

    ngOnDestroy(): void {
        this.videoSubscription.unsubscribe();
    }

    activityFinished() {
        this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe();
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
