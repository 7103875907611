import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StuplayConfig } from '../stuplay-config';
import { getParams } from '../helpers';

@Injectable()
export class ApplicationProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    get(id: number|string, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/company/${id}/applications`, {params: getParams(params)});
    }

    updateSettings(id: number, applicationId: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/company/${id}/applications/${applicationId}/user-settings`, params);
    }
}
