import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'timeline-show-content',
    templateUrl: './timeline-show-content.component.html'
})
export class TimelineShowContentComponent implements OnInit {
    @Input() content: any;
    @Input() media: any;
    safeUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (this.content.link) {
            this.checkVideoUrl(this.content.link.videoIframe)
        }
    }

    checkVideoUrl(html: string): void {
        this.safeUrl = this.sanitizer.bypassSecurityTrustHtml(html)
    }
}
