import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScormModule, SectionContent, SectionContentSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { UserProvider } from '../../../utils/services/user.provider';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'app-scorm',
    templateUrl: './scorm.component.html',
})
export class ScormComponent {
    @Input() scorm: ScormModule;
    @ViewChild('iframeScorm', { static: false }) iframeScorm: any;

    public loading: boolean = false;
    public sectionContent: SectionContent;
    public sectionContentSession: SectionContentSession;
    public url: any;
    public is_preview: boolean = false;
    public session: any = {};
    public sent: boolean = false;
    public account: any;
    public scormSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private storageService: StorageService,
        private modalService: NgbModal,
        public userProvider: UserProvider,
        public timerService: TimerService,
    ) {
        this.scormSubscription = route.paramMap.subscribe(() => {
            this.scorm = this.route.snapshot.data.scorm.scorm;
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.scorm.url);
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
            this.sectionContent = this.route.snapshot.data?.scorm;
            this.timerInit();
        });
    }

    ngOnInit() {
        this.account = this.userProvider.currentUser;
        this.sectionContent = this.route.snapshot.data?.scorm;
        this.scorm = this.route.snapshot.data.scorm?.scorm;
        this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.scorm?.url);
    }

    ngAfterViewInit() {
        window.addEventListener('message', (message) => {
            if (['ScormInitialize'].indexOf(message?.data?.function) !== -1) {
                const res = {
                    'LMSInitialize': true,
                    'LMSGetLastError': 0,
                    'LMSGetErrorString': '',
                    'LMSGetDiagnostic': '',
                    'LMSFinish': '',
                    'cmi.core.student_id': this.account?.id,
                    'cmi.core.student_name': this.account?.name,
                    'cmi.core.lesson_location': '',
                    'cmi.core.credit': 'credit',
                    'cmi.core.lesson_status': 'incomplete',
                    'cmi.core.entry': '',
                    'cmi.core.score.raw': 0,
                    'cmi.core.score.max': 100,
                    'cmi.core.score.min': 0,
                    'cmi.core.total_time': 0,
                    'cmi.core.lesson_mode': this.is_preview ? 'browse' : 'normal',
                    'cmi.core.suspend_data': this.session?.current || '',
                    'cmi.launch_data': ''
                };
                const iframeWin = this.iframeScorm.nativeElement?.contentWindow;

                iframeWin.postMessage({
                    isCallback: true,
                    uuid: message.data.uuid,
                    data: res
                }, '*');
            }

            if (message.data.status === 'scorm') {
                if (['LMSSetValue', 'LMSCommit'].indexOf(message?.data?.function) !== -1) {
                    this[message?.data?.function](message?.data?.data);
                }

            }
        }, false);
    }

    LMSSetValue(data: any) {
        if (['cmi.core.lesson_status', 'cmi.lesson_status', 'cmi.completion_status'].indexOf(data.str) !== -1) {
            if (['passed', 'completed'].indexOf(data.value) !== -1 && this.sent === false) {
                this.sent = true;
            }
        } else if (['cmi.score.raw', 'cmi.core.score.raw'].indexOf(data.str) !== -1) {
            this.sectionContentSession.score = data.value;
            if (!this.is_preview) {
                this.sectionContentSession.update(false, this.storageService.get('company')?.id)
            }
        } else if (['cmi.suspend_data', 'cmi.core.suspend_data'].indexOf(data.str) !== -1) {
            // this.sectionContentSession.current = data.value;
            if (!this.is_preview) {
                this.sectionContentSession.update(false, this.storageService.get('company')?.id)
            }
        }
        return true;
    }

    LMSCommit(_data: any) {
        return true;
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    ngOnDestroy() { }

    activityFinished(event: any) {
        this.loading = true;
        this.sectionContentSession
            .update(event, this.storageService.get('company')?.id)
            .pipe(finalize(() => this.loading = false))
            .subscribe();
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
