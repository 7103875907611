<div class="slide slide_presentation">
    <h2 class="heading">
        <span>{{ 'player.scorm.name' | translate }}</span>{{sectionContent?.title}}
    </h2>

    <p *ngIf="sectionContent.description" class="multiline" [innerHTML]="sectionContent?.description | safe:'html'"></p>
    <div (click)='openModal(modalScorm)' class="card card-background">
        <div class="card__text" style="margin: auto">
            <p> {{ 'player.section-content.scorm.open-modal' | translate }}</p>
        </div>
    </div>
    <msc-next-prev-btn (isFinished)="activityFinished($event)" [validate]="true" [doneAt]="sectionContentSession.doneAt" [loading]="loading"></msc-next-prev-btn>
</div>

<ng-template #modalScorm let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="media-holder" style="height: 100%">
            <iframe *ngIf="modalScorm?.visible" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" #iframeScorm id="scorm_iframe" [src]="url" width="100%" height="100%" style="border: none; top: 10% !important;"></iframe>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalScorm let-modal>
    <div class="modal-dialog modal-full">
        <div class="modal-content">
            <span style="z-index: 1000" (click)="modal.close()" class="button btn-action_close">+</span>
            <div class="media-holder" style="height: 100%">
                <iframe *ngIf="modalScorm.visible" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"   #iframeScorm id="scorm_iframe" [src]="url" width="100%" height="100%" style="border: none; top: 10% !important;"></iframe>
             </div>
        </div>
    </div>
</ng-template> -->

<app-footer [context]="'scorm'" [contextId]="scorm?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>
