import { Account } from '../../account/account';
import { CourseRole } from './course-role/course-role';

export class Collaborator {
    account: Account;
    courseRole: CourseRole;
    isActive: boolean;
    isOwner: boolean;

    constructor(collaborator: any) {
        this.extends(collaborator);
    }

    extends(collaborator: any): Collaborator {
        Object.assign(this, collaborator);

        if (collaborator.account) {
            this.account = new Account(collaborator.account);
        }

        if (collaborator.role) {
            this.courseRole = new CourseRole(collaborator.role);
        }

        return this;
    }
}
