import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Injectable()
export class TimerService {
    @Output() inactivity: EventEmitter<boolean> = new EventEmitter();

    subscription: Subscription = new Subscription();
    subInactivity: Subscription = new Subscription();

    constructor(
        private storageService: StorageService,
    ) { }

    setTimer(session?: any, type?: string) {
        const key = (type === 'question') ? 'durationGlobal' : (type === 'content' ? 'duration' : null);
        this.resetTimer();
    
        if (key && session) {
            
            const timerLocale = timer(2000, 15000);
    
            this.subscription = timerLocale.subscribe(t => {
                session[key] += 15; 
    
                if (type === 'question') {
                    const params = {
                        notifyByMail: false,
                        requestCorrection: !!session?.doneAt,
                        company_id: this.storageService.get('company')?.id
                    };
    
                    session.update(params).subscribe();
                } else {
                    session.update(!!session?.doneAt, this.storageService.get('company')?.id).subscribe();
                }
            });
    
            this.checkInactivity();
        }
    }
    
    

    resetTimer(): void {
        this.subscription.unsubscribe();
        this.subscription = new Subscription();
    }

    checkInactivity(): void {
        this.resetInactivity();
        const timerLocale = timer(602000, 602000);
        this.subInactivity = timerLocale.subscribe(() => {
            this.inactivity.emit(true);
            this.resetInactivity();
            this.resetTimer();
        });
    }

    resetInactivity(): void {
        this.subInactivity.unsubscribe();
        this.subInactivity = new Subscription();
    }
}
