import { Media } from '../media';
import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../stuplay-config';

export class Journey {
    id: number;
    slug: string;
    name: string;
    title: string;
    description: string;
    type: string;
    language: any;
    duration: number;
    skills: string;
    usersLimit: number;
    autoReminders: number;
    contentsCount: number;
    eventsCount: number;
    templatesCount: number;
    externalContentsCount: number;
    contents?: any[];
    media?: Media;
    published: boolean;
    isUnpublished?: boolean;

    constructor(journey: any, private http: HttpClient, protected stuplayConfig: StuplayConfig) {
        this.extends(journey);
    }

    extends(journey: any): Journey {
        Object.assign(this, journey);
        if (this.media) {
            this.media = new Media(this.media, this.http, this.stuplayConfig);
        }
        return this;
    }
}
