<img class="author__image avatar" [src]="post?.account?.picture+'?size=48'" *ngIf="post?.account">
<span class="author__name" *ngIf="post?.account">
    {{ post.account.name }}
</span>

<span class="date">{{ getDate(post.createdAt) }}</span>

<div class="post__content">
    <timeline-show-content [content]="post?.content" [media]="post?.media"></timeline-show-content>

    <msc-link-manager [content]="post?.content?.message"></msc-link-manager>
</div>

<div class="post__comments">
    <ng-container *ngIf="post?.comments?.length">
        <div class="comment" *ngFor="let comment of post.comments">
            <msc-comment [me]="me" [companyOwner]="companyOwner" [comment]='comment' (onDelete)="deleteComment($event)"></msc-comment>
        </div>
    </ng-container>

    <form class="new-post" #postForm>
        <timeline-add-content (addContent)="addComment($event, post?.id, postForm)" [placeholder]="('player.timeline.placeholder.answer' | translate)"></timeline-add-content>
    </form>
</div>

<timeline-delete-content *ngIf="checkCanDelete()" (deleteContent)="onDeletePost()"></timeline-delete-content>
