import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { RefreshAuthService } from './refresh-auth.service';
import { AuthProvider, Auth } from '@stuplay';
import { RedirectService } from './redirect.service';
import { UrlHelper } from '../helpers';

@Injectable()
export class AuthService {

    constructor(
        private cookieService: CookieService,
        private refreshAuthService: RefreshAuthService,
        private redirectService: RedirectService,
        private authProvider: AuthProvider,
    ) {
        this.listener();
    }

    private listener(): void {
        this.refreshAuthService.listenLogout().subscribe(() => {
            this.logout(true);
        });
    }

    login(credentials: any): Observable<any> {
        this.clearCookies();
        return this.authProvider
            .login(credentials)
            .pipe(
                map((data: Auth) => {
                    this.createCookies(data);
                    return data;
                })
            );
    }
    /**
     * Create cookie with user data
     * @param {Auth} data - Data authentication
     */
     private createCookies(data: Auth): void {
        this.clearCookies();
        this.cookieService.set(environment.tokenName, data.access_token, 7, '/', UrlHelper.getDomain(), false, 'Lax');
        this.cookieService.set(environment.refreshTokenName, data.refresh_token, 7, '/', UrlHelper.getDomain(), false, 'Lax');
        sessionStorage.setItem(environment.tokenSessionName, data.access_token);
    }

    public clearCookies(): void {
        this.cookieService.delete(environment.tokenName, '/', `.${UrlHelper.getDomain()}`, false);
        this.cookieService.delete(environment.refreshTokenName, '/', `.${UrlHelper.getDomain()}`, false);
        sessionStorage.removeItem(environment.tokenSessionName);
    }

    authenticateMethod(params: any): Observable<any> {
        return this.authProvider.authenticateMethod(params);
    }

    loggedIn(): boolean {
        const token = this.cookieService.get(environment.tokenName);
        if (token && token.length) {
            sessionStorage.setItem(environment.tokenSessionName, this.cookieService.get(environment.tokenName));
            return true;
        } else {
            return false;
        }
    }

    refresh(): Observable<any> {
        return this.authProvider.refresh(this.cookieService.get(environment.refreshTokenName)).pipe(
            map((auth: Auth) => {
                this.createCookies(auth);
                return auth;
            }),
            catchError((err) => {
                this.logout(true);
                return throwError(err);
            })
        );
    }

    logout(force?: boolean): void {
        if (!force) {
            this.authProvider.logout().subscribe(() => {
                window.location.replace('login');
            });
        }
        this.cookieService.deleteAll('/', `.${UrlHelper.getDomain()}`, false, 'Lax');
        sessionStorage.clear();

        if (force) {
            window.location.replace('login');
        }
    }
}
