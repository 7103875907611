import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StuplayConfig, Media } from '@stuplay';

@Component({
    selector: 'library-folders',
    templateUrl: './library-folders.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LibraryFoldersComponent {
    @Input() item: any;
    @Output() updateMedia = new EventEmitter();

    public folder: any;
    public medias: Media[];
    public processing: boolean = false;

    constructor(
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        private stuplayConfig: StuplayConfig,
    ) { }

    ngOnInit(): void {
        this.http.get(`${this.stuplayConfig.envVar.API_URL}/folder/0`)
            .subscribe((data: any) => {
                this.folder = data;
                this.medias = this.folder.folder.medias;
                this.ref.detectChanges();
            });
    }

    onUpdatedMedia(media: any): void {
        this.updateMedia.emit(media);
    }

    loadMore(): void {
        this.processing = true;
        this.http.get(`${this.stuplayConfig.envVar.API_URL}/folder/0?page=${this.folder.pagination.currentPage + 1}`)
            .subscribe((data: any) => {
                this.folder = data;
                this.folder.folder.medias.forEach((media: any) => this.medias.push(media));
                this.processing = false;
                this.ref.detectChanges();
            });
    }
}
