import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SectionContent, SectionContentSession, Media } from '@stuplay';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-lesson-media',
    templateUrl: 'lesson-media.component.html'
})
export class LessonMediaComponent implements OnInit, OnDestroy {
    @ViewChild('isDownloadable', { static: false }) aDownload: ElementRef;

    public subscription: Subscription;
    public blob: string = 'https://mscprod.blob.core.windows.net/production/';
    public media: Media;
    public sectionContent: SectionContent;
    public sectionContentSession: SectionContentSession;
    public url: SafeResourceUrl;
    public urlIframe: SafeResourceUrl;
    public loading: boolean = false;
    public reloadUrl: boolean = false;
    public ipad: any = {
        ready: false,
        iframeLoaded: false,
        retry: 0,
        enableDownload: false
    };

    constructor(
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        public timerService: TimerService,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.subscription = this.route.paramMap.subscribe(() => {
            this.media = this.route.snapshot.data.media.media;
            this.sectionContent = this.route.snapshot.data.media;
            this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
            this.timerInit();
            this.initIpad();
            this.initializer();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    initIpad(): void {
        this.ipad = {
            ready: false,
            iframeLoaded: false,
            retry: 0,
            enableDownload: false
        };
    }

    initializer(): void {
        if (this.media.type === 'IMG') {
            this.url = this.urlIframe = environment.envVar.STORAGE_URL + this.media.url + '?size=raw';
        } else if (this.media.type === 'EMBED') {
            this.url = environment.envVar.STORAGE_URL + this.media.url;
            this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.url);
        } else if (this.media.type === 'PDF') {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.envVar.STORAGE_URL + this.media.url);
            this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.envVar.STORAGE_URL + this.media.url + ((!this.sectionContent.isDownloadable) ? '#toolbar=0' : '#toolbar=1'));
            this.processUrlIpad();
        } else {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.envVar.STORAGE_URL + this.media.originalUrl);
            this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.envVar.STORAGE_URL + this.media.url);
        }
    }

    downloadMedia(): void {
        const event = new MouseEvent('click', { bubbles: true });
        // this.renderer.invokeElementMethod(this.aDownload.nativeElement, 'dispatchEvent', [event]);
        (this.aDownload.nativeElement as any)['dispatchEvent'].apply(this.aDownload.nativeElement, [event]);
    }

    closeModal(): void {
        this.modalService.dismissAll();
        this.reloadUrl = false;
    }

    openModal(content: any): void {
        if (this.media.type !== 'IMG' && this.media.type !== 'VID') {
            const options: NgbModalOptions = { backdropClass: 'backdrop-custom', windowClass: 'force-fullscreen-lesson-media' };
            this.modalService.open(content, options);
            this.reloadUrl = true;
        }
    }

    activityFinished(): void {
        this.loading = true;
        this.sectionContentSession
            .update(true, this.storageService.get('company')?.id)
            .pipe(finalize(() => this.loading = false))
            .subscribe();
    }

    detectPhone(): boolean {
        if ((navigator.userAgent.indexOf('iPhone') > -1 ||
            navigator.userAgent.indexOf('iPod') > -1 ||
            navigator.userAgent.indexOf('Android') > -1) &&
            this.media.type === 'PDF') {
            return true;
        }
        return false;
    }

    detectIpad(): boolean {
        if (navigator.userAgent.indexOf('iPad') > -1 && this.media.type === 'PDF') {
            return true;
        }
        return false;
    }

    processUrlIpad(): void {
        const googleUrl = 'https://docs.google.com/gview?url=<blob>&embedded=true';
        const blob = this.blob + this.media.url;
        const url = googleUrl.replace('<blob>', blob);
        this.ipad.ready = true;

        const timeOut = setTimeout(() => {
            this.createIframe(url);
            clearTimeout(timeOut);
        }, 600);
    }

    createIframe(url: string): void {
        const iframe: any = document.createElement('iframe');
        iframe.src = url;
        iframe.style.border = 'none';
        const wrapper: any = document.getElementById('iframe-wrapper');
        if (wrapper) {
            wrapper.innerHTML = '';
            wrapper.appendChild(iframe);
            this.checkIframeLoading(url);
        }

        iframe.onload = () => {
            iframe.style.width = '100%';
            iframe.style.height = '300px';
            this.ipad.iframeLoaded = true;
        }
    }

    checkIframeLoading(url: string): void {
        if (++this.ipad.retry > 4) {
            this.ipad.iframeLoaded = true;
            this.ipad.enableDownload = true;
            const wrapper: any = document.getElementById('iframe-wrapper');
            wrapper.innerHTML = '';
        } else {
            const timeOut = setTimeout(() => {
                if (!this.ipad.iframeLoaded) {
                    this.createIframe(url);
                }
                clearTimeout(timeOut);
            }, 2500);
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
