<div [ngClass]="'question__list-style_' + question.param.listStyle" *ngIf="!questionSession.doneAt">
    <label for="checkbox{{index}}" class="choice" *ngFor="let choice of internalQuestionSession?.answer.choices; let index=index" [ngClass]="{'choice_selected': choice.state}">
        <input type="checkbox" id="checkbox{{index}}" name="choice{{choice.choice}}" [(ngModel)]="choice.state" (change)="onChange(index)">
        <span class="choice__text">{{choice.choice}}</span>
    </label>
</div>

<div class="" *ngIf="questionSession.doneAt">
    <div class="m-flex" *ngFor="let answer of questionSession.answer.choices; let index=index;">
        <div class="m-flex m-flex-9 m-answer m-items-center">
            <span style="margin: 0 0 0 1rem;">{{ answer.choice }}</span>
        </div>
    </div>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>
