import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'live-editor',
	templateUrl: './live-editor.component.html'
})
export class liveEditorComponent {
	@Output() onUpdate = new EventEmitter();
	@Input() placeholder;
	@Input() inputData;
	@Output('inputDataChange') update = new EventEmitter<string>();
	focused: boolean = false;

	onFocus() {
		this.focused = true;
	}

	onBlur() {
		this.update.emit(this.inputData);
		this.focused = false;
		this.onUpdate.emit();
	}

	isEmpty() {
		return !this.inputData;
	}

	getData() {
		return this.isEmpty() ? this.placeholder : this.inputData;
	}
}