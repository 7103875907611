<div class="question_mode_input" *ngIf="!question.param.type || question.param.type == 'input'">
    <div class="sentence"
         [class.answer_success]="isAnswerSuccess(sentenceIndex)"
         [class.answer_failed]="isAnswerFailed(sentenceIndex)"
         *ngFor="let sentence of sentences; let sentenceIndex = index">
        <span *ngFor="let word of sentence; let wordIndex = index">
            <span *ngIf="word">
                {{ word }}
            </span>

            <span *ngIf="word === null" class="sentence__gap" [ngStyle]="{'width': width[sentenceIndex][wordIndex] ? width[sentenceIndex][wordIndex] : 'auto'}">
                <input type="text"
                       [(ngModel)]="userChoices[sentenceIndex][wordIndex]"
                       (ngModelChange)="onChange(sentenceIndex, wordIndex)"
                       [disabled]="questionSession?.isDisable(question.tryMax)">
            </span>
        </span>

        <div class="correction" *ngIf="isAnswerFailed(sentenceIndex)">
            {{ 'word.correct-answer' | translate }} :
            <p [innerHTML]="showCorrectAnswer(sentenceIndex)"></p>
        </div>
    </div>
</div>

<div class="question_mode_select" *ngIf="question.param.type == 'select'">
    <div class="sentence" [class.answer_success]="isAnswerSuccess(sentenceIndex)" [class.answer_failed]="isAnswerFailed(sentenceIndex)" *ngFor="let sentence of sentences; let sentenceIndex = index">
        <span *ngFor="let word of sentence; let wordIndex = index">
            <span *ngIf="word">{{ word }}</span>

            <span *ngIf="!word && word !== ''" class="sentence__gap">
                <select [(ngModel)]="userChoices[sentenceIndex][wordIndex]" (ngModelChange)="onChange()" [disabled]="questionSession?.isDisable(question.tryMax)">
                    <option *ngFor="let choice of allChoices" [value]="choice">{{ choice }}</option>
                </select>
            </span>
        </span>

        <div class="correction" *ngIf="isAnswerFailed(sentenceIndex)">
            {{ 'word.correct-answer' | translate}} :
            <p [innerHTML]="showCorrectAnswer(sentenceIndex)"></p>
        </div>
    </div>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>
