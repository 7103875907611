import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Swipe } from '../classes';
import { DataHelper } from '../helpers';
import { Course, Section, SectionContent, Exercise, Question, CourseSubscriptionSession } from '@stuplay';
import { OrderedService } from './ordered.service';

@Injectable()
export class NavigationProvider {
    currentCourseSubscriptionSession: CourseSubscriptionSession;
    currentCourse: Course;
    currentSection: Section;
    currentSectionContent: SectionContent;
    currentExercise: Exercise;
    currentQuestion: Question;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private orderedService: OrderedService,
    ) {
        this.deferSwipe();
    }

    private deferSwipe(timer: number = 3000): void {
        const timeOut = setTimeout(() => {
            const swipe = new Swipe(document.querySelector('#view')).run();
            swipe.direction.subscribe((data: any) => {
                if (data === 'left') {
                    this.goRoute('prev');
                } else {
                    this.goRoute('next');
                }
            });
            clearTimeout(timeOut);
        }, timer);
    }

    goRoute(nextOrPrev: string) {
        const route = this.getRoute(nextOrPrev);
        return this.router.navigate(route, { relativeTo: this.route?.root?.firstChild?.firstChild, queryParamsHandling: 'merge' });
    }

    getRoute(nextOrPrev: string): any[] {
        if (this.currentQuestion) {
            return this.getQuestion(nextOrPrev)
        } else if (this.currentSectionContent) {
            return this.getSectionContent(nextOrPrev);
        } else if (this.currentSection) {
            if (nextOrPrev === 'next' && this.currentSection?.sectionContents && this.currentSection?.sectionContents?.length > 0) {
                this.currentSectionContent = this.currentSection?.sectionContents[0];
                return this.getNextSectionContent(nextOrPrev, this.currentSectionContent);
            } else if (nextOrPrev === 'prev') {
                const index = this.currentCourse.sections.findIndex(({ id }) => id === this.currentSection?.id);
                const prevSection = this.currentCourse.sections[this.getNewIndex(index, nextOrPrev)];
                this.currentSection = prevSection;
                if (prevSection && prevSection?.sectionContents && prevSection?.sectionContents.length > 0) {
                    this.currentSectionContent = this.currentSection.sectionContents[this.currentSection?.sectionContents.length - 1];
                    return this.getNextSectionContent(nextOrPrev, this.currentSectionContent);
                } else {
                    if (this.currentCourseSubscriptionSession?.doneSurvey || !this.currentCourse?.hasSurvey) {
                        return ['./results'];
                    } else {
                        return ['./survey'];
                    }
                }
            } else {
                return this.getSection(nextOrPrev);
            }
        } else {
            return ['./section', this.currentCourse?.sections[0]?.id];
        }
    }

    isLevelUp(index: any, array: any, nextOrPrev: any): boolean {
        return (index === array.length - 1 && nextOrPrev === 'next') || (index === 0 && nextOrPrev === 'prev');
    }

    getNewIndex(index: number, nextOrPrev: string): number {
        return nextOrPrev === 'next' ? index + 1 : index - 1;
    }

    getQuestion(nextOrPrev: string): any[] {
        const index = this.currentSectionContent.exercise.questions.findIndex((question: any) => question.id === this.currentQuestion?.id);
        if (this.isLevelUp(index, this.currentSectionContent?.exercise?.questions, nextOrPrev)) {
            this.currentQuestion = null;
            return this.getSectionContent(nextOrPrev, 'question');
        } else {
            const nextQuestion = this.currentSectionContent.exercise.questions[this.getNewIndex(index, nextOrPrev)];
            this.currentQuestion = nextQuestion;
            return [
                './section', this.currentSection?.id,
                'exercise', this.currentSectionContent?.exercise?.id,
                'question', nextQuestion?.id
            ];
        }
    }

    getSectionContent(nextOrPrev: string, fromQuestionLevel?: string): any[] {
        const index = this.currentSection.sectionContents.findIndex(({ id }) => id === this.currentSectionContent?.id);
        if (this.isLevelUp(index, this.currentSection?.sectionContents, nextOrPrev)) {
            this.currentSectionContent = null;
            return this.getSection(nextOrPrev, fromQuestionLevel || 'sectionContent');
        } else {
            this.currentSectionContent = this.currentSection.sectionContents[this.getNewIndex(index, nextOrPrev)];
            return this.getNextSectionContent(nextOrPrev, this.currentSectionContent, fromQuestionLevel);
        }
    }

    getNextSectionContent(nextOrPrev: string, nextSectionContent: SectionContent, fromQuestionLevel?: string): any[] {
        if (nextSectionContent.context !== 'exercise') {
            if (nextSectionContent.context === 'media') {
                return [
                    './section', this.currentSection?.id,
                    nextSectionContent?.context, nextSectionContent[nextSectionContent.context]?.id, 'content', nextSectionContent?.id
                ];
            } else {
                return [
                    './section', this.currentSection?.id,
                    nextSectionContent?.context === 'external_link' ? 'external-link' : nextSectionContent?.context, nextSectionContent[DataHelper.changeCase(nextSectionContent?.context, 'camel')]?.id
                ];
            }
        } else {
            if (nextSectionContent.exercise.questions.length < 1) { // TODO: remove when sure when can't create exercise without questions
                this.currentQuestion = null;
                this.currentSectionContent = nextSectionContent;
                return this.getSectionContent(nextOrPrev, fromQuestionLevel);
            }
            const questionIndex = nextOrPrev === 'next' ? 0 : (nextSectionContent?.exercise?.questions || []).length - 1;
            this.currentQuestion = nextSectionContent.exercise.questions[questionIndex];
            return [
                './section', this.currentSection?.id,
                'exercise', nextSectionContent?.exercise?.id,
                'question', nextSectionContent?.exercise?.questions[questionIndex]?.id
            ];
        }
    }

    getSection(nextOrPrev: string, fromLevel?: string): any[] {
        const index = this.currentCourse.sections.findIndex(({ id }) => id === this.currentSection?.id);
        if (fromLevel && nextOrPrev === 'prev') {
            return ['./section', this.currentSection?.id];
        } else if (this.isLevelUp(index, this.currentCourse?.sections, nextOrPrev)) {
            this.currentSection = null;
            if (this.currentCourseSubscriptionSession?.doneSurvey || !this.currentCourse?.hasSurvey) {
                return ['./results']
            } else {
                return ['./survey']
            }
        } else {
            const nextSection = this.currentCourse?.sections[this.getNewIndex(index, nextOrPrev)];
            this.currentSection = nextSection;
            return ['./section', nextSection?.id];
        }
    }

    isNextSectionAvailable(): boolean {
        if (this.isLastSectionContent() && this.currentCourse?.isOrdered && !this.isLastSection()) {
            const index = this.currentCourse.sections.findIndex(({ id }) => id === this.currentSection?.id);
            return !this.isDisabled(this.currentCourse?.sections[this.getNewIndex(index, 'next')]);
        }
    
        return true;
    }

    isDisabled(section: any): boolean {
        return !this.orderedService.isSectionExist(section?.id);
    }

    isLastSectionContent(): boolean {
        const index = this.currentSection?.sectionContents.findIndex((sectionContent: any) => {
            return sectionContent.id === this.currentSectionContent?.id;
        });

        return index === ((this.currentSection?.sectionContents || []).length - 1);
    }

    isLastSection(): boolean {
        const index = this.currentCourse.sections.findIndex((section: any) => {
            return section.id === this.currentSection?.id;
        });

        return index === (this.currentCourse?.sections || []).length - 1;
    }

    getLevel(level: string, fromLevel?: string): string {
        if ((level === 'sectionContent' && fromLevel === 'question') || (level === 'section' && fromLevel === 'question')) {
            return '../../../';
        } else if (level === 'section' && fromLevel === 'question') {
            return '../../../../../';
        } else {
            return '../../';
        }
    }
}
