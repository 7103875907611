import { Question } from '../question';
import { OrderedAnswer } from '../../../../../../course-camp-session';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../../../stuplay-config';

export class OrderedQuestion extends Question {
  content: OrderedQuestionContent; //(stringify);
  param: OrderedQuestionParameters; //(stringify);
  constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
    super(question, http, stuplayConfig);
    this.extends(question);
  }


  getCorrection(answer): Observable<OrderedAnswer> {
    return this.postAnswer(answer);
  }

  extends(question: any): OrderedQuestion {
    Object.assign(this, question);

    if (!this.content) {
      this.content = new OrderedQuestionContent();
      this.content.addChoice();
      this.content.addChoice();
      this.content.addChoice();
      this.content.addChoice();
    }
    if (question.content) {
      if (this.content instanceof OrderedQuestionContent) {
        this.content.extends(question.content);
      } else {
        this.content = new OrderedQuestionContent(question.content);
      }
    }
    return this;
  }

}

export class OrderedQuestionContent {
  choices: OrderedQuestionChoice[] = [];

  constructor(data: any = {}) {
    this.extends(data);
  }

  extends(data: any = {}): OrderedQuestionContent {
    Object.assign(this, data);

    if (data.choices) {
      this.choices = [];
      data.choices.map((choice) => this.choices.push(new OrderedQuestionChoice(choice)));
    }
    return this;
  }

  addChoice(): OrderedQuestionChoice {
    const newChoice = new OrderedQuestionChoice();
    newChoice.choice = '';
    this.choices.push(newChoice);
    return newChoice;
  }

  removeChoice(index: number) {
    this.choices.splice(index, 1);
  }
}

export class OrderedQuestionChoice {
  choice: string;

  constructor(data: any = {}) {
    this.extends(data);
  }

  extends(data: any = {}): OrderedQuestionChoice {
    Object.assign(this, data);

    return this;
  }
}

export interface OrderedQuestionParameters {
  type: string;
}
