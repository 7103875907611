import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
//
import { TimelineProvider, CourseProvider } from '@stuplay';
//
import { UserProvider } from '../../../services/user.provider';
import { StorageService } from "../../../services/storage.service";

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'msc-post',
    templateUrl: './post.component.html'
})

export class PostComponent {
    @Input() post: any;
    @Input() context: any;
    @Input() contextId: number;
    @Input() timelineId: number;
    @Input() isTrajectContent: boolean;
    @Output() updateExchange = new EventEmitter();
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    private me: any;
    private company: any;
    private course: any;
    private companyOwner;

    constructor(
        private sanitizer: DomSanitizer,
        private courseProvider: CourseProvider,
        private router: Router,
        private route: ActivatedRoute,
        private userProvider: UserProvider,
        private timelineProvider: TimelineProvider,
        private storageService: StorageService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.course = this.storageService.get('course');

        this.userProvider.getCurrentUser().subscribe((user) => {
            this.me = user;
        });

        this.userProvider.getRoles(this.company?.id).subscribe((roles) => {
            this.companyOwner = roles?.companyOwner;
        });

        if (this.post.created_at) {
            this.post.created_at = this.datePipe.transform(new Date(this.post?.created_at), DATE_DEFAULT_FORMAT);
        }
    }

    addComment(comment: any, postId: number, postForm: any): void {
        if (comment !== '') {
            const params = {
                timeline_id: this.timelineId,
                content: comment,
                type: 'mixed',
                company_id: this.company?.id,
                context: this.context,
                context_id: this.contextId
            };

            if (this.isTrajectContent) {
                params['traject_id'] = this.course?.trajectId;
            } else {
                params['instance_id'] = this.course?.id
            }

            this.timelineProvider.addComment(this.post?.id, params).subscribe((data: any) => {
                this.post.comments.push(data);
                // this.updateExchange.emit(true);
                postForm.reset()
            });
        }
    }

    checkCanDelete(): boolean {
        if (this.companyOwner) {
            return true;
        }
        if (this.me && this.post?.account) {
            return this.me?.id === this.post?.account?.id;
        }
    }

    onDeletePost(): void {
        this.timelineProvider.deletePost(this.timelineId, this.post?.id).subscribe(() => {
            this.onDelete.emit(this.post?.id);
        });
    }

    getDate(date: string): string {
        return this.datePipe.transform(new Date(date), DATE_DEFAULT_FORMAT);
    }

    deleteComment(id: any): void {
        this.timelineProvider.deleteComment(id).subscribe(() => {
            const index = this.post.comments.findIndex((comment: any) => {
                return comment.id === id;
            });

            if (index > -1) {
                this.post.comments.splice(index, 1);
            }
        });
    }
}
