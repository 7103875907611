import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { Media } from '../../../../media';
import { cleanObject } from '../../../../helpers';

export class Exchange {
    id: number;
    media?: Media;

    constructor(exchange: any, protected http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(exchange);
    }

    extends(exchange: any): Exchange {
        Object.assign(this, exchange);
        return this;
    }

    update(): Observable<Exchange> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/exchange/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    save(): Observable<Exchange> {
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/project/${this.id}`, cleanObject(this));
        } else {
            baseObs = this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/project`, cleanObject(this));
        }

        return baseObs.pipe(map((object: object) => this.extends(object)));
    }
}
