<div class="slide slide-presentation">
    <div class="course-presentation">
        <div class="row">
            <div class="columns course-presentation__infos">
                <h2 class="heading">
                    <span *ngIf="course.type || course.template?.type">
                        {{ ('player.course.' + (course.type ? course.type : course.template?.type)) | translate }}
                    </span>
                    {{ course.title ||  ('player.untitled-course' | translate) }}
                    
                </h2>

                <p class="course-presentation__description multiline"
                    [innerHTML]="(course?.shortDescription ? course.shortDescription : course?.template?.shortDescription) | safe:'html'">
                </p>

                <div class="m-flex btn-course" >
                    <div *ngIf='course.sections.length > 0'>
                        <button [routerLink]="['section', firstSectionId]" queryParamsHandling="merge" class="btn">
                            {{ 'player.course.start' | translate }}
                        </button>
                    </div>

                    <div class="m-unsubscribe"
                        *ngIf="course.courseSubscriptionId && !external && !course.subscription?.isFromGroup"
                        (click)="openModalUnsubscribe(modalUnsubscribe)">
                        <button class="btn btn_color_grey">
                            {{ 'words.unsubscribe-course' | translate }}
                        </button>
                    </div>

                    <div class="m-unsubscribe" *ngIf="!course.courseSubscriptionId && !preview">
                        <button class="btn btn_color_grey" [disabled]="true">
                            <span>{{ 'words.unsubscribe-course' | translate }}</span>
                            <i class="icon icon-info-outline"></i>
                            <span class="tooltip"
                                [attr.data-tooltip]="'words.unsubscribe-journey.title' | translate"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="course-presentation__img">
                <img *ngIf="course.picture" [src]="course.picture + '?size=raw'">
                <img *ngIf="!course.picture"
                    src="https://static.myskillcamp.com/images/images_random/empty-state-7.jpg">
            </div>
        </div>

        <div class="row">
            <div class="columns medium-5 complement_infos">
                <div class="session__score">
                    <span class="legend" style="font-weight: bold;font-size: 1.4rem;color: #080D38">{{ 'player.course.average'| translate }}</span>

                    <span class="ratio">{{ !courseSubscriptionSession?.score ? 0 : courseSubscriptionSession.score | number : '1.0-0'}} %</span>
                </div>

                <div class="session__progress">
                    <span class="legend" style="font-weight: bold;font-size: 1.2rem;color: #080D38">{{
                        'player.course.progress'| translate }}</span>

                    <span class="progress-bar">
                        <span class="progress"
                            [ngStyle]="{'width.%': (courseSubscriptionSession?.progress === null) ? 0 : courseSubscriptionSession?.progress}"></span>
                    </span>
                </div>

                <div class="row">
                    <div class="columns medium-6 micro-data">
                        <span class="micro-data__title">
                            {{ 'player.course.duration'| translate }}
                        </span>

                        <span class="micro-data__info">
                            {{ (course?.duration ? course.duration : course.template?.duration) | time }}
                        </span>
                    </div>

                    <div class="columns medium-6 micro-data">
                        <span class="micro-data__title">
                            {{ 'player.course.language'| translate }}
                        </span>

                        <span class="micro-data__info">
                            {{ course.language ? course.language.name : course.template?.language?.name }}
                        </span>
                    </div>

                    <div class="columns medium-12 micro-data" *ngIf="course.weeklyWorkingDuration > 0">
                        <span class="micro-data__title">
                            {{ 'player.course.weekly-working-duration'| translate }}
                        </span>

                        <span class="micro-data__info">
                            {{ course?.weeklyWorkingDuration | time }}
                        </span>
                    </div>

                    <div class="columns medium-12 micro-data" *ngIf="course.type || course.template?.type">
                        <span class="micro-data__title">
                            {{ 'player.course-word' | translate }}
                        </span>

                        <span class="micro-data__info">
                            {{ ('type.' + (course.type ? course.type : course.template?.type)) | translate }}
                        </span>
                    </div>
                </div>

                <div class="row" *ngIf="course.tags.length > 0 && course.tags">
                    <div class="columns small-12 micro-data">
                        <span class="micro-data__title">
                            {{ 'words.skills' | translate }}
                        </span>

                        <span class="micro-data__info">
                            <span class="tag" *ngFor="let tag of course.tags">{{ tag }}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="columns medium-7">
                <div class="listing__holder">
                    <ul class="listing">
                        <li class="listing__item" *ngIf="course.requirement || course.template?.requirement">
                            <h3>{{ 'studio.create.course.requirement' | translate }}</h3>
                            <p
                                [innerHTML]="(course?.requirement ? course?.requirement : course?.template?.requirement) | safe:'html'">
                            </p>
                        </li>

                        <li class="listing__item" *ngIf="course.target || course.template?.target">
                            <h3>{{ 'studio.create.course.audience' | translate }}</h3>
                            <p [innerHTML]="(course?.target ? course?.target : course?.template?.target) | safe:'html'">
                            </p>
                        </li>

                        <li class="listing__item" *ngIf="course.purpose || course.template?.purpose">
                            <h3>{{ 'studio.create.course.purpose' | translate }}</h3>
                            <p
                                [innerHTML]="(course?.purpose ? course?.purpose : course?.template?.purpose) | safe:'html'">
                            </p>
                        </li>

                        <li class="listing__item" *ngIf="!course.purpose && !course.target && !course.requirement">
                            <h3>{{ 'player.course.discover-this-course' | translate }}</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="events && events.length > 0">
        <h2 class="heading">{{ 'player.course.events.title' | translate }}</h2>
        <p>{{ 'player.course.events.description' | translate }}</p>

        <table class="table">
            <tr *ngFor="let event of events">
                <td><strong>{{ event.name }}</strong></td>
                <td *ngIf="(event?.eventSessions[0]?.startAt | date:'dd') !== (event?.eventSessions[0]?.endAt | date:'dd')"
                    class="date">
                    {{ event?.eventSessions[0]?.startAt | amDateFormat:'D' }}
                    {{ 'date.'+(event?.eventSessions[0]?.startAt | amDateFormat:'MMMM') | translate }}
                    {{ event?.eventSessions[0]?.startAt | amDateFormat:'H:mm' }} - {{ event?.eventSessions[0]?.endAt | amDateFormat:'D' }}
                    {{ 'date.'+(event?.eventSessions[0]?.endAt | amDateFormat:'MMMM') | translate }}
                    {{ event?.eventSessions[0]?.endAt | amDateFormat:'H:mm' }}
                    <div>
                        <a [href]="event?.eventSessions[0]?.icsCalendar">{{ 'event.event-session.download.add-calendar' | translate }}</a>
                    </div>
                </td>

                <td *ngIf="(event?.eventSessions[0]?.startAt | date:'dd') === (event?.eventSessions[0]?.endAt | date:'dd')"
                    class="date">
                    {{ event?.eventSessions[0]?.startAt | amDateFormat:'D' }}
                    {{ 'date.'+(event?.eventSessions[0]?.startAt | amDateFormat:'MMMM') | translate }}
                    {{ event?.eventSessions[0]?.startAt | amDateFormat:'H:mm' }} - {{ event?.eventSessions[0]?.endAt | amDateFormat:'H:mm' }}
                    <div>
                        <a [href]="event.eventSessions[0].icsCalendar">{{ 'event.event-session.download.add-calendar' | translate }}</a>
                    </div>
                </td>

                <td class="place">{{ event?.eventSessions[0]?.address || "-" }}</td>
            </tr>
        </table>
    </div>
</div>

<ng-template #modalUnsubscribe let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="m-modal-title">
            <ng-container *ngIf="course.title; else noTitle">
                {{ 'content.delete.subscription_new.title' | translate : {title: course.title} }}
            </ng-container>
            <ng-template #noTitle>
                {{ 'content.delete.subscription.title' | translate }}
            </ng-template>
        </div>

        <div class="m-modal-description" [innerHTML]="'content.delete.subscription_new.description' | translate"></div>

        <div class="m-modal-message">
            <label style="text-align: center;">{{ 'content.delete.subscription_new.message' | translate }}</label>
            <textarea [(ngModel)]="data.message"></textarea>
        </div>

        <div class="m-modal-actions">
            <div class="">
                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.cancel' | translate }}
                </button>
            </div>

            <div class="">
                <button class="btn" (click)="unsubscribe()">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'words.unsubscribe' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalUnsubscribe let-modal>
    <span class="btn-action_close" (click)="modal.close()">+</span>

    <div class="m-modal-content">
        <div class="m-modal-title">
            {{ 'words.unsubscribe-course.title' | translate }}
        </div>

        <div class="m-modal-description" [innerHTML]="'words.unsubscribe-course.description' | translate"></div>

        <div class="m-modal-message">
            <textarea [(ngModel)]="data.message"></textarea>
        </div>

        <div class="m-modal-actions">
            <div class="">
                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.cancel' | translate }}
                </button>
            </div>

            <div class="">
                <button class="btn" (click)="unsubscribe()">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'player.common.validate' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template> -->
