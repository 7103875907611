<div class="slide slide_presentation">
    <div class="column-left">
        <h2 class="heading">
            <span>{{ 'player.self-assessment.name'| translate }}</span> {{ selfAssessment.title }}
        </h2>
        <p class="event-presentation__description multiline" [innerHTML]="sectionContent?.description | safe:'html'"></p>

        <div class="imported-files" style="margin-bottom: 2rem" *ngIf="selfAssessment?.type === 'media' || selfAssessment?.type === 'both'">
            <div class="imported-files__title">
                {{ 'player.media.imported-files' | translate }}
            </div>

            <div *ngIf="!sectionContentSession?.selfassessmentAnswer?.medias || (selfAssessment?.allowRevaluation && ratio && sectionContentSession?.doneAt)">
                <span class="micro-data__info" *ngFor="let i of range[index]">
                    <media-button-upload [type]="'upload'" [hidden]="validate" [media]="medias[index][i]" (updateMedia)="onUpdateMedia($event)" (deleteMedia)="onDeleteMedia(i)" (onError)="onError()"></media-button-upload>
                </span>
            </div>

            <div class="error-message" *ngIf="error">
                {{ 'player.media.error-import' | translate }}
            </div>

            <div *ngIf="sectionContentSession?.selfassessmentAnswer?.medias && (!sectionContentSession?.doneAt || !selfAssessment?.allowRevaluation)">
                <span class="micro-data__info" *ngFor="let answerMedia of sectionContentSession.selfassessmentAnswer.medias">
                    <media-button-upload [media]="answerMedia"></media-button-upload>
                </span>
            </div>

            <div *ngIf="sectionContentSession?.selfassessmentAnswer?.medias && sectionContentSession?.selfassessmentAnswer?.medias.length <= 0">
                <span>{{ 'selfassessment.no-imported-files' | translate }}</span>
            </div>
        </div>

        <msc-froala [(content)]="content" (contentChange)="update()"
            *ngIf="(!sectionContentSession?.selfassessmentAnswer?.isCorrected && !displayNote && (selfAssessment?.type === 'text' || selfAssessment?.type === 'both')) || (selfAssessment?.allowRevaluation && sectionContentSession.doneAt && (selfAssessment?.type === 'text' || selfAssessment?.type === 'both'))">
        </msc-froala>
        <p *ngIf="(sectionContentSession?.selfassessmentAnswer?.isCorrected && !displayNote && (selfAssessment?.type === 'text' || selfAssessment?.type === 'both')) || (!selfAssessment?.allowRevaluation && ratio && sectionContentSession.doneAt && (selfAssessment?.type === 'text' || selfAssessment?.type === 'both'))"
            [innerHTML]="sectionContentSession?.selfassessmentAnswer?.content"></p>

        <div style="margin-top: 1rem" *ngIf="(displayNote || selfAssessment?.type === 'none') || ratio">
            <p>{{ 'words.score.self-assessment' | translate }}</p>

            <div *ngIf="(sectionContentSession?.doneAt && selfAssessment?.allowRevaluation) || !sectionContentSession?.selfassessmentAnswer?.isCorrected">
                <input type="range" name="points" min="0" max="100" [value]="ratio" [(ngModel)]="ratio">
                <span>{{ ratio }}</span>

                <p>{{ 'words.your-explanation' | translate }}</p>
                <textarea name="" id="" cols="30" rows="6" [(ngModel)]="studentComment"></textarea>
            </div>

            <div *ngIf="(sectionContentSession?.doneAt && !selfAssessment?.allowRevaluation) && sectionContentSession?.selfassessmentAnswer?.isCorrected">
                <p>{{ ratio }} %</p>
            </div>
        </div>

        <msc-next-prev-btn
            [navigate]="false"
            [navigateEmitter]="navigateEmitter"
            [type]="'selfassessment'"
            [loading]="loading"
            [translator]="translateButton()"
            [validate]="((mediasId[index].length > 0 && !sectionContentSession?.doneAt)
                || (content.length > 0 && !sectionContentSession?.doneAt)
                || (sectionContentSession?.selfassessmentAnswer?.medias && sectionContentSession?.selfassessmentAnswer?.medias.length > 0 && !sectionContentSession?.doneAt))
                || ((selfAssessment?.type === 'none' && !sectionContentSession?.doneAt)
                || (selfAssessment?.type === 'none' && sectionContentSession?.doneAt && selfAssessment?.allowRevaluation))
                || (selfAssessment?.allowRevaluation && sectionContentSession?.doneAt)"
            [doneAt]="sectionContentSession?.doneAt"
            (isFinished)="checkSelfAssessmentAnswer(modalWarning)">
        </msc-next-prev-btn>
    </div>
</div>

<ng-template #modalWarning let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="m-modal-title">
            {{ 'selfassessment.modal.confirm.title' | translate }}
        </div>

        <div class="m-modal-description" *ngIf="content.length <= 0 && checkMedias()" [innerHTML]="'selfassessment.modal.confirm.description.media' | translate"></div>
        <div class="m-modal-description" *ngIf="content.length > 0 && !checkMedias()" [innerHTML]="'selfassessment.modal.confirm.description.text' | translate"></div>

        <div class="m-modal-actions">
            <div class="">
                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.cancel' | translate }}
                </button>
            </div>

            <div class="">
                <button class="btn" (click)="validateModal()">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'player.common.validate' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalWarning let-modal>
    <span class="btn-action_close" (click)="modal.close()">+</span>

    <div class="m-modal-content">
        <div class="m-modal-title">
            {{ 'selfassessment.modal.confirm.title' | translate }}
        </div>

        <div class="m-modal-description" *ngIf="content.length <= 0 && checkMedias()" [innerHTML]="'selfassessment.modal.confirm.description.media' | translate"></div>
        <div class="m-modal-description" *ngIf="content.length > 0 && !checkMedias()" [innerHTML]="'selfassessment.modal.confirm.description.text' | translate"></div>

        <div class="m-modal-actions">
            <div class="">
                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.cancel' | translate }}
                </button>
            </div>

            <div class="">
                <button class="btn" (click)="validateModal()">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'player.common.validate' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template> -->

<app-footer [context]="'selfassessment'" [contextId]="selfAssessment?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
