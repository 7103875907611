import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Media } from '@stuplay';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'media-video',
    templateUrl: './video.component.html'
})

export class MediaVideoComponent implements OnChanges {

    @Input() media: Media;
    url;


    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const url = this.media.url.replace('www.vimeo.com/', 'player.vimeo.com/video/');
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https:' + url);
    }
}
