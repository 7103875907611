import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '@stuplay';

@Component({
    selector: 'library-modal',
    templateUrl: './library-modal.component.html',
    styleUrls: ['./library-modal.component.less']
})

export class LibraryModalComponent {
    @Input() media: Media;
    @Output() updateMedia = new EventEmitter();
    @Output() closeModal = new EventEmitter<boolean>();
    tab: number = 2;

    setTab(num: number) {
        this.tab = num;
    }

    isSelected(num: number) {
        return this.tab === num;
    }

    onUpdatedMedia(media) {
        this.updateMedia.emit(media);
        this.close();
    }

    close() {
        this.closeModal.emit();
    }
}
