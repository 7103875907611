import { Injectable } from '@angular/core';

@Injectable({
    providedIn:'root'
})
export class StorageService {
    private storage: any[];

    constructor() {
        this.storage = [];
    }

    set(key: string, data: any): void {
        this.storage[key] = data;
    }

    get(key: string): any {
        return this.storage[key];
    }

    getFlash(key: string): any {
        const storage = this.storage[key];
        this.delete(key);
        return storage;
    }

    getAndSet(key: string, data: any): any {
        this.set(key, data);
        return this.get(key);
    }

    delete(key?: string, returnValue: string = ''): string {
        if (key) {
            delete this.storage[key];
            return returnValue;
        } else {
            this.storage = [];
        }
    }
}
