export const environment = {
  name: {
      short: 'dev',
      long: 'dev'
  },
  analytics: {
      google: 'UA-112169318-4'
  },
  production: false,
  tokenName: 'token_msc_staging',
  tokenSessionName: 'token_session',
  refreshTokenName: 'refresh_token_staging',
  domain: 'deltablue.com',
  envVar: {
      API_URL: "https://api-upgraded.myskillcamp.com.deltabluecdn.com/api",
      AUTH_URL: "https://auth.myskillcamp.com.deltabluecdn.com",
      STORAGE_URL: "https://api-upgraded.myskillcamp.com.deltabluecdn.com/api/stockage/",
      REPORTING_URL: "https://reporting-clone-f9cb377f5501.deltablue.io/api/v2",
      APP_URL: "https://app.myskillcamp.com.deltabluecdn.com/",
      STUDIO_URL: "https://studio.myskillcamp.com.deltabluecdn.com/",
      PLAYER_URL: "https://player.myskillcamp.com.deltabluecdn.com/",
      CLOUD_URL: "https://cloud.myskillcamp.com.deltabluecdn.com"
}
};
