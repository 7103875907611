import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes } from 'ngx-uploader';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { StuplayConfig, Media } from '@stuplay';
import { ToastService } from '../../components/toast/toast.service';

@Component({
    selector: 'library-upload',
    templateUrl: './library-upload.component.html',
    styleUrls: ['./library-upload.component.less']
})

export class LibraryUploadComponent {
    @Input() media: Media;
    @Input() uploadInput: EventEmitter<UploadInput>;
    @Output() updateMedia = new EventEmitter();
    public promiseSetBySomeAction: any;
    public formData: FormData;
    public files: UploadFile[];
    public humanizeBytes: Function;
    public dragOver: boolean;
    public observableEmitter: Observable<EventEmitter<UploadInput>>;

    constructor(
        public http: HttpClient,
        private stuplayConfig: StuplayConfig,
        private toastService: ToastService,
    ) {
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
    }

    startUpload(): void {
        const event: UploadInput | any = {
            type: 'uploadAll',
            url: `${this.stuplayConfig.envVar.API_URL}/media`,
            method: 'POST',
            data: {},
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem(environment?.tokenSessionName)
            },
            concurrency: 1
        };
        this.uploadInput.emit(event);
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
            const isFileSizeValid = this.files[0].size <= 2000000000;
            const isFileTypeValid = this.files[0].type;
            if (!isFileSizeValid) {
                this.toastService.push('file-too-big', 'error');
            } else if (!isFileTypeValid) {
                this.toastService.push('file.not-supported', 'error');
            } else {
                const event: UploadInput | any = {
                    type: 'uploadAll',
                    url: `${this.stuplayConfig.envVar.API_URL}/media`,
                    method: 'POST',
                    data: {},
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem(environment?.tokenSessionName)
                    },
                    concurrency: 1
                };
                this.uploadInput.emit(event);
            }
        } else if (output?.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
        } else if (output?.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => file.id === output?.file?.id);
            this.files[index] = output.file;
        } else if (output?.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output?.file);
        } else if (output?.type === 'dragOver') { // drag over event
            this.dragOver = true;
        } else if (output?.type === 'dragOut') { // drag out event
            this.dragOver = false;
        } else if (output?.type === 'drop') { // on drop event
            this.dragOver = false;
        } else if (output?.type === 'done') {
        } else if (output?.type === 'start') {
            const interval = setInterval(() => {
                if (output?.file?.progress?.status === 2) {
                    this.onUpdatedMedia(output?.file?.response);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }

    onUpdatedMedia(media: any) {
        this.updateMedia.emit(media);
        this.files = [];
    }
}
