import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApplicationProvider } from './application';
import { AuthProvider } from './auth';
import { CampProvider } from './camp';
import { CourseCampProvider } from './course-camp';
import { CourseCampSessionProvider } from './course-camp-session';
import { CourseProvider } from './course';
import { CourseSubscriptionSessionProvider } from './course-subscription-session';
import { EventsProvider } from './events/events.provider';
import { ExternalLinkProvider } from './course';
import { FolderProvider } from './folder';
import { JourneyProvider } from './journey';
import { MediaProvider } from './media';
import { NoteProvider } from './note';
import { ReUseListingProvider } from './re-use-listing/re-use-listing.provider';
import { ScormProvider } from './course/section/section-content/scorm/scorm-provider';
import { SectionContentProvider } from './course/section/section-content/section-content.provider';
import { SectionProvider } from './course/section/section.provider';
import { SkillProvider } from './skill';
import { StatisticsProvider } from './statistics';
import { StuplayConfig } from './stuplay-config';
import { SurveyProvider } from './survey/survey.provider';
import { TimelineProvider } from './timeline';
import { UserProvider } from './user';
import { UtilityProvider } from "./utility";

@NgModule({})
export class StuplayModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: StuplayModule,
            providers: [
                ApplicationProvider,
                AuthProvider,
                CampProvider,
                CourseCampProvider,
                CourseCampSessionProvider,
                CourseProvider,
                EventsProvider,
                ExternalLinkProvider,
                FolderProvider,
                JourneyProvider,
                MediaProvider,
                NoteProvider,
                ReUseListingProvider,
                ScormProvider,
                SectionContentProvider,
                SectionProvider,
                SkillProvider,
                StatisticsProvider,
                StuplayConfig,
                SurveyProvider,
                TimelineProvider,
                UserProvider,
                UtilityProvider,
            ]
        };
    }
}
