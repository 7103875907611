import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { cleanObject } from "../../../../helpers";

export class SelfAssessment {
    id: number;
    type: string;
    title: string;
    info: string;
    content: string;
    createdAt: number;
    updatedAt: number;
    allowRevaluation: boolean;
    isScorable: boolean;
    scoreMax: number;

    constructor(selfAssessment: any, protected http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(selfAssessment);
    }

    extends(selfAssessment: any): SelfAssessment {
        Object.assign(this, selfAssessment);
        return this;
    }

    update(): Observable<SelfAssessment> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/selfassessment/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }

    save(): Observable<SelfAssessment> {
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/selfassessment/${this.id}`, cleanObject(this));
        } else {
            baseObs = this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/selfassessment`, cleanObject(this));
        }

        return baseObs.pipe(map((object: object) => this.extends(object)));
    }
}
