import { Component, Input, OnInit } from '@angular/core';
import { Timeline } from '@stuplay';
import { ActivatedRoute, Params } from '@angular/router';
import { StorageService } from '../../../utils/services/storage.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
    @Input() timeline: Timeline;
    @Input() context: string;
    @Input() contextId: number;
    @Input() previewMode: boolean = false;
    linkState: string = 'notes';
    public course: any;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            this.previewMode = !!params.preview;
        });
        this.course = this.storageService.get('course');
        if (this.course?.discussionEnabled) {
            this.linkState = 'discussion';
        }
    }

    changeState(state: string) {
        this.linkState = state;
    }
}
