import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Camp } from "./camp";
import { StuplayConfig } from '../stuplay-config';
import { CourseCamp } from '../course-camp';
import { getParams } from "../helpers";

@Injectable()
export class CampProvider {
    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getCamps(): Observable<Camp[]> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps?includes=course_camps`)
            .pipe(map((camps: any[]) => camps.map((camp) => new Camp(camp, this.http, this.stuplayConfig))));
    }

    getAllCamps(context: string, id: number, q?: string): Observable<Camp[]> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/camps?context=${context}&context_id=${id}`, {params :getParams({q: q})})
            .pipe(map((camps: any[]) =>  camps.map((camp) => new Camp(camp, this.http, this.stuplayConfig))));
    }

    getCourses(contextId: number, context: string): Observable<CourseCamp[]>  {
      if (context === 'camp') {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course-camp?camp_id=${contextId}&include=sessions,course.categories`)
        .pipe(map((data: any) => data.map(courseCamp => new CourseCamp(courseCamp, this.http, this.stuplayConfig))));
      } else {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/group/${contextId}/course?include=camp,sessions,course.categories`)
        .pipe(map((data: any) => data.map(courseCamp => new CourseCamp(courseCamp, this.http, this.stuplayConfig))));
      }
    }

    getMyCampsWithGroups(): Observable<any> {
      return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps?includes=account&sidebar=true`)
      .pipe(map((data: any) =>  data.campuses),
      switchMap((camps) => {
        return forkJoin(camps.map((camp) => {
          return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps/${camp?.id}/group`);
        }))
        .pipe(map((groupsOfCamps) => {
          return camps.map((camp, index) => {
            camp.groups = groupsOfCamps[index];
            return camp;
          });
        }));
      }))
    }

    getUsers(campId): Observable<any>  {
      return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps/${campId}/user`).pipe(map((data: any) => data.users));
    }

    getGroups(campId): Observable<any> {
      return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps/${campId}/group`).pipe(map((data: any) => data.users));
    }

    getCorrection(campId): Observable<any>  {
      return this.http.get(`${this.stuplayConfig.envVar.API_URL}/camps/${campId}/correction`).pipe(map((data: any) => data.users));
    }
}
