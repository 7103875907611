import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { ScormModule } from './scorm-module';

@Injectable()
export class ScormProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getStatus(id: number): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/scorms/${id}/upload/status`)
            .pipe(map((data) => new ScormModule(data, this.http, this.stuplayConfig)));
    }

    launchScorm(id: number, sectionContentId: number, preview: boolean): Observable<any> {
        const body = {
            preview: preview,
            section_content_id: sectionContentId
        };

        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/learning/scorms/${id}/url`, body);
    }
}