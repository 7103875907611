import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard';
import { PlayerComponent } from './pages/player/player.component';
import { HubComponent } from './pages/hub/hub.component';
import { CourseComponent } from './pages/course/course.component';

import { SectionComponent } from './pages/section/section.component';
import { CoursePresentationComponent } from './pages/course/course-presentation/course-presentation.component';
import { CourseEndComponent } from './pages/course/course-end/course-end.component';
import { SectionPresentationComponent } from './pages/section/section-presentation/section-presentation.component';
import { EventComponent } from './pages/section/event/event.component';
import { ProjectComponent } from './pages/section/project/project.component';
import { ExerciseComponent } from './pages/section/exercise/exercise.component';
import { CheatSheetComponent } from './pages/section/cheatsheet/cheatsheet.component';
import { LessonMediaComponent } from './pages/section/lesson-media/lesson-media.component';
import { HomeComponent } from './pages/home/home.component';
import {
    CampResolver,
    CertificateResolver,
    CheatSheetResolver,
    EventResolver,
    ExternalLInkResolver,
    ExerciseResolver,
    MediaResolver,
    ProjectResolver,
    QuestionResolver,
    QuestionSessionResolver,
    VideoResolver,
    SectionContentSessionResolver,
    SectionResolver,
    UserResolver,
    ExchangeResolver,
    ScormResolver,
    ScormCloudResolver,
    CourseResolver,
    CourseSubscriptionSessionResolver,
    SelfAssessmentResolver
} from './app.resolvers';
import { ExchangeComponent } from './pages/section/exchange/exchange.component';
import { CertificateComponent } from './pages/section/certificate/certificate.component';
import { ScormComponent } from './pages/section/scorm/scorm.component';
import { ScormCloudComponent } from './pages/section/scorm/scorm-cloud.component';
import { ExternalLinkComponent } from './pages/section/external-link/external-link.component';
import { ResultsComponent } from './pages/results/results.component';
import { VideoComponent } from './pages/section/video/video.component';
import { SurveyComponent } from './pages/course/survey/survey.component';
import { SelfAssessmentComponent } from './pages/section/self-assessment/self-assessment.component';
import { CompanyGuard } from './company-guard';
export const playerRoutes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'course/:courseId',
        component: HubComponent,
        resolve: {
            course: CourseResolver
        },
        children: [
            {
                path: '',
                component: CourseComponent,
                resolve: {
                    courseSubscriptionSession: CourseSubscriptionSessionResolver
                },
                children: [
                    {
                        path: '',
                        component: CoursePresentationComponent
                    },
                    {
                        path: 'end',
                        component: CourseEndComponent
                    },
                    {
                        path: 'survey',
                        component: SurveyComponent
                    },
                    {
                        path: 'results',
                        component: ResultsComponent
                    },
                    {
                        path: 'section/:sectionId',
                        component: SectionComponent,
                        resolve: {
                            section: SectionResolver
                        },
                        children: [
                            {
                                path: '',
                                component: SectionPresentationComponent
                            },
                            {
                                path: 'media/:mediaId/content/:contentId',
                                component: LessonMediaComponent,
                                resolve: {
                                    media: MediaResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'cheatsheet/:cheatsheetId',
                                component: CheatSheetComponent,
                                resolve: {
                                    cheatsheet: CheatSheetResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'external-link/:externalLinkId',
                                component: ExternalLinkComponent,
                                resolve: {
                                    externalLink: ExternalLInkResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'scorm/:scormId',
                                component: ScormComponent,
                                resolve: {
                                    scorm: ScormResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'scormcloud/:scormcloudId',
                                component: ScormCloudComponent,
                                resolve: {
                                    scormcloud: ScormCloudResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'exercise/:exerciseId',
                                resolve: {
                                    exercise: ExerciseResolver,
                                    sectionContentSession: SectionContentSessionResolver,
                                },
                                children: [{
                                    path: 'question/:questionId',
                                    component: ExerciseComponent,
                                    resolve: {
                                        question: QuestionResolver,
                                        questionSession: QuestionSessionResolver,
                                    }
                                }]
                            },
                            {
                                path: 'event/:eventId',
                                component: EventComponent,
                                resolve: {
                                    event: EventResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'exchange/:exchangeId',
                                component: ExchangeComponent,
                                resolve: {
                                    exchange: ExchangeResolver,
                                    sectionContentSession: SectionContentSessionResolver,
                                }
                            },
                            {
                                path: 'video/:videoId',
                                component: VideoComponent,
                                resolve: {
                                    video: VideoResolver,
                                    sectionContentSession: SectionContentSessionResolver,
                                }
                            },
                            {
                                path: 'project/:projectId',
                                component: ProjectComponent,
                                resolve: {
                                    project: ProjectResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'certificate/:certificateId',
                                component: CertificateComponent,
                                resolve: {
                                    certificate: CertificateResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            },
                            {
                                path: 'selfassessment/:selfassessmentId',
                                component: SelfAssessmentComponent,
                                resolve: {
                                    selfassessment: SelfAssessmentResolver,
                                    sectionContentSession: SectionContentSessionResolver
                                }
                            }
                        ]
                    }
                ]
            }
        ],
    },
];
export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: PlayerComponent,
        canActivate: [AuthGuard, CompanyGuard],
        resolve: {
            currentUser: UserResolver
        },
        children: playerRoutes
    },
    {
        path: ':companyId',
        component: PlayerComponent,
        canActivate: [AuthGuard, CompanyGuard],
        resolve: {
            currentUser: UserResolver
        },
        children: playerRoutes
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        SectionResolver, SectionContentSessionResolver, CheatSheetResolver, MediaResolver,
        QuestionResolver, QuestionSessionResolver, ScormResolver, ScormCloudResolver, VideoResolver,
        EventResolver, ProjectResolver, UserResolver, ExerciseResolver, CampResolver, ExchangeResolver,
        CertificateResolver, CourseResolver, CourseSubscriptionSessionResolver, SelfAssessmentResolver, ExternalLInkResolver
    ]
})
export class AppRoutingModule { }
