<msc-topbar *ngIf="displays.topbar" [course]="course" [courseSubscriptionSession]="courseSubscriptionSession"
	[showSidebar]="showSidebar" [displaySidebar]="displays.sidebar" (toggle)="toggleSidebar()"></msc-topbar>
<msc-sidebar *ngIf="displays.sidebar" [course]="course" [showSidebar]="showSidebar" [external]="external"
	(closeSidebar)="toggleSidebar()"></msc-sidebar>

<div id="view" [ngClass]="{'overlay' : showSidebar, 'view-activity': !displays.sidebar, 'view__external': external}">
	<router-outlet></router-outlet>
</div>

<style>
	.view-activity {
		position: static !important;
	}

	.view__external {
		top: 0 !important;
	}
</style>