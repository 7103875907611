import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of, pipe } from 'rxjs';
import { concatMap, mergeMap, map, catchError } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { CourseSubscriptionSession } from "./course-subscription-session";
import { getParams } from "../helpers";
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/utils/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class CourseSubscriptionSessionProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig,
        private storageService: StorageService) { }

    private statisticsAggregator() {
        return pipe(
            concatMap(
                (data: Object) => this.getCourseSubscriptionSessionReporting(this.storageService.get('company').id, this.storageService.get('course').id, this.storageService.get('me').id).pipe(
                    map(res => ({ ...data, ...res })),
                    catchError(() => {
                        return of(data);
                    })
                )
            ),
            concatMap((data) => {
                if (data.sectionContentSessions.length) {
                    return this.getCourseSectionContentReporting(this.storageService.get('company').id, this.storageService.get('course').id, this.storageService.get('me').id, (data as any).sectionContentSessions.map(sectionContent => sectionContent.sectionContentId))
                        .pipe(
                            map((res) => {
                                data.sectionContentSessions = data.sectionContentSessions?.map(sectionContentSession => ({ ...sectionContentSession, ...res[sectionContentSession.sectionContentId]?.context }));
                                return data;
                            }),
                            catchError(() => {
                                return of(data);
                            })
                        );
                }
                return of(data);
            }),
            map((data) => {
                return new CourseSubscriptionSession(data, this.http, this.stuplayConfig)
            })
        )
    }

    getOrCreateCourseSubscriptionSession(courseId: number, preview?: boolean, directPlay?: boolean): Observable<CourseSubscriptionSession> {
        const params = {
            course_id: courseId,
            direct_play: directPlay ? 1 : 0
        };
        const prev = (preview) ? '?preview=' + preview : '';

        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/course-subscription-sessions${prev}`, params)
            .pipe(this.statisticsAggregator());
    }

    getCourseSubscriptionSession(id: number): Observable<CourseSubscriptionSession> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course-subscription-sessions/${id}`)
            .pipe(this.statisticsAggregator());
    }

    getCourseSectionContentReporting(company_id, id: number, acc_id, section_content_ids): Observable<any> {
        let params = { context: "course", content_ids: section_content_ids.join(',') }
        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${company_id}/contents/${id}/subscriptions/${acc_id}/activities`, { params })
            .pipe(map(data => data));
    }

    getCourseSubscriptionSessionReporting(company_id, id: number, acc_id): Observable<any> {
        let params = { context: "course", account_ids: [acc_id] }
        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${company_id}/contents/${id}/subscriptions`, { params })
            .pipe(map(data => data[acc_id]?.context));
    }

    putCourseSubscriptionSession(id: number, body: any, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/course-subscription-sessions/${id}`, body, { params });
    }

    resetCourseSubscriptionSession(id: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/course-subscription-sessions/${id}/reset`);
    }

    unsubscribe(id: number, params: any): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/subscriptions/courses/${id}`, { params: getParams(params) });
    }

    resetContentSectionSession(id: number): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/section-content-sessions/${id}/reset`, {});
    }
}
