export class DataHelper {
    public static isEmpty(content: any): boolean {
        if (content) {
            return Object.keys(content).length === 0;
        }
        return true;
    }

    public static changeCase(value: string, type: string = 'snake'): string {
        switch (type) {
            case 'camel':
                let splitedValue = value.split('_');
                splitedValue = splitedValue.map((splited, index) => {
                    if (index > 0) {
                        return splited.charAt(0).toUpperCase() + splited.slice(1);
                    }
                    return splited;
                });
                value = splitedValue.join('');
                break;
            case 'snake':
                value = value.split(/(?=[A-Z])/).join('_').toLowerCase();
                break;
        }

        return value;
    }

    public static isJSON(value: any): boolean {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    }
}
