<div [ngStyle]="{'width': width+'px', 'height': height+'px'}" class="media-holder" [ngClass]="{'m-media-holder_preview': mini, 'media-holder__pointer': pointer}" *ngIf="media && (status === 1 && init)" (click)="modalOpen(modalMedia)">
    <media-embed *ngIf="media.type === 'EMBED'  && !previewFile" [media]="media" [reloadUrl]="reloadUrl"></media-embed>
    <media-image *ngIf="media.type === 'IMG'" [media]="media"></media-image>
    <media-video *ngIf="media.type === 'VID'  && !previewFile" [media]="media"></media-video>
    <media-doc *ngIf="(media.type === 'DOC' || media.type === 'PDF') && !previewFile" [media]="media" [downloadable]="downloadable"></media-doc>
    <media-sound *ngIf="media.type === 'SND'" [media]="media"></media-sound>
    <media-none *ngIf="media.type === 'NONE'" [media]="media"></media-none>
</div>

<div *ngIf="status === 0 && init && media.type !== 'EMBED'">
    <div class="load-more animate">
        <div class="dot"></div>
    </div>
    <span>{{ 'media.upload.waiting-process' | translate }}</span>
</div>

<div *ngIf="status === -1 && init && media.type !== 'EMBED'">
    <span>{{ 'words.error-occurred' | translate }}</span>
</div>

<button class="check-document add check" *ngIf="previewFile && media.type !== 'IMG'" (click)="modalOpen(modalMedia)">
    <div class="interior">
        {{ media.title | truncate: 20 }}
    </div>
</button>

<ng-template #modalMedia let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <media-embed *ngIf="media.type === 'EMBED'" [media]="media"></media-embed>
            <media-image *ngIf="media.type === 'IMG'" [media]="media"></media-image>
            <media-video *ngIf="media.type === 'VID'" [media]="media"></media-video>
            <media-doc *ngIf="(media.type === 'DOC' || media.type === 'PDF')" [media]="media" [downloadable]="downloadable"></media-doc>
            <media-none *ngIf="media.type === 'NONE'" [media]="media"></media-none>
            <media-sound *ngIf="media.type === 'SND'" [media]="media"></media-sound>
        </div>
    </div>
</ng-template>

<div [ngStyle]="{'width': width+'px', 'height': height+'px'}" class="media-holder" *ngIf="!media">
    <img style="width: 100%; object-fit: cover;" *ngIf="!media && cover" [src]="cover">
</div>

<style>
    .media-holder__pointer {
        cursor: pointer;
    }

    .m-media-holder_preview {
        position: relative;
    }
</style>
