import { Component, EventEmitter } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { LinkerQuestion, LinkerQuestionSession, LinkerAnswerChoice } from '@stuplay';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-linker',
    templateUrl: 'linker.component.html',
    styleUrls: ['./linker.component.less']
})
export class LinkerComponent {
    showCorrectAnswer: boolean;
    question: LinkerQuestion;
    questionSession: LinkerQuestionSession;
    questionSessionChange: EventEmitter<LinkerQuestionSession> = new EventEmitter();
    subscription: Subscription;
    grabbed: boolean = false;

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    isAnswerSuccess(choice: LinkerAnswerChoice): boolean {
        if (this.questionSession.doneAt) {
            return choice.isCorrect;
        }
        return false;
    }

    isAnswerFailed(choice: LinkerAnswerChoice): boolean {
        if (this.questionSession.doneAt) {
            return !choice.isCorrect;
        }
        return false;
    }

    onDragStart(): void {
        this.grabbed = true;
    }

    onChange(choice: any, index: number): void {
        choice.position = index + 1;
        this.questionSessionChange.emit(this.questionSession);
        this.grabbed = false;
    }

    cursorDisable(): string {
        if (this.questionSession.doneAt) {
            return 'not-allowed'
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }

    dropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.onChange(event.item, event.currentIndex);
    }
}
