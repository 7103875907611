<div *ngIf="currentUser">
    <div id="dropdown" class="dropdown dropdown_advance dropdown_position_left dropdown_size_large header-camp__account" [ngClass]="{'dropdown_active': isOpen}">
        <div (click)="toggleDropdown()" class="dropdown__context">
            <img [src]="currentUser.picture + '?size=48'">
            <i class="fa icon-chevron-down"></i>
        </div>

        <div class="dropdown__content">
            <div class="dropdown__header d-none">
				<span>
					{{ 'topbar.account.my-account' | translate }}
				</span>
            </div>

            <div class="dropdown__items-list">
                <!--<a href="{{campUrl}}/{{lang}}/user/settings" class="dropdown__item dropdown__item_selectable dropdown__item_icon">-->
                    <!--<i class="icon icon-settings-2-outline color-black"></i>-->
                    <!--<span>-->
						<!--<strong>{{'topbar.account.settings' | translate }}</strong>-->
                        <!--{{'topbar.account.settings.description' | translate }}-->
					<!--</span>-->
                <!--</a>-->

                <a (click)="navigateProfile()" class="dropdown__item dropdown__item_selectable dropdown__item_icon" style="border-bottom: 1px solid #d7e3f6;">
                    <i class="icon icon-mentoring color-black"></i>
                    <span>
						<strong>{{ 'topbar.account.profile' | translate }}</strong>
                        {{ currentUser.name }}
					</span>
                </a>

                <a [title]="'words.leave-course' | translate" class="dropdown__item dropdown__item_selectable dropdown__item_icon" target="_blank" (click)="back()">
                    <i class="d-none icon icon-corner-up-left-outline color-black"></i>
                    <span>
						<strong>{{ 'words.leave-course' | translate }}</strong>
					</span>
                </a>

                <!--<button (click)="logout()" class="dropdown__item dropdown__footer dropdown__item_icon dropdown__item_selectable">-->
                    <!--<i class="icon icon-power-outline color-black"></i>-->
                    <!--<span>-->
						<!--<strong>{{'topbar.account.log-out' | translate }}</strong>-->
                        <!--{{'topbar.account.log-out.description' | translate }}-->
					<!--</span>-->
                <!--</button>-->
            </div>
        </div>
    </div>
</div>
