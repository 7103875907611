import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Question, QuestionSession, Exercise, SectionContent } from '@stuplay';
import { NavigationProvider } from '../../../utils/services/navigation.provider';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-exercise',
    templateUrl: 'exercise.component.html',
})
export class ExerciseComponent implements OnInit, OnDestroy {
    @Input() question: Question;
    @Input() questionSession: QuestionSession;
    exercise: Exercise;
    questionSubscription: Subscription;
    sectionContent: SectionContent;

    constructor(private route: ActivatedRoute, private navigation: NavigationProvider, private storageService: StorageService) {}

    ngOnInit() {
        this.questionSubscription = this.route.paramMap.subscribe(() => {
            this.question = this.route.snapshot.data['question'];
            this.exercise = this.route.snapshot.data['exercise'];
            this.questionSession = this.route.snapshot.data['questionSession'];
            this.questionSession.companyId = this.storageService.get('company')?.id;
            const sectionContentId = this.route.parent.snapshot.data?.sectionContentSession?.sectionContentId;
            this.sectionContent = (this.route.parent?.parent?.snapshot?.data?.section?.sectionContents || []).find((sectionContent: any) => {
                return sectionContent?.id === sectionContentId;
            });
        });
    }

    ngOnDestroy() {
        this.questionSubscription.unsubscribe();
    }

    nextQuestion(): void {
        this.navigation.goRoute('next');
    }
}
