import { Component } from '@angular/core';
import { Note, NoteProvider } from '@stuplay';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'msc-note',
    templateUrl: './note.component.html'
})

export class NoteComponent {
    currentNote: Note;
    notes: any[];
    templateId: number;

    constructor(
        private noteProvider: NoteProvider,
        private storageService: StorageService,
    ) {
        this.templateId = this.storageService.get('course')?.id
    }

    ngOnInit() {
        this.templateId = this.storageService.get('course')?.id
        this.noteProvider.getNotes(this.templateId).subscribe((notes: Note[]) => this.notes = notes);
    }

    setCurrentNote(note: any): void {
        this.currentNote = note;
    }

    resetCurrentNote(): void {
        this.currentNote = null
    }

    createNote(): void {
        this.noteProvider.createNote(this.templateId).subscribe((note) => {
            this.currentNote = note;
            this.notes.push(note)
        })
    }

    deleteNote(note: any): void {
        note.delete(note?.id).subscribe(() => {
            this.notes.splice(this.notes.indexOf(note), 1);
        });
    }
}
