import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../../stuplay-config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../../../../../media/index';
import { cleanObject } from "../../../../../helpers";

export class EventSessionMedia {
    id: number;
    eventId: number;
    eventSessionId: number;
    media: Media;

    constructor(
        public eventSessionMedia: any,
        private http: HttpClient,
        private stuplayConfig: StuplayConfig,
    ) {
        this.extends(eventSessionMedia);
    }

    extends(eventSessionMedia: any): EventSessionMedia {
        Object.assign(this, eventSessionMedia);
        return this;
    }

    delete(): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/events/${this.eventId}/session/${this.eventSessionId}/media/${this.id}`)
            .pipe(map((res) => null));
    }

    update(): Observable<EventSessionMedia> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/events/${this.eventId}/session/${this.eventSessionId}/media/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
