import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../../stuplay-config';

export class EventSessionIntegration {
    id: number;
    eventSessionId: number;
    providerName: string;
    resourceId: string;

    constructor(eventSessionIntegration: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(eventSessionIntegration);
    }

    extends(eventSessionIntegration: any): EventSessionIntegration {
        Object.assign(this, eventSessionIntegration);
        return this;
    }
}