import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { Section } from "../course/section/section";
import { SectionContent } from "../course/section/section-content/section-content";
import { Course } from "../course/course";

@Injectable()
export class ReUseListingProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getSectionContentsFromAccount(context: string[], id: number[]): Observable<Section> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section?includes=section-contents&section-content.context=${context.join(',')}&id=${id.join(',')}`)
            .pipe(map((section) => {
                return new Section(section, this.http, this.stuplayConfig);
            }));
    }

    getSectionFromAccount(id: number[]): Observable<Course> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/course?includes=section&id=${id}`)
            .pipe(map((course) => {
                return new Course(course, this.http, this.stuplayConfig);
            }));
    }

    getQuestionFromAccount(id: number[]): Observable<SectionContent> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content?includes=question&id=${id}`)
            .pipe(map((data) => new SectionContent(data, this.http, this.stuplayConfig)));
    }
}
