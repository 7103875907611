import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
//
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
//
import { Course, CourseSubscriptionSession, CourseSubscriptionSessionProvider } from '@stuplay';
//
import { UserProvider } from '../../../utils/services/user.provider';
import { BackUrlService } from '../../../utils/services/back-url.service';
import { StorageService } from 'src/app/utils/services/storage.service';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'msc-course-presentation',
    templateUrl: './course-presentation.component.html',
    styleUrls: ['./course-presentation.component.less'],
    providers: [DatePipe],
})

export class CoursePresentationComponent {
    public firstSectionId: number;
    public events: any[] = [];
    public lang: string = 'fr';
    public courseSubscriptionSession: CourseSubscriptionSession;
    public course: Course | any;
    public loading: boolean = false;
    public data: any;
    public preview: boolean = false;
    public external: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private userProvider: UserProvider,
        private fromService: BackUrlService,
        private modalService: NgbModal,
        private datePipe: DatePipe,
        private storageService: StorageService
    ) { }

    ngOnInit(): void {
        this.course = this.route.snapshot.data.course;
        this.courseSubscriptionSession = this.route.snapshot.data.courseSubscriptionSession;
        this.course.getEvents().subscribe((res: any) => {
            res.forEach((event: any) => {
                if (event.eventSessions[0].startAt && event.eventSessions[0].endAt) {
                    event.eventSessions[0].startAt = this.datePipe.transform(event.eventSessions[0].startAt, DATE_DEFAULT_FORMAT);
                    event.eventSessions[0].endAt = this.datePipe.transform(event.eventSessions[0].endAt, DATE_DEFAULT_FORMAT);
                    this.events.push(event);
                }
            })
        });

        if (this.course.sections[0]) {
            this.firstSectionId = this.course?.sections[0]?.id;
        }
        this.refreshStats();
        this.lang = this.userProvider.currentUser.lang;
        this.data = { message: '' };

        this.route.queryParams.subscribe((params: Params) => {
            this.preview = !!params['preview'];
            if (params.external || params.direct_play || params.onboarding) {
                this.external = params.external === 'true' || params.direct_play === 'true' || params.onboarding === 'true';
            }
        });
    }

    private refreshStats(): void {
        this.courseSubscriptionSessionProvider.getCourseSubscriptionSessionReporting(this.storageService.get('company').id, this.course?.id, this.storageService.get('me').id).subscribe((data) => {
            this.courseSubscriptionSession = data;
        });
    }

    /**
     *
     */
    openModalUnsubscribe(content: any): void {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    /**
     *
     */
    unsubscribe(): void {
        if (!this.loading) {
            this.loading = true;
            const params = {
                message: this.data.message
            };

            this.courseSubscriptionSessionProvider.unsubscribe(this.course?.id, params).subscribe(() => {
                this.loading = false;
                window.location.href = this.fromService.getBackUrl();
            });
        }
    }
}
