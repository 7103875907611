import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import * as moment from 'moment';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    private units: any = {
        en: {d: 'd', h: 'h', m: 'm', s: 's'},
        es: {d: 'd', h: 'h', m: 'm', s: 's'},
        de: {d: 'T', h: 'h', m: 'm', s: 's'},
        fr: {d: 'j', h: 'h', m: 'm', s: 's'},
        nl: {d: 'd', h: 'u', m: 'm', s: 's'},
        it: {d: 'g', h: 'o', m: 'm', s: 's'}
    };
    private unitsExtend: any = {
        en: {d: 'day', ds: 'days', h: 'hour', hs: 'hours', m: 'min', s: 'sec'},
        es: {d: 'día', ds: 'días', h: 'hora', hs: 'horas', m: 'min', s: 'sec'},
        de: {d: 'Tag', ds: 'Tage', h: 'Stunde', hs: 'Stunden', m: 'min', s: 'Sek'},
        fr: {d: 'jour', ds: 'jours', h: 'heure', hs: 'heures', m: 'min', s: 'sec'},
        nl: {d: 'dag', ds: 'dagen', h: 'uur', hs: 'uren', m: 'min', s: 'sec'},
        it: {d: 'giorno', ds: 'giorni', h: 'ora', hs: 'orari', m: 'min', s: 'sec'}
    };
    private lang: string = 'en';

    constructor(private translationService: TranslationService) {}

    transform(duration: number, extend?: boolean): any {
        this.lang = this.translationService.getCurrentLang();

        return extend ? this.processDurationExtend(duration) : this.processDuration(duration);
    }

    private processDuration(duration: number): any {
        if (duration >= 86400) {
            return Math.round(duration / 3600 / 24) + this.units[this.lang].d;
        } else if (duration >= 3600) {
            return moment.utc(duration * 1000).format('H[' + this.units[this.lang].h + ']mm');
        } else if (duration >= 60) {
            return moment.utc(duration * 1000).format('m[' + this.units[this.lang].m + ']');
        } else {
            return moment.utc(duration * 1000).format('s[' + this.units[this.lang].s + ']');
        }
    }

    private processDurationExtend(duration: number): any {
        if (duration >= 86400) {
            const time = Math.round(duration / 3600 / 24);
            return time + ' ' + (time > 1 ? this.unitsExtend[this.lang].ds : this.unitsExtend[this.lang].d);
        } else if (duration >= 3600) {
            const time = Math.round(duration / 3600);
            return time + ' ' + (time > 1 ? this.unitsExtend[this.lang].hs : this.unitsExtend[this.lang].h);
        } else if (duration >= 60) {
            return Math.round(duration / 60) + ' ' + this.unitsExtend[this.lang].m;
        } else {
            return duration + ' ' + this.unitsExtend[this.lang].s;
        }
    }
}
