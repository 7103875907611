import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { TranslationService } from './translation.service';
import * as Sentry from '@sentry/angular';

@Injectable()
export class ExternalAppsService {
    private booted: any;
    private appIds: any;

    constructor(
        private storageService: StorageService,
        private translateService: TranslationService,
    ) {
        this.booted = {
            sentry: false
        };

        this.appIds = {
        };
    }

    boot(me: any): void {
        if (!(environment as any)?.local) {
            this.bootSentry(me);
        }
    }

    /**
     * Boot sentry system
     */
    bootSentry(me: any): void {
        if (!this.booted?.sentry) {
            this.booted.sentry = true;

            Sentry.setUser(
                {
                    id: me?.id,
                    username: me?.name,
                    email: me?.email
                }
            );
        }
    }
}
