import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Course, SectionContentSession, CourseSubscriptionSession, CourseSubscriptionSessionProvider } from '@stuplay';
import { NavigationProvider } from '../../services/navigation.provider';
import { OrderedService } from '../../services/ordered.service';
import { StatsService } from '../../services/stats.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { EmitterService } from '../../services/emitter.service';
import { BackUrlService } from '../../services/back-url.service';

@Component({
    selector: 'msc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent {
    @Input() course: Course;
    @Input() showSidebar: any;
    @Input() external: boolean = false;
    @Output() closeSidebar: EventEmitter<any> = new EventEmitter;
    courseSubscriptionSession: CourseSubscriptionSession;
    sectionOpen: Array<boolean> = [];
    questionsSessions: any[] = [];
    sectionNumber: any;
    loading: boolean = false;
    preview: boolean;
    currentSectionContentId: number;
    displayNav;
    public logo: string = '';

    constructor(
        private navProvider: NavigationProvider,
        private eRef: ElementRef,
        private route: ActivatedRoute,
        private router: Router,
        private orderedService: OrderedService,
        private statsService: StatsService,
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private modalService: NgbModal,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private fromService: BackUrlService,
        
    ) {
        this.courseSubscriptionSession = this.navProvider?.currentCourseSubscriptionSession;
        this.orderedService.setSession(this.courseSubscriptionSession);
        this.orderedService.setSection(this.navProvider?.currentCourse?.sections);
        this.setQuestionsSessions();
        this.route.queryParams.subscribe((params: Params) => {
            this.preview = params['preview'];
        });
    }

    ngOnInit() {
        this.route.children[0].params.subscribe((data) => {
            this.sectionNumber = data?.sectionId;
            this.course.sections.forEach((section) => {
                this.openSection(section);
            });
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.displayNav = false;
                if (event.url.match('/section/')) {
                    this.displayNav = !this.course.isTrajectContent || (this.course.isTrajectContent && this.course.templateId);
                    this.sectionNumber = event.url.replace('?preview=true', '').split('/section/')[1].split('/')[0];
                    this.course.sections.forEach((section) => {
                        this.openSection(section);
                    });
                }
            }
        });

        this.statsService.listenRefresh().subscribe(() => {
            this.courseSubscriptionSessionProvider.getOrCreateCourseSubscriptionSession(this.course?.id, this.preview).subscribe((data) => {
                this.courseSubscriptionSession = this.navProvider.currentCourseSubscriptionSession = data;
                this.orderedService.setSession(this.courseSubscriptionSession);
            });
        });

        this.logo = `${this.storageService.get('company').experience.displayPicture}?size=512`;
    }

    setQuestionsSessions(): void {
        this.questionsSessions = [];
        this.courseSubscriptionSession.sectionContentSessions.forEach((sectionContentSession: SectionContentSession) => {
            sectionContentSession.questionSessions.forEach((questionSession) => {
                this.questionsSessions.push(questionSession);
            });
        });
    }

    back(): void {
        const me = this.storageService.get('me');
        const company = this.storageService.get('company');

        document.location.replace(this.fromService.getBackUrl());
    }

    navigate(sectionContent: any, section: any): any {
        switch (sectionContent?.context) {
            case 'media':
                return ['section', section?.id, sectionContent?.context, sectionContent?.media?.id, 'content', sectionContent?.id];
            case 'external_link':
                return ['section', section?.id, 'external-link', sectionContent?.externalLink?.id];
            default:
                return ['section', section?.id, sectionContent?.context, sectionContent[sectionContent?.context]?.id];
        }
    }

    openSection(section: any, origin?: string): void {
        this.scroller();
        const sectionIndex = this.course.sections.findIndex((elementSection) => elementSection?.id === section?.id);
        if (origin === 'template') {
            this.sectionOpen[sectionIndex] = !this.sectionOpen[sectionIndex];
        } else {
            if (this.sectionNumber === (section?.id).toString()) {
                this.sectionOpen[sectionIndex] = true;
            }
        }

        this.emitterService.set('sectionOpen', true);
    }

    getClasses(questionId: number): string {
        this.setQuestionsSessions();
        const questionSession = this.questionsSessions.find((questionSession) => questionSession?.questionId === questionId);
        if (questionSession) {
            const done: string = `${questionSession.doneAt && 'is-done'}`;
            const correction: string = `${(questionSession.doneAt && questionSession.isCorrect) ? 'success' : 'fail'}`;
            if (questionSession.type !== 'open-write' && done && correction) {
                return done + ' ' + correction
            } else if (questionSession.type === 'open-write' && questionSession.doneAt && !questionSession.correctedAt) {
                return 'pending';
            } else if (questionSession.type === 'open-write' && questionSession.doneAt && questionSession.correctedAt) {
                return 'is-done ' + (questionSession.isCorrect ? 'success' : 'fail');
            } else {
                return '';
            }
        }
    }

     // li
    getSectionContentClasses(sectionContentId: number): string {
        const sectionContentSession = this.courseSubscriptionSession.sectionContentSessions.find((sectionContentSession) => sectionContentSession?.sectionContentId === sectionContentId);
        return (sectionContentSession && sectionContentSession.progress === 100) ? 'is-done success' : '';
    }

    getProgressColor(progress: number): string {
        // Definimos el color inicial y final utilizando variables CSS
        const startColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
        const middleColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-600').trim();
        const endColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-700').trim();
    
        // Calculamos el color intermedio utilizando una interpolación lineal entre los colores
        const interpolatedColor = this.interpolateColor(startColor, endColor, progress);
        return interpolatedColor;
    }
    getVerticalProgressGradient(progress: number): string {
        const startColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
        const endColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-700').trim();
    
        // Calculamos el porcentaje de progreso en términos de degradado vertical
        const progressPercentage = progress * 100;
    
        // Creamos un gradiente vertical basado en el progreso
        return `linear-gradient(to bottom, ${startColor} ${progressPercentage}%, #e3e3e3 ${progressPercentage}%)`;
    }
    
    //getClass(progress: number): string { return (progress == 1.0-0) }
    
    
    interpolateColor(color1: string, color2: string, weight: number): string {
        // Convertimos los colores de formato hexadecimal a sus componentes RGB
        const r1 = parseInt(color1.substring(1, 3), 16);
        const g1 = parseInt(color1.substring(3, 5), 16);
        const b1 = parseInt(color1.substring(5, 7), 16);
    
        const r2 = parseInt(color2.substring(1, 3), 16);
        const g2 = parseInt(color2.substring(3, 5), 16);
        const b2 = parseInt(color2.substring(5, 7), 16);
    
        // Calculamos los componentes RGB del color intermedio
        const r = Math.round(r1 * (1 - weight) + r2 * weight);
        const g = Math.round(g1 * (1 - weight) + g2 * weight);
        const b = Math.round(b1 * (1 - weight) + b2 * weight);
    
        // Convertimos los componentes RGB nuevamente a formato hexadecimal
        const interpolatedColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    
        return interpolatedColor;
    }
    
    getIconesClasses(sectionContentContext: string): string {
        switch (sectionContentContext) {
            case 'cheatsheet':
                return 'icon icon-link-outline';
            case 'discussion':
                return 'icon icon-message-square-outline';
            case 'event':
                return 'icon icon-icon_calendar';
            case 'media':
                return 'icon icon-media';
            case 'project':
            case 'selfassessment':
                return 'icon icon-courses';
            case 'scorm':
            case 'scormcloud':
            case 'external_link':
                return 'icon icon-link-outline';
            case 'exchange':
                return 'icon icon-exchange';
            case 'certificate':
                return 'icon icon-certificate';
        }
    }

    isDisabled(section: any): boolean {
        if (!this.course.isOrdered) {
            return false;
        }

        this.orderedService.setSection(this.navProvider.currentCourse.sections);

        return !this.orderedService.isSectionExist(section?.id);
    }

    toggleSidebar() {
        this.closeSidebar.emit();
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (this.eRef.nativeElement.contains(event.target) || event.target.className === 'overlay') {
            this.toggleSidebar();
        }
    }

    scroller(): void {
        const timeOut = setTimeout(() => {
            if (document?.getElementById('view')?.scroll) {
                document.getElementById('view').scroll(0, 0);
            }
            clearTimeout(timeOut);
        }, 50);
    }

    openModalReset(id: number, content: any): void {
        this.currentSectionContentId = id;
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    resetQuiz(): void {
        this.loading = true;
        const sectionContentSession = this.courseSubscriptionSession.sectionContentSessions.find((sectionContentSession) => {
            return sectionContentSession.sectionContentId === this.currentSectionContentId;
        });

        this.courseSubscriptionSessionProvider.resetContentSectionSession(sectionContentSession?.id).subscribe(() => {
            window.location.reload();
        });
    }
}
