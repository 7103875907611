import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const RecordRTC = require('recordrtc/RecordRTC.min');
const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;


@Component({
    selector: 'media-audio',
    templateUrl: './audio.component.html',
})

export class MediaAudioComponent {
    @ViewChild('audio', { static: false }) audio;
    @ViewChild('recordedSounds', { static: false }) recordedSounds;

    stream: any;
    recordRTC: any;
    audioRecorded = [];

    constructor(
        public sanitizer: DomSanitizer,
    ) { }

    ngAfterViewInit() {
        // set the initial state of the video
        const audio: HTMLAudioElement = this.audio.nativeElement;
        audio.muted = false;
        audio.controls = true;
        audio.autoplay = false;
    }

    toggleControls() {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        audio.muted = !audio.muted;
        audio.autoplay = !audio.autoplay;
    }

    successCallback(stream: any) {
        const options = {
            type: 'audio',
            recorderType: StereoAudioRecorder
        };
        this.stream = stream;
        this.recordRTC = RecordRTC(stream, options);
        this.recordRTC.startRecording();
        let audio: HTMLAudioElement = this.audio.nativeElement;
        audio.src = window.URL.createObjectURL(stream);
        this.toggleControls();
    }

    errorCallback() {
        // handle error here
    }

    processAudio(audioVideoWebMURL) {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        const recordRTC = this.recordRTC;
        audio.src = audioVideoWebMURL;
        this.toggleControls();
        const recordedBlob = recordRTC.getBlob();
        this.audioRecorded.push(this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(recordedBlob)));
        recordRTC.getDataURL(function(dataURL) { });
        this.storeRecord(recordedBlob);
    }

    startRecording() {
        const mediaConstraints = {
            audio: true
        };
        navigator.mediaDevices
            .getUserMedia(mediaConstraints)
            .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }

    stopRecording() {
        const recordRTC = this.recordRTC;
        recordRTC.stopRecording(this.processAudio.bind(this));
        const stream = this.stream;
        stream.getAudioTracks().forEach(track => track.stop());
    }

    storeRecord(srcUrl) {
        const recordedSounds = this.recordedSounds.nativeElement;
        recordedSounds.insertAdjacentHTML('beforeend', '<audio src="' + window.URL.createObjectURL(srcUrl) + '"controls="true"></audio>');
    }

    download() {
        this.recordRTC.save('audio.mp3');
    }
}
