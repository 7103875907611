import { APP_INITIALIZER, ErrorHandler, NgModule, Injector } from '@angular/core';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as Sentry from '@sentry/angular';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'angular2-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxUploaderModule } from 'ngx-uploader';

import { AuthGuard } from './auth-guard';
import { CompanyGuard } from './company-guard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';

/* Services */
import { AuthService } from './utils/services/auth.service';
import { BackUrlService } from './utils/services/back-url.service';
import { EmitterService } from './utils/services/emitter.service';
import { ExternalAppsService } from './utils/services/external-apps.service';
import { NavigationProvider } from './utils/services/navigation.provider';
import { OrderedService } from './utils/services/ordered.service';
import { RedirectService } from './utils/services/redirect.service';
import { RefreshAuthService } from './utils/services/refresh-auth.service';
import { StatisticsService } from './utils/services/statistics.service';
import { StatsService } from './utils/services/stats.service';
import { TranslationService } from './utils/services/translation.service';
import { UserProvider } from './utils/services/user.provider';

// Pipes
import { CleanPipe } from './utils/pipes/clean.pipe';
import { DurationPipe } from './utils/pipes/duration.pipe';
import { FileSizePipe } from './utils/pipes/filesize.pipe';
import { ResultPipe } from './utils/pipes/result.pipe';
import { SafePipe } from './utils/pipes/safe.pipe';
import { TimePipe } from './utils/pipes/time.pipe';
import { TruncatePipe } from './utils/pipes/truncate.pipe';
import { UrlPipe } from './utils/pipes/url.pipe';

// Pages
import { CertificateComponent } from './pages/section/certificate/certificate.component';
import { CheatSheetComponent } from './pages/section/cheatsheet/cheatsheet.component';
import { CourseComponent } from './pages/course/course.component';
import { CourseEndComponent } from './pages/course/course-end/course-end.component';
import { CoursePresentationComponent } from './pages/course/course-presentation/course-presentation.component';
import { EventComponent } from './pages/section/event/event.component';
import { ExchangeComponent } from './pages/section/exchange/exchange.component';
import { ExerciseComponent } from './pages/section/exercise/exercise.component';
import { ExternalLinkComponent } from './pages/section/external-link/external-link.component';
import { FillInComponent } from './pages/section/exercise/questions/fill-in/fill-in.component';
import { HomeComponent } from './pages/home/home.component';
import { HubComponent } from './pages/hub/hub.component';
import { LessonMediaComponent } from './pages/section/lesson-media/lesson-media.component';
import { LinkerComponent } from './pages/section/exercise/questions/linker/linker.component';
import { LoginComponent } from './pages/login/login.component';
import { MscFlashCardsQuestionComponent } from './pages/section/exercise/questions/flash-cards.component';
import { MultipleComponent } from './pages/section/exercise/questions/multiple/multiple.component';
import { OpenAudioComponent } from './pages/section/exercise/questions/open-audio/open-audio.component';
import { OpenWriteComponent } from './pages/section/exercise/questions/open-write/open-write.component';
import { OrderedComponent } from './pages/section/exercise/questions/ordered/ordered.component';
import { PlayerComponent } from './pages/player/player.component';
import { ProcedureComponent } from './pages/section/exercise/questions/procedure/procedure.component';
import { ProjectComponent } from './pages/section/project/project.component';
import { QuestionComponent } from './pages/section/exercise/questions/question.component';
import { ResultsComponent } from './pages/results/results.component';
import { ScormCloudComponent } from './pages/section/scorm/scorm-cloud.component';
import { ScormComponent } from './pages/section/scorm/scorm.component';
import { SectionComponent } from './pages/section/section.component';
import { SectionPresentationComponent } from './pages/section/section-presentation/section-presentation.component';
import { SelfAssessmentComponent } from './pages/section/self-assessment/self-assessment.component';
import { SurveyComponent } from './pages/course/survey/survey.component';
import { TrueFalseComponent } from './pages/section/exercise/questions/true-false/true-false.component';
import { UniqueQuestionChoiceComponent } from './pages/section/exercise/questions/unique/unique.component';
import { VideoComponent } from './pages/section/video/video.component';

// Utils
import { AccountComponent } from './utils/components/account/account.component';
import { CommentComponent } from './utils/components/timeline/comment/comment.component';
import { ErrorInterceptorService } from './utils/interceptors/error.interceptor.service';
import { FooterComponent } from './utils/components/footer/footer.component';
import { FroalaComponent } from './utils/components/froala/froala.component';
import { LibraryModule } from './utils/library';
import { LinkManagerComponent } from './utils/components/link-preview/link-manager/link-manager.component';
import { LinkPreviewComponent } from './utils/components/link-preview/link-preview.component';
import { liveEditorComponent } from './utils/components/live-editor/live-editor.component';
import { MediaAudioComponent } from './utils/media/audio/audio.component';
import { MediaButtonUploadComponent } from './utils/media/media-button-upload.component';
import { MediaComponent } from './utils/media/media.component';
import { MediaDocComponent } from './utils/media/doc/doc.component';
import { MediaEmbedComponent } from './utils/media/embed/embed.component';
import { MediaImageComponent } from './utils/media/image/image.component';
import { MediaManagerComponent } from './utils/media/media-manager.component';
import { MediaNoneComponent } from './utils/media/none/none.component';
import { MediaSoundComponent } from './utils/media/sound/sound.component';
import { MediaVideoComponent } from './utils/media/video/video.component';
import { MscNextNavComponent } from './utils/components/navigation/next-prev-nav.component';
import { MscNextPrevComponent } from './utils/components/navigation/next-prev-btn.component';
import { NoteComponent } from './utils/components/note/note.component';
import { NoteDetailComponent } from './utils/components/note/note-detail/note-detail.component';
import { PostComponent } from './utils/components/timeline/post/post.component';
import { QuestionHostDirective } from './utils/directives/question-host.directive';
import { SidebarComponent } from './utils/components/sidebar/sidebar.component';
import { SingleUploadComponent } from './utils/media/single-upload.component';
import { TimelineAddContentComponent } from './utils/components/timeline/timeline-add-content.component';
import { TimelineComponent } from './utils/components/timeline/timeline.component';
import { TimelineDeleteContentComponent } from './utils/components/timeline/timeline-delete-content.component';
import { TimelineShowContentComponent } from './utils/components/timeline/timeline-show-content.component';
import { TimerComponent } from './utils/components/timer/timer.component';
import { TimerService } from './utils/components/timer/timer.service';
import { ToastComponent } from './utils/components/toast/toast.component';
import { ToastService } from './utils/components/toast/toast.service';
import { TokenInterceptorService } from './utils/interceptors/token.interceptor.service';
import { TopBarComponent } from './utils/components/topbar/topbar.component';
import { UrlHelper } from './utils/helpers';

import { CompanyProvider, StuplayModule } from './stuplay';
import { stuplayConfigProvider } from './stuplay-config.provider';

import { environment } from '../environments/environment';
import { Router } from '@angular/router';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

export function BaseHrefFactory() {
    if (UrlHelper.getHostname() === environment.envVar.PLAYER_URL.replace('https://', '') || UrlHelper.getDomain().includes('deltabluecdn.com')) {
        return '/';
    }
    return '/player/';
}

export let AppInjector: Injector;

@NgModule({
    imports: [
        Angular2PromiseButtonModule.forRoot(),
        AppRoutingModule,
        BrowserModule,
        DragDropModule,
        FormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        HttpClientModule,
        InfiniteScrollModule,
        LibraryModule,
        MomentModule,
        NgbModule,
        NgProgressModule,
        NgxUploaderModule,
        StuplayModule.forRoot(),
    ],
    declarations: [
        HubComponent,
        AppComponent,
        HomeComponent,
        LoginComponent,
        PlayerComponent,
        TopBarComponent,
        SidebarComponent,
        AccountComponent,
        liveEditorComponent,

        // Course
        CourseComponent,
        CoursePresentationComponent,
        CourseEndComponent,

        // Section
        SectionComponent,
        SectionPresentationComponent,

        // Media
        MediaComponent,
        MediaEmbedComponent,
        MediaImageComponent,
        MediaVideoComponent,
        MediaNoneComponent,
        MediaSoundComponent,
        MediaDocComponent,
        MediaAudioComponent,
        MediaButtonUploadComponent,
        SingleUploadComponent,

        // CheatSheet
        CheatSheetComponent,
        LessonMediaComponent,

        // Exercise
        ExerciseComponent,
        MscFlashCardsQuestionComponent,
        QuestionComponent,
        FillInComponent,
        MultipleComponent,
        OpenWriteComponent,
        OrderedComponent,
        ProcedureComponent,
        TrueFalseComponent,
        OpenAudioComponent,
        LinkerComponent,
        UniqueQuestionChoiceComponent,

        // Pipe
        TimePipe,
        ResultPipe,
        UrlPipe,
        TruncatePipe,
        SafePipe,
        CleanPipe,
        FileSizePipe,
        DurationPipe,

        // CommentComponent
        MscNextPrevComponent,
        MscNextNavComponent,
        EventComponent,
        ProjectComponent,
        TimelineComponent,
        TimelineAddContentComponent,
        TimelineShowContentComponent,
        TimelineDeleteContentComponent,
        PostComponent,
        CommentComponent,

        // Exchange
        ExchangeComponent,

        // Media
        MediaManagerComponent,

        // Certification
        CertificateComponent,

        // Self Assessment
        SelfAssessmentComponent,

        // Footer
        FooterComponent,

        // Notes
        NoteDetailComponent,
        NoteComponent,

        // Scorm
        ScormComponent,
        ScormCloudComponent,

        // External link - Lti
        ExternalLinkComponent,

        // Results
        ResultsComponent,

        // Video
        VideoComponent,

        // Survey
        SurveyComponent,

        // Timer
        TimerComponent,

        // Toast
        ToastComponent,

        // Link Preview
        LinkManagerComponent,
        LinkPreviewComponent,

        // Froala
        FroalaComponent,

        // Directives
        QuestionHostDirective,
    ],
    entryComponents: [
        FillInComponent,
        LinkerComponent,
        MultipleComponent,
        OpenAudioComponent,
        OpenWriteComponent,
        OrderedComponent,
        ProcedureComponent,
        TrueFalseComponent,
        UniqueQuestionChoiceComponent,
    ],
    providers: [
        AuthGuard,
        CompanyGuard,
        AuthService,
        BackUrlService,
        DatePipe,
        EmitterService,
        ExternalAppsService,
        NavigationProvider,
        OrderedService,
        RedirectService,
        RefreshAuthService,
        StatisticsService,
        StatsService,
        stuplayConfigProvider,
        TimerService,
        ToastService,
        TranslationService,
        UserProvider,
        CompanyProvider,
        {
            provide: APP_BASE_HREF,
            useFactory: BaseHrefFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private injector: Injector){
        AppInjector = this.injector;
    }
 }
