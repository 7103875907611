import { Component, Renderer2, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
//
import { Subscription } from 'rxjs';
//
import { Event, EventSession, SectionContent, SectionContentSession, Course } from '@stuplay'
//
import { UserProvider } from '../../../utils/services/user.provider';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { environment } from '../../../../environments/environment';
import { NavigationProvider } from '../../../utils/services/navigation.provider';
import { ToastService } from '../../../utils/components/toast/toast.service';
import { StorageService } from '../../../utils/services/storage.service';
import { UrlHelper } from 'src/app/utils/helpers';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'msc-event',
    templateUrl: './event.component.html',
    providers: [DatePipe],
})

export class EventComponent {
    @ViewChildren('downloadableEventSessionMedia') aDownload: QueryList<ElementRef>;

    public course: Course;
    public event: Event;
    public eventSession: EventSession;
    public eventSubscription: Subscription;
    public me: any;
    public preview: boolean = false;
    public sectionContent: SectionContent;
    public sectionContentSession: SectionContentSession;
    public status = 'subscription';
    public apiURL = UrlHelper.getDomainFromUrl(environment.envVar.API_URL);

    constructor(
        public timerService: TimerService,
        private route: ActivatedRoute,
        private userProvider: UserProvider,
        private router: Router,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        private navProvider: NavigationProvider,
        private toastService: ToastService,
        private storageService: StorageService,
        private datePipe: DatePipe,
    ) {
        this.course = this.navProvider.currentCourse;
        this.eventSubscription = route.paramMap.subscribe((params) => {
            this.event = this.route.snapshot.data.event.event;
            this.sectionContent = this.route.snapshot.data.event;
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
            if (this.event.eventSessions[0]) {
                this.eventSession = this.route.snapshot.data?.event?.event?.eventSessions[0];
            }
            this.timerInit();
        });
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.event = this.route.snapshot.data.event.event;
        this.sectionContent = this.route.snapshot.data.event;
        this.sectionContentSession = this.route.snapshot.data.sectionContentSession;

        if (this.event.eventSessions[0]) {
            this.eventSession = this.route.snapshot.data?.event?.event?.eventSessions[0];
            this.eventSession.startAt = this.datePipe.transform(this.eventSession.startAt, DATE_DEFAULT_FORMAT);
            this.eventSession.endAt = this.datePipe.transform(this.eventSession.endAt, DATE_DEFAULT_FORMAT);
        }

        this.route.queryParams.subscribe((params: Params) => {
            this.preview = !!params.preview;
        });
    }

    ngOnDestroy(): void {
        this.eventSubscription.unsubscribe();
    }

    /**
     *
     */
    formatDate(date: string): any {
        return this.datePipe.transform(date, DATE_DEFAULT_FORMAT);
    }

    /**
     *
     */
    downloadEventSessionMedia(index: number): void {
        const event = new MouseEvent('click', { bubbles: true });
        (this.aDownload.toArray()[index].nativeElement as any)['dispatchEvent'].apply(this.aDownload.toArray()[index].nativeElement, [event]);
    }

    /**
     *
     */
    dynamicUrl(media: any): string {
        let url: any;
        if (media.type === 'IMG') {
            url = `${environment.envVar.STORAGE_URL}/${media.url}?size=raw`;
        } else {
            url = `${environment.envVar.STORAGE_URL}/${media.originalUrl}`;
        }

        return url;
    }

    /**
     *
     */
    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }

    /**
     *
     */
    finished(): void {
        this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe();
    }

    /**
     *
     */
    goToEvent(): void {
        const link = this.event.link + `?email=${this.me?.email}&lastName=${this.me.lastname}&firstName=${this.me?.firstname}`;
        window.open(link, '_blank');
    }
}
