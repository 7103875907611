import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { StuplayConfig } from '../../../../stuplay-config';
import { getParams } from "../../../../helpers";
@Injectable()
export class ExternalLinkProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    addExternalLink(params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/external-links`, params);
    }

    getExternalLink(id: number, params: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/external-links/${id}`, {params: getParams(params)});
    }

    updateExternalLink(id: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/create/external-links/${id}`, params);
    }

    deleteExternalLink(id: number, params: any): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/create/external-links/${id}`, {params: getParams(params)});
    }

    startExternalLink(id: number, params: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/external-links/${id}/play`, {params: getParams(params)});
    }

    updateSectionContent(id: number, params?: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content/${id}`, params);
    }

    getCourseSubscription(id: number, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course-subscription-sessions/${id}`, {params: getParams(params)});
    }

    getPreviewUrl(params: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/learning/external-resources/preview`, {params: getParams(params)});
    }

    addExternalResource(params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/learning/external-resources`, params);
    }

    addSkillToExternalResource(id: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/learning/external-contents/${id}/skills`, params);
    }
}
