import { Media } from '../../media';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { StuplayConfig } from '../../stuplay-config';
import { cleanObject } from "../../helpers";

export class Category {
    id: number;
    parentId: number;
    media: Media;
    isActive: boolean;
    title: string;
    slug: string;
    description: string;

    constructor(course: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(course);
    }

    extends(category: any): Category {
        Object.assign(this, category);
        return this;
    }

    update(): Observable<Category> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/category/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}

