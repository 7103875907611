import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MultipleQuestion, MultipleQuestionSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/utils/services/storage.service';
import { TimerService } from '../../../../../utils/components/timer/timer.service';

@Component({
    selector: 'msc-multiple',
    templateUrl: 'multiple.component.html',
    styleUrls: ['./multiple.component.less']
})
export class MultipleComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    internalQuestionSession: MultipleQuestionSession;
    question: MultipleQuestion;
    questionSession: MultipleQuestionSession;
    questionSessionChange: EventEmitter<MultipleQuestionSession> = new EventEmitter();
    solution: boolean = true;
    isRadio: boolean = false;

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        this.refreshSession();

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.isRadio = this.question.content.choices.filter(choice => choice.state).length == 1;
    }

    refreshSession(): void {
        let change = false;

        for (const i in this.question.content.choices) {
            if (!this.questionSession.answer.choices[i]) {
                this.questionSession.answer.choices[i] = {
                    choice: this.question.content.choices[i].choice,
                    state: null,
                    isCorrect: null
                };
                change = true;
            } else {
                if (this.question.content.choices[i].choice !== this.questionSession.answer.choices[i].choice) {
                    this.questionSession.answer.choices[i].choice = this.question.content.choices[i].choice;
                    change = true;
                }
            }
        }

        if (change) {
            const params = {
                requestCorrection: false,
                forceRefresh: true,
                company_id: this.storageService.get('company')?.id
            }
            this.questionSession.update(params).subscribe();
        }

        this.internalQuestionSession = Object.assign({}, this.questionSession);
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    changeTab(): void {
        this.solution = !this.solution;
    }

    onChange(): void {
        this.questionSessionChange.emit(this.questionSession);
    }

    singleSelect(choice: any) {
        this.internalQuestionSession.answer.choices.forEach(choice => choice.state = false);
        choice.state = true;
        this.questionSessionChange.emit(this.questionSession);
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }
}
