import { Component } from '@angular/core';

@Component({
    selector: 'msc-player',
    templateUrl: './player.component.html'
})
export class PlayerComponent {

    constructor() {}
}
