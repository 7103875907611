import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { getParams } from "../helpers";

@Injectable()
export class UserProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getCurrent(): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/me`).pipe(map((data: any) => data.account));
    }

    getCamps(): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/me/camps`).pipe(map((data: any) => data.camps));
    }

    getCompanies(params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/me/companies`, {params: getParams(params)});
    }
}