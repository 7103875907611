<div class="slide slide_presentation">
    <div class="column-left">
        <h2 class="heading">
            <span>{{ 'player.project.name'| translate }}</span> {{ project.title }}
        </h2>

        <p class="event-presentation__description multiline" [innerHTML]="sectionContent.description | safe:'html'"></p>

        <div *ngIf="project.type === 'document'" class="imported-files">
            <div class="imported-files__title">
                {{ 'player.media.imported-files' | translate }}
            </div>

            <div *ngIf="!sectionContentSession.projectAnswer.projectAnswerMedia">
                <span class="micro-data__info" *ngFor="let i of range[index]">
                    <media-button-upload [type]="'upload'" [hidden]="validate" [media]="medias[index][i]" (updateMedia)="onUpdateMedia($event)" (deleteMedia)="onDeleteMedia(i)" (onError)="onError()"></media-button-upload>
                </span>
            </div>

            <div class="error-message" *ngIf="error">
                {{ 'player.media.error-import' | translate }}
            </div>

            <div *ngIf="sectionContentSession?.projectAnswer?.projectAnswerMedia;">
                <span class="micro-data__info" *ngFor="let answerMedia of sectionContentSession.projectAnswer.projectAnswerMedia;">
                    <media-button-upload [media]="answerMedia.media"></media-button-upload>
                </span>
            </div>
        </div>

        <div *ngIf="project.type === 'cheatsheet'">
            <msc-froala [(content)]="content" (contentChange)="update()"></msc-froala>
        </div>

        <div *ngIf="project.type === 'discussion'">
            <app-timeline [context]="'project'" [contextId]="project?.id"></app-timeline>
        </div>

        <msc-next-prev-btn *ngIf="project.type === 'document'" (isFinished)="activityFinished()" type="project" [translator]="'.project'" [validate]="mediasId[index].length > 0" [doneAt]="sectionContentSession.doneAt" [loading]="loading"></msc-next-prev-btn>
        <msc-next-prev-btn *ngIf="project.type === 'cheatsheet'" (isFinished)="activityFinishedSheet($event)" [validate]="true" [doneAt]="sectionContentSession.doneAt" [loading]="loading"></msc-next-prev-btn>
    </div>
</div>

<app-footer [context]="'project'" [contextId]="project?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
