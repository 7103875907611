import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '@stuplay';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'timeline-add-content',
    templateUrl: './timeline-add-content.component.html'
})
export class TimelineAddContentComponent {
    @Input() placeholder: string;
    @Output() addContent = new EventEmitter();
    @Output() updateMedia = new EventEmitter();
    message: string = '';
    media: Media;

    constructor(
        private modalService: NgbModal,
    ) { }

    onAddContent(message: string): void {
        this.addContent.emit({ message: message, media: this.media, link: null });
        this.media = null;
        this.message = '';
    }

    onUpdatedMedia(media: any): void {
        this.media = media;
    }

    deleteMediaPreview(): void {
        this.media = null;
    }

    open(content: any, options: NgbModalOptions = { windowClass: 'force-fullscreen' }) {
        this.modalService.open(content, options);
    }
}
