import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../stuplay-config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../../../../media';
import { cleanObject, getParams } from "../../../../helpers";
import { StorageService } from 'src/app/utils/services/storage.service';
import { environment } from 'src/environments/environment';

export class Certificate {
    id: number;
    type: string;
    title: string;
    duration: number;
    description: string;
    validityDuration: number;
    gender: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    email: string;
    phoneNumber: number;
    address: string;
    media: Media;
    hash: string;
    minimumRatio: number;
    token: string;
    sendEmail: boolean;
    global: boolean;

    constructor(certificate: any, protected http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(certificate);
    }

    extends(certificate: any): Certificate {
        Object.assign(this, certificate);
        return this;
    }

    update(): Observable<Certificate> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/certificate/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    download(): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/certificate/${this.hash}/download`, { responseType: 'blob' });
    }

    sendMail(courseId: number, companyId?: number): Observable<any> {
        const data = {
            courseId: courseId,
            companyId: companyId ? companyId : null
        };

        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/certificate/${this.hash}/email`, data);
    }

    checkAchieved(courseId: number, sectionContentSessionId: number, companyId: number, userId: number): Observable<any> {
        const params = {
            context: "course"
        };
        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${companyId}/contents/${courseId}/users/${userId}/certificate`, { params: getParams(params) });
    }
}
