<div class="slide slide_module">
    <div *ngIf="sectionContent.isDownloadable && media.type !== 'EMBED' && media.type !== 'VID' && media.storage !== 'ext'" class="btn__group">
        <a (click)="downloadMedia()" class="btn btn_height_small">{{ 'player.download-media' | translate }}</a>
        <a #isDownloadable [href]="url" target="_blank"></a>
    </div>

    <h2 class="heading">
        <span>{{ 'player.lesson-media.name' | translate }}</span> {{ sectionContent.title }}
    </h2>
    <p *ngIf="sectionContent.description" class="multiline" [innerHTML]="sectionContent?.description | safe:'html'"></p>

    <div *ngIf="!detectPhone() && !detectIpad()" class="slide_video" (click)="openModal(modalFullScreen)" style="text-align: center;">
        <div class="open-fullscreen" *ngIf="media.type !== 'IMG' && media.type !== 'VID'">
            <img src="https://static.myskillcamp.com/images/icon/icon-player/icon_fullscreen_on.svg" alt="">
        </div>

        <media-holder *ngIf="media" [media]="media" [downloadable]="!!sectionContent.isDownloadable" [reloadUrl]="reloadUrl"></media-holder>
    </div>

    <div class="" *ngIf="ipad.enableDownload" style="text-align: center;">
        {{ 'player.download-pdf' | translate }}
    </div>

    <div *ngIf="detectPhone() || ipad.enableDownload" style="text-align: center;">
        <a (click)="downloadMedia()" class="btn btn_color_grey btn_height_small">
            {{ media.title }}
        </a>

        <a #isDownloadable [href]="url" target="_blank"></a>
    </div>

    <div id="m-loading" class="m-loading" *ngIf="detectIpad() && ipad.ready && !ipad.iframeLoaded">
        {{ 'player.loading' | translate }}
    </div>

    <div id="iframe-wrapper" class="m-ipad" *ngIf="detectIpad() && ipad.ready"></div>

    <msc-next-prev-btn (isFinished)="activityFinished()" [validate]="true" [doneAt]="sectionContentSession.doneAt" [loading]="loading"></msc-next-prev-btn>
</div>

<ng-template #modalFullScreen let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="closeModal(modalFullScreen)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="height: 100%;">
            <iframe [src]="urlIframe" width="100%" height="100%" allowfullscreen></iframe>
        </div>
    </div>
</ng-template>

<app-footer [context]="'media'" [contextId]="media?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    .m-loading {
        text-align: center;
    }

    .m-ipad {
        width: 100%;
    }

    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
