import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SectionContent } from './section-content';
import { Media } from '../../media';
import { StuplayConfig } from '../../stuplay-config';
import { cleanObject, getParams } from '../../helpers';

export class Section {
    id: number;
    courseId: number;
    position: number;
    newPosition?: number;
    title: string;
    sectionContents: SectionContent[];
    state: string;
    media?: Media;
    description?: string;

    constructor(section: any, private http: HttpClient, protected stuplayConfig: StuplayConfig) {
        this.extends(section);
    }

    extends(section: any): Section {
        Object.assign(this, section);
        if (this.sectionContents && this.sectionContents.length > 0) {
            this.sectionContents = this.sectionContents
                .map((sectionContent) => new SectionContent(sectionContent, this.http, this.stuplayConfig));
        }
        if (this.media) {
            this.media = new Media(this.media, this.http, this.stuplayConfig);
        }
        return this;
    }

    update(): Observable<Section> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/section/${this.id}?includes=section-contents`, cleanObject(this))
            .pipe(map((data) => this.extends(data)));
    }

    delete(): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/section/${this.id}`)
            .pipe(map(() => null));
    }

    addSectionContent(context: string, param: any): Observable<Section> {
        if (context == 'media' || context == 'scorm' || context == 'scormcloud' || context === 'external_link') {
            const sectionContent = {
                context: context,
                context_id: param?.contextId,
                section_id: this.id,
                position: param?.position
            };
            return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content`, sectionContent)
                .pipe(map((sectionContent: any) => {
                    this.sectionContents.splice(param.position, 0, new SectionContent(sectionContent, this.http, this.stuplayConfig));
                    return this;
                }));
        } else {
            const url = (context === 'event') ? `${this.stuplayConfig.envVar.API_URL}/events` : `${this.stuplayConfig.envVar.STUDIO_URL}/${context}`;
            return this.http.post(url, param)
                .pipe(switchMap((object: any) => {
                    const sectionContent = {
                        context: context,
                        context_id: object?.id,
                        section_id: this.id,
                        position: param?.position
                    };
                    return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content`, sectionContent);
                }),
                    map((sectionContent: any) => {
                        this.sectionContents.splice(param.position, 0, new SectionContent(sectionContent, this.http, this.stuplayConfig));
                        return this;
                    }));
        }
    }

    addContent(courseId: number, context: string, params: any): Observable<Section> {
        if (context == 'media' || context == 'scorm' || context == 'scormcloud' || context === 'external_link') {
            const content = {
                context: context,
                context_id: params?.contextId,
                position: params?.position
            };
            return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/courses/${courseId}/sections/${this.id}/contents`, content)
                .pipe(map((sectionContent: any) => {
                    this.sectionContents.splice(params?.position, 0, new SectionContent(sectionContent, this.http, this.stuplayConfig));
                    return this;
                }));
        } else {
            const url = (context === 'event') ? `${this.stuplayConfig.envVar.API_URL}/events` : `${this.stuplayConfig.envVar.STUDIO_URL}/${context}`;
            return this.http.post(url, params)
                .pipe(switchMap((object: any) => {
                    const content = {
                        context: context,
                        context_id: object?.id,
                        position: params?.position
                    };
                    return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/courses/${courseId}/sections/${this.id}/contents`, content);
                }),
                    map((sectionContent: any) => {
                        this.sectionContents.splice(params.position, 0, new SectionContent(sectionContent, this.http, this.stuplayConfig));
                        return this;
                    }));
        }
    }

    deleteSectionContent(id: number, params?: any): Observable<void> {
        const selectedSectionContent = this.sectionContents.find(sectionContent => sectionContent?.id === id);
        const selectedSectionContentIndex = this.sectionContents.findIndex(sectionContent => sectionContent?.id === id);
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content/${selectedSectionContent?.id}`, { params: getParams(params) })
            .pipe(map(() => {
                this.sectionContents.splice(selectedSectionContentIndex, 1);
                return null;
            }));
    }

    addMedia(media: Media): Observable<Section> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/media`, cleanObject(this))
            .pipe(map((media) => {
                this.media = new Media(media, this.http, this.stuplayConfig);
                return this;
            }));
    }

    deleteMedia(mediaId: number): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/media/${mediaId}`).pipe(map(() => null));
    }
}
