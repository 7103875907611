import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from '../../utils/services/storage.service';
import { OrderedService } from '../../utils/services/ordered.service';
import { ToastService } from '../../utils/components/toast/toast.service';

@Component({
    selector: 'section',
    templateUrl: './section.component.html',
})
export class SectionComponent {
    section: any;
    sectionSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private orderedService: OrderedService,
        private toastService: ToastService,
    ) { }

    ngOnInit() {
        this.sectionSubscription = this.route.paramMap.subscribe(() => {
            this.section = this.route.snapshot.data?.section;
        });

        if (this.storageService.get('course')?.isOrdered && !this.orderedService?.isSectionExist(this.section?.id)) {
            this.toastService.push('content-locked', 'warning');
        }
    }

    ngOnDestroy(): void {
        this.sectionSubscription.unsubscribe();
    }
}
