<label>
    <input type="text" placeholder="{{ placeholder }}" [(ngModel)]="message" debounce="1000" required>
    <button (click)='onAddContent(message)' type="submit" class="btn btn_action_post">
        {{'player.timeline.button-send-message' | translate }}
    </button>
</label>

<button *ngIf="!media" class="add-media" (click)="open(modalMedia)">{{'player.timeline.add-media' | translate}}</button>

<div *ngIf="media" class="media-manager media-manager_mini" style="height: 80px; width: 200px;">
    <media-holder [media]="media" [mini]="true"></media-holder>
    <div class="media-manager__actions">
        <button class="btn btn_color_red delete" (click)="deleteMediaPreview()">
            <span class="sr-only">{{ 'words.delete' | translate }}</span>
        </button>
    </div>
</div>

<ng-template #modalMedia let-modal>
    <library-modal (closeModal)="modal.close($event)" (updateMedia)="onUpdatedMedia($event)"></library-modal>
</ng-template>
