import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../stuplay-config';
import { Event } from './event';
import { Project } from './project/project';
import { CheatSheet } from './cheatsheet/cheatsheet';
import { Media } from '../../../media';
import { Exercise } from './exercise/exercise';
import { Exchange } from './exchange/exchange';
import { ScormModule } from './scorm/scorm-module';
import { Certificate } from './certificate/certificate';
import { Video } from './video/video';
import { Lti } from './lti';
import { SelfAssessment } from './self-assessment/self-assessment';
import { cleanObject } from '../../../helpers';

export class SectionContent {
    id: number;
    context: string;
    contextId: number;
    project?: Project;
    event?: Event;
    exercise?: Exercise;
    media?: Media;
    cheatsheet?: CheatSheet;
    exchange?: Exchange;
    scorm?: ScormModule;
    scormcloud?: ScormModule;
    externalLink?: Lti;
    video?: Video;
    certificate?: Certificate;
    selfassessment?: SelfAssessment;
    position: number;
    description: string;
    title: string;
    isDownloadable: boolean;
    isScorable: boolean;

    constructor(sectionContent: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(sectionContent);
    }

    extends(sectionContent: any): SectionContent {
        Object.assign(this, sectionContent);
        if (this.context === 'certificate' && this.certificate) {
            this.certificate = new Certificate(this.certificate, this.http, this.stuplayConfig);
        }
        if (this.context === 'project' && this.project) {
            this.project = new Project(this.project, this.http, this.stuplayConfig);
        }
        if (this.context === 'selfassessment' && this.selfassessment) {
            this.selfassessment = new SelfAssessment(this.selfassessment, this.http, this.stuplayConfig);
        }
        if (this.context === 'event' && this.event) {
            this.event = new Event(this.event, this.http, this.stuplayConfig);
        }
        if (this.context === 'media' && this.media) {
            this.media = new Media(this.media, this.http, this.stuplayConfig);
        }
        if (this.context === 'cheatsheet' && this.cheatsheet) {
            this.cheatsheet = new CheatSheet(this.cheatsheet, this.http, this.stuplayConfig);
        }
        if (this.context === 'exercise' && this.exercise) {
            this.exercise = new Exercise(this.exercise, this.http, this.stuplayConfig);
        }
        if (this.context === 'exchange' && this.exchange) {
            this.exchange = new Exchange(this.exchange, this.http, this.stuplayConfig);
        }
        if (this.context === 'scorm' && this.scorm) {
            this.scorm = new ScormModule(this.scorm, this.http, this.stuplayConfig);
        }
        if (this.context === 'scormcloud' && this.scormcloud) {
            this.scormcloud = new ScormModule(this.scormcloud, this.http, this.stuplayConfig);
        }
        if (this.context === 'external_link' && this.externalLink) {
            this.externalLink = new Lti(this.externalLink, this.http, this.stuplayConfig);
        }
        if (this.context === 'video' && this.video) {
            this.video = new Video(this.video, this.http, this.stuplayConfig);
        }
        return this;
    }

    update(): Observable<SectionContent> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    get(): Observable<SectionContent> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content/${this.id}`)
            .pipe(map((data) => this.extends(data)));
    }

    deleteEvent(eventId: number): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/event/${eventId}`)
            .pipe(map(res => null));
    }

    getEvent(id: number): Observable<Event> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/event/${id}`)
            .pipe(map(res => new Event(res, this.http, this.stuplayConfig)));
    }

    getProject(id: number): Observable<Project> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/project/${id}`)
            .pipe(map(res => new Project(res, this.http, this.stuplayConfig)));
    }

    deleteProject(projectId: number): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/project/${projectId}`)
            .pipe(map(res => null));
    }
}
