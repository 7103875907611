import { Component } from '@angular/core';
import { Project, SectionContent, SectionContentSession } from '@stuplay'
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-project',
    templateUrl: './project.component.html'
})
export class ProjectComponent {
    company: any;
    project: Project;
    sectionContent: SectionContent;
    sectionContentSession: SectionContentSession;
    projectSubscription: Subscription;
    range: any[];
    medias: any[];
    mediasId: any[];
    index: number;
    validate: boolean = false;
    error: boolean = false;
    content: any;
    loading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        public timerService: TimerService,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');

        this.projectSubscription = this.route.paramMap.subscribe(() => {
            this.project = this.route.snapshot.data?.project?.project;
            this.sectionContent = this.route.snapshot.data?.project;
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
            this.index = this.sectionContentSession?.sectionContentId;
            this.range = [];
            this.medias = [];
            this.mediasId = [];
            this.validate = false;
            this.initArrays();
            this.reloadButtonMedia();
            this.timerInit();
            this.content = this.sectionContentSession?.projectAnswer?.content;
        });
    }

    ngOnDestroy() {
        this.projectSubscription.unsubscribe();
    }

    update(): void {
        this.onChange(this.content);
    }

    onChange(content: any): void {
        this.sectionContentSession.createOrUpdateProjectAnswer({ content, companyId: this.company?.id }).subscribe();
    }

    activityFinished(): void {
        this.createAnswer();
    }

    activityFinishedSheet(event: any): void {
        this.loading = true;
        this.sectionContentSession
            .update(event, this.storageService.get('company')?.id)
            .pipe(finalize(() => this.loading = false))
            .subscribe();
    }

    onUpdateMedia(media: any): void {
        if (!media) { console.warn('onUpdateMedia', media); return; }
        this.medias[this.index].push(media);
        this.mediasId[this.index].push(media?.id);
        this.reloadButtonMedia();
    }

    onDeleteMedia(index: number): void {
        this.medias[this.index].splice(index, 1);
        this.mediasId[this.index].splice(index, 1);
        this.reloadButtonMedia();
    }

    onError(): void {
        this.error = true;
        const timeOut = setTimeout(() => {
            this.error = false;
            clearTimeout(timeOut);
        }, 5000);
    }

    reloadButtonMedia(): void {
        this.range[this.index] = [0];

        const length = (this.sectionContentSession.projectAnswer.projectAnswerMedia) ? this.sectionContentSession.projectAnswer.projectAnswerMedia.length : this.mediasId[this.index].length;
        for (let i = 0; i < length; i++) {
            this.range[this.index].push(i + 1);
        }

        if (this.sectionContentSession.projectAnswer.projectAnswerMedia) {
            this.range[this.index].pop();
        }
    }

    createAnswer(): void {
        this.validate = true;
        this.error = false;
        this.loading = true;

        this.sectionContentSession
            .createProjectAnswer(this.mediasId[this.index], this.company?.id)
            .pipe(
                switchMap(() => this.sectionContentSession.update(true, this.storageService.get('company')?.id)),
                finalize(() => this.loading = false)
            )
            .subscribe();
    }

    initArrays(): void {
        if (!this.mediasId[this.index]) {
            this.mediasId[this.index] = [];
        }
        if (!this.medias[this.index]) {
            this.medias[this.index] = [];
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
