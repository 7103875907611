<div class="media-manager" [ngClass]="{'media-manager_empty': !media, 'media-manager_mini': mini}">
    <div *ngIf="!media">
        <img *ngIf="placeholder" src="{{placeholder}}" />
        <div *ngIf="!placeholder" class="placeholder">
            <div class="placeholder__title">{{ 'studio.media.library' | translate }}</div>
            <div class="placeholder__text">{{ 'studio.media.add_media' | translate }}</div>
        </div>
    </div>
    <media-holder *ngIf="media" [media]="media"></media-holder>
    <div class="media-manager__actions">
        <button class="btn add-media" *ngIf="!media">
            <span class="sr-only"></span>
        </button>
        <button *ngIf="media" class="btn btn_color_red delete" (click)="delete()"><span class="sr-only">{{'player.common.button.delete' | translate }}</span></button>
    </div>
</div>

<ng-template #modalMedia let-modal>
    <library-modal [media]="media" (closeModal)="modal.close()" (updateMedia)="onUpdatedMedia($event)"></library-modal>
</ng-template>
