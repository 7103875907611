import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class StatsService {
    private refreshEmitter: EventEmitter<any> = new EventEmitter;

    listenRefresh(): EventEmitter<any> {
        return this.refreshEmitter;
    }

    refresh(emit: any): void {
        this.refreshEmitter.emit(emit);
    }
}
