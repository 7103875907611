import { Upload } from "../../../../media";
import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../stuplay-config';

export class ScormModule {
    upload?: Upload;
    url: string;
    id: number;
    displayType: 'embedded' | 'new_tab';

    constructor(scorm: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(scorm);
    }

    extends(scormModule: any): ScormModule {
        Object.assign(this, scormModule);
        return this;
    }

    update() {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/create/scorms/${this.id}`, {
            display_type: this.displayType
        });
    }
}
