import { Component, OnInit, Input } from '@angular/core';
import { UrlHelper } from '../../../helpers';

@Component({
    selector: 'msc-link-manager',
    templateUrl: './link-manager.component.html',
    styleUrls: ['./link-manager.component.scss']
})
export class LinkManagerComponent implements OnInit {
    @Input() content: string;
    @Input() class: string = 'm-link--white';
    links: string[] = [];

    constructor() { }

    ngOnInit() {
        if (this.content) {
            const splitter = this.content.split(/(\s|<br>)/gm);
            for (const item of splitter) {
                if (UrlHelper.test(item)) {
                    this.links.push(item);
                }
            }
        }
    }
}
