import { Injectable } from '@angular/core';

@Injectable()
export class StuplayConfig {
    envVar = {
        API_URL: '',
        STUDIO_URL: '',
        CLOUD_URL: ''
    }
}
