

<div>
    <div class="module__upload">
        <h1 class="heading">
            <span>{{ 'studio.media.import.head' | translate}}</span>
        </h1>
        <div>{{ 'studio.media.library.embed.hint' | translate}}</div>
        <label>{{ 'studio.common.url' | translate}}</label>
        <input type="text" placeholder="https://" [(ngModel)]="url" (ngModelChange)="preview('url')">
        <div>{{ 'studio.media.library.embed.hint2' | translate}}</div>
        <label>{{ 'studio.common.embed' | translate}}</label>
        <input type="text" [(ngModel)]="embed" (ngModelChange)="preview('embed')" >

        <div class="embed" *ngIf="trustedUrl" style="height: 300px; padding-bottom: 30% !important;">
            <iframe [src]="trustedUrl" width="50%" height="50%"></iframe>
        </div>
        <!--&lt;!&ndash; <span class="description"></span> &ndash;&gt;-->
        <!--<label class="slide slide_add slide_upload">-->
            <!--<span class="title"><span class="import">{{ 'studio.media.import.title' | translate}}</span></span>-->
            <!--<span class="description font-text">{{ 'studio.media.import.hint' | translate}}<br> {{ 'studio.media.import.max-weight' | translate}}</span>-->
            <!--<input type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">-->
        <!--</label>-->

        <div class="actions">
            <button class="btn pull-right btn-action" (click)="createEmbed()">{{ 'studio.media.embed.create' | translate}}</button>
        </div>
    </div>
</div>
