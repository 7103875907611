import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
    enableProdMode();
}

if (!(environment as any).local) {
    Sentry.init({
        dsn: 'https://a47b3d9fe04e47ea87d56053a9e6bdb7@o479680.ingest.sentry.io/6215043',
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['*.myskillcamp.com'],
                routingInstrumentation: Sentry.routingInstrumentation
            })
        ],
        tracesSampleRate: 1.0,
        environment: environment.production ? 'production' : 'development',
        ignoreErrors: [
            'Error: Cannot match any routes',
            'Handled unknown error',
            'Non-Error exception captured with keys',
            'ResizeObserver loop',
            'Http failure response',
            'InvalidStateError',
            '"status":40',
            'Not found',
            // new filters
            'HttpErrorResponse',
            'NG0',
            'Server returned code',
            'Cannot read properties of null (reading \'style\')'
        ],
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i
        ]
    });
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
