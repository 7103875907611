import { HttpClient } from "@angular/common/http";
import { StuplayConfig } from '../../../stuplay-config';
import { SurveyQuestionAnswer } from './survey-question-answers/survey-question-answers';

export class SurveyQuestion {
    id: number;
    title: string;
    type: string;
    surveyQuestionAnswers: SurveyQuestionAnswer[] = [];

    constructor(surveyQuestion: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(surveyQuestion);
    }

    extends(surveyQuestion: any): SurveyQuestion {
        Object.assign(this, surveyQuestion);
        if (this.surveyQuestionAnswers.length === 0) {
            this.surveyQuestionAnswers.push(new SurveyQuestionAnswer({content: null}, this.http, this.stuplayConfig));
        } else if (this.surveyQuestionAnswers && this.surveyQuestionAnswers.length > 0) {
            this.surveyQuestionAnswers = this.surveyQuestionAnswers.map((surveyQuestionAnswer) =>
                new SurveyQuestionAnswer(surveyQuestionAnswer, this.http, this.stuplayConfig));
        }
        return this;
    }
}
