import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { cleanObject } from '../../../../helpers';

export class CheatSheet {
    cheatsheetId: number;
    content: any; // To translate
    title: string;
    id: number;

    constructor(cheatsheet: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(cheatsheet);
    }

    extends(cheatsheet: any): CheatSheet {
        Object.assign(this, cheatsheet);
        return this;
    }

    update(): Observable<CheatSheet> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/cheatsheet/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
