import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { Journey } from './journey';
import { Course } from '../course';
import { SectionContent } from '../course/section/section-content';
import { Condition } from './condition';
import { getParams, cleanObject } from '../helpers';

@Injectable()
export class JourneyProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) { }

    getJourneys(query?: string, params?: any): Observable<any> {
        let data = {
            pagination: {},
            journeys: []
        };

        if (!query) {
            query = '';
        }

        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/trajects?q=${query}`, { params: getParams(params) })
            .pipe(map((res: any) => {
                data.pagination = res?.pagination;
                res.trajects.map((journey) => data.journeys.push(new Journey(journey, this.http, this.stuplayConfig)));
                return data;
            }));
    }

    getJourney(slug: string | number, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/trajects/${slug}`, { params: getParams(params) })
            .pipe(map((data) => new Journey(data, this.http, this.stuplayConfig)));
    }

    createJourney(journey: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects`, journey)
            .pipe(map((data) => new Journey(data, this.http, this.stuplayConfig)));
    }

    deleteJourney(id: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}`);
    }

    updateJourney(journey: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${journey?.id}`, cleanObject(journey))
            .pipe(map((data) => new Journey(data, this.http, this.stuplayConfig)));
    }

    cloneJourney(id: number): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/duplicate`, {})
            .pipe(map((data) => new Journey(data, this.http, this.stuplayConfig)));
    }

    getTemplates(id: number, params?: any): Observable<any> {
        let data = {
            pagination: {},
            templates: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/templates`, { params: getParams(params) })
            .pipe(map((res: any) => {
                data.pagination = res?.pagination;
                res.templates.map((course) => data.templates.push(new Course(course, this.http, this.stuplayConfig)));
                return data;
            }));
    }

    getTemplatesAndProviderContents(id: number, params?: any): Observable<any> {
        let response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/trajects/${id}/contents/available`, { params: getParams(params) })
            .pipe(map((data: any) => {
                response.pagination = data?.pagination;
                response.contents = data?.contents;
                return response;
            }));
    }

    getMeta(id: number, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/trajects/${id}/contents/available/meta`, { params: getParams(params) });
    }

    addContent(id: number, params: any): Observable<any> {
        if (typeof id !== 'number') { return; }
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/trajects/${id}/contents`, params);
    }

    changeContent(id: number, contentId: number, context: string, contextId: number, companyId: number): Observable<any> {
        const data = {
            context: context,
            contextId: contextId,
            companyId: companyId
        };

        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/${contentId}/change`, data);
    }

    updateContentPosition(id: number, contentId: number, position: number): Observable<any> {
        const data = {
            position: position
        };

        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/traject/${id}/content/${contentId}/position`, data);
    }

    getContentPosition(id: number): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/traject/${id}/contents/positions`);
    }

    deleteContent(id: number, contentId: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/${contentId}`);
    }

    positionContent(id: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/position`);
    }

    publish(id: number, settings: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/trajects/${id}/publish`, settings);
    }

    generateSectionContent(context: string, param: any): Observable<SectionContent> {
        if (context === 'media' || context === 'scorm' || context === 'scormcloud' || context === 'external_link') {
            const sectionContent = {
                context: context,
                context_id: param?.contextId,
                position: param?.position
            };
            return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content`, sectionContent)
                .pipe(map((sectionContent: any) => {
                    return new SectionContent(sectionContent, this.http, this.stuplayConfig)
                }));
        } else {
            return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/${context}`, param)
                .pipe(switchMap((data: any) => {
                    const sectionContent = {
                        context: context,
                        context_id: data?.id,
                        position: param?.position
                    };
                    return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content`, sectionContent);
                }), map((sectionContent: any) => {
                    return new SectionContent(sectionContent, this.http, this.stuplayConfig)
                }));
        }
    }

    getSectionContent(id: number): Observable<SectionContent> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content/${id}`)
            .pipe(map((data) => new SectionContent(data, this.http, this.stuplayConfig)));
    }

    addCondition(id: number, contentId: number, data: any): Observable<Condition> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/${contentId}/conditions`, data)
            .pipe(map((condition: any) => {
                return new Condition(condition);
            }));
    }

    updateCondition(id: number, contentId: number, condition: Condition): Observable<Condition> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/${contentId}/conditions/${condition?.id}`, condition)
            .pipe(map((condition) => {
                return new Condition(condition);
            }));
    }

    deleteCondition(id: number, contentId: number, conditionId: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/trajects/${id}/contents/${contentId}/conditions/${conditionId}`);
    }
}
