import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FillInQuestion, FillInQuestionSession, FillInAnswer, FillInAnswerChoice } from '@stuplay';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/utils/services/storage.service';
import { shuffleArray } from '../../../../../../utils';
import { TimerService } from '../../../../../utils/components/timer/timer.service';

@Component({
    selector: 'msc-fill-in',
    templateUrl: 'fill-in.component.html',
})
export class FillInComponent implements OnInit, OnDestroy {
    @Input() question: FillInQuestion;
    @Input() questionSession: FillInQuestionSession;
    @Output() questionSessionChange: EventEmitter<FillInQuestionSession> = new EventEmitter();
    subscription: Subscription;
    sentences: string[][] = [];
    choices: string[][] = [];
    allChoices: string[] = [];
    userChoices: string[][] = [];
    answer: FillInAnswer = { choices: [] };
    regex1 = /\[(.*?)\]/g;
    regex2 = /(?:(\[(?:[^\]])*?\])|(?:[^\[\]])*)/g;
    param: any;
    init = true;
    width: string[][] = [];

    constructor(
        public timerService: TimerService,
        public storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.initializeQuestion();
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession?.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    onChange(index?: number, wordIndex?: number): void {
        this.buildAnswer();
        this.questionSessionChange.emit(this.questionSession);

        if (index !== undefined && wordIndex !== undefined) {
            const size = this.userChoices[index][wordIndex].length;
            if (size > 16) {
                this.width[index][wordIndex] = 130 + (size * 5) + 'px';
            } else {
                this.width[index][wordIndex] = 'auto';
            }
        }
    }

    initializeQuestion(): void {
        this.param = JSON.parse(JSON.stringify(this.question.param));
        if (this.questionSession.updatedAt > this.questionSession.createdAt && this.questionSession.attempt > 0) {
            this.init = false;
            this.questionSessionChange.emit(this.questionSession);
            this.questionSession.answer.choices.forEach(this.parseChoice, this);
        } else {
            this.question.content.choices.forEach(this.parseChoice, this);
        }
        this.question.content.choices.forEach(({ choice }) => {
            const choiceWords = (choice.match(this.regex1) || []).map((match) => match.slice(1, match.length - 1));;
            this.allChoices = this.allChoices.concat(choiceWords);
        });
        this.allChoices = shuffleArray(this.allChoices).filter((choiceTofilter, filterIndex, array) => {
            return !array.some((choice, index) => choice === choiceTofilter && index > filterIndex);
        });
    }

    parseChoice(choice: any): void {
        const choices: string[] = [];
        let matchesArray: string[];
        if (!choice.choice) {
            matchesArray = [];
        } else {
            matchesArray = choice.choice.match(this.regex2);
            matchesArray.splice((choice.choice.match(this.regex2).length - 1), 1);
        }

        this.sentences.push(matchesArray.map((match: string) => {
            if (match !== '' || !match) {
                return match.startsWith('[') && match.endsWith(']') ? null : match.trim();
            }
        }));

        for (let i = 0; i < this.sentences.length; i++) {
            this.width[i] = [];
        }
        const answerChoices = matchesArray.map((match) => match.slice(1, match.length - 1));
        this.userChoices.push(this.init ? choices : answerChoices);
    }

    buildAnswer(): void {
        let index = 0;
        this.userChoices.map((sentence: string[]) => {
            sentence.map((word: string) => {
                if (word !== null) {
                    const choice: FillInAnswerChoice = { id: index, choice: word };
                    this.questionSession.answer.choices.push(choice);
                    index++;
                }
            });
        });

        this.questionSession.answer.choices = this.sentences.map((sentence, sentenceIndex) => {
            const choice = sentence.map((word, wordIndex) => {
                if (word !== '') {
                    return word || `[${this.userChoices[sentenceIndex][wordIndex]}]`;
                }
            }).join(' ');

            return {
                choice,
                id: sentenceIndex
            }
        });
    }

    showCorrectAnswer(choiceIndex: number): any {
        return this.question.content.choices[choiceIndex].choice?.replace(/(\[)/g, '<b><u>').replace(/(\])/g, '</u></b>');
    }

    isAnswerSuccess(sentenceIndex: number): boolean {
        return this.questionSession.doneAt && this.questionSession?.answer?.choices[sentenceIndex]?.isCorrect;
    }

    isAnswerFailed(sentenceIndex: number): boolean {
        return this.questionSession.doneAt && !this.questionSession?.answer?.choices[sentenceIndex]?.isCorrect;
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }
}
