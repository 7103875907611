import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadFile } from '../classes/loadfile.class';

@Injectable()
export class TranslationService {
    /**
     * Path
     */
    private path: string = '/assets/i18n/';
    /**
     * Current language
     */
    private currentLanguage: string = 'en';
    /**
     * Fallback language
     */
    private fallbackLanguage: string = 'en';
    /**
     * Available languages
     */
    private availableLanguages: string[] = ['fr', 'en', 'nl', 'de', 'it', 'es','pt_BR'];
    /**
     * Files Collection
     */
    private filesCollection: any[] = [];

    private loadFile = new LoadFile();

    /**
     * Import HttpClient
     * @param http
     */
    constructor(private http: HttpClient) {
        this.currentLanguage = this.getBrowserLang();
        this.load();
    }

    /**
     * Set a new lang in service
     * @param {string} lang
     */
    setLang(lang: string): void {
        this.currentLanguage = lang;
        this.load();
    }

    /**
     * Get the current lang
     * @return {string} Current lang
     */
    getCurrentLang(): string {
        return this.currentLanguage;
    }

    /**
     * Get the browser lang
     * @return {string} Browser lang
     */
    getBrowserLang(): string {
        const navigatorLanguage = navigator.language || this.fallbackLanguage;
        const language = navigatorLanguage.split('-')[0].toLowerCase();

        return this.availableLanguages.indexOf(language) > -1 ? language : this.fallbackLanguage;
    }

    /**
     * Load language file
     */
    private load(): void {
        if (!this.filesCollection[this.currentLanguage]) {
            this.filesCollection[this.currentLanguage] = [];

            this.filesCollection[this.currentLanguage] = this.loadFile.get(this.currentLanguage);

            if (this.currentLanguage !== this.fallbackLanguage) {
                this.loadFallback();
            }
        }
    }

    /**
     * Load fallback language file
     */
    private loadFallback(): void {
        if (!this.filesCollection[this.fallbackLanguage]) {
            this.filesCollection[this.fallbackLanguage] = [];
            
            this.filesCollection[this.fallbackLanguage] = this.loadFile.get(this.fallbackLanguage);
        }
    }

    /**
     * Get translation
     * @param {string} key
     * @param {any} params
     * @param {string} translating
     */
    getTranslate(key: string, params?: any, translating?: string): string {
        let translate = translating || (this.filesCollection[this.currentLanguage][key] || this.filesCollection[this.fallbackLanguage][key]);

        if (translate && params) {
            for (const k in params) {
                if (params.hasOwnProperty(k)) {
                    translate = translate.replace(`{{${k}}}`, params[k]);
                }
            }
        }

        return translate;
    }

    /**
     * Instant translating
     * @param {string} key
     * @param {any} params
     */
    instant(key: string, params?: any): any {
        if (this.filesCollection[this.currentLanguage][key]) {
            return this.getTranslate(key, params);
        }

        const translate = this.loadFile.get(this.currentLanguage)[key];
        return this.getTranslate(key, params, translate);
    }
}
