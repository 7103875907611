import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { StuplayConfig } from '@stuplay';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'single-upload',
    templateUrl: './single-upload.component.html'
})
export class SingleUploadComponent {
    @Input() uploadInput: EventEmitter<UploadInput>;
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() updateMedia = new EventEmitter();
    files: UploadFile[] = [];
    dragOver: boolean;
    extension: string;

    constructor(private stuplayConfig: StuplayConfig) {
        this.uploadInput = new EventEmitter<UploadInput>();
        this.extension = '.jpg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx, .txt, .xls, .xlsx, .mp3, .mp4';
    }

    close() {
        this.closeModal.emit();
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
            // when all files added in queue
            // uncomment this if you want to auto upload files when added
            const event: UploadInput | any = {
                type: 'uploadAll',
                url: `${this.stuplayConfig.envVar.API_URL}/media`,
                method: 'POST',
                data: {},
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem(environment?.tokenSessionName)
                },
                concurrency: 1 // set sequential uploading of files with concurrency 1
            };
            this.uploadInput.emit(event);
        } else if (output?.type === 'addedToQueue') {
            this.files.push(output.file); // add file to array when added
        } else if (output?.type === 'uploading') {
            // update current data in files array for uploading file
            const index = this.files.findIndex(file => file.id === output?.file?.id);
            this.files[index] = output?.file;
        } else if (output?.type === 'removed') {
            // remove file from array when removed
            this.files = this.files.filter((file: UploadFile) => file !== output?.file);
        } else if (output?.type === 'dragOver') { // drag over event
            this.dragOver = true;
        } else if (output?.type === 'dragOut') { // drag out event
            this.dragOver = false;
        } else if (output?.type === 'drop') { // on drop event
            this.dragOver = false;
        } else if (output?.type === 'done') {
        } else if (output?.type === 'start') {
            const interval = setInterval(() => {
                if (output?.file?.progress?.status === 2) {
                    output.file.progress.data.percentage = 125;
                    setTimeout(() => {
                        this.updateMedia.emit(output?.file?.response);
                    }, 1000);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }
}
