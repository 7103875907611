import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { StuplayConfig } from '../stuplay-config';
import { getParams } from "../helpers";

@Injectable()
export class EventsProvider {
    defaultLang: string;

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getEventsByDate(templateIdChosen?: number, langs?: string, q?: string) {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/instances/events`, {params: getParams({templateId: templateIdChosen, language: langs, q: q})});
    }
}
