<div class="procedure">
    <ng-container *ngIf="!questionSession.doneAt">
        <div class="m-flex">
            <div>
                <ng-container *ngFor="let choice of questionSession.answer.choices; let index=index">
                    <div class="m-number">
                        <span>
                            {{ index + 1 }}
                        </span>
                    </div>
                </ng-container>
            </div>

            <div class="procedure__choices" style="width: 92%;"
                cdkDropList
                [cdkDropListData]="questionSession.answer.choices"
                (cdkDropListDropped)="dropped($event)">
                <ng-container *ngFor="let choice of questionSession.answer.choices; let index=index">
                    <label class="choice"
                        cdkDrag
                        [cdkDragDisabled]="questionSession.doneAt"
                        [class.answer_success]="isAnswerSuccess(choice)"
                        [class.answer_failed]="isAnswerFailed(choice)"
                        [ngStyle]="{'cursor': cursorDisable()}">
                        <span class="choice__text">{{ choice.choice }}</span>
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="questionSession.doneAt">
        <div class="m-flex m-header">
            <div class="m-align-center" style="width: 50%">
                {{ 'words.my-answer' | translate }}
            </div>

            <div class="m-align-center" style="width: 50%">
                {{ 'words.solution' | translate }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="questionSession.doneAt">
        <div class="procedure__choices procedure__choices--correction m-flex">
            <div style="width: 50%;">
                <ng-container *ngFor="let choice of questionSession.answer.choices; let index=index">
                    <label class="choice"
                        [class.answer_success]="isAnswerSuccess(choice)"
                        [class.answer_failed]="isAnswerFailed(choice)">
                        <span class="choice__position">
                            {{ index + 1 }}
                        </span>

                        <span class="choice__text">
                            {{ choice.choice }}
                        </span>
                    </label>
                </ng-container>
            </div>

            <div style="width: 50%;">
                <ng-container *ngFor="let choice of question.content.choices; let index=index">
                    <label class="choice" >
                        <span class="choice__text">
                            {{ choice.choice }}
                        </span>
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<!-- old code -->
<!-- <div class="procedure__choices"
    dnd-sortable-container
    [sortableData]="questionSession.answer.choices"
    style="width: 92%;">
    <label class="choice"
        [class.answer_success]="isAnswerSuccess(choice)"
        [class.answer_failed]="isAnswerFailed(choice)"
        [ngStyle]="{'cursor': cursorDisable()}"
         *ngFor="let choice of questionSession.answer.choices; let index=index"
         dnd-sortable [sortableIndex]="index"
         [dragEnabled]="!this.questionSession.doneAt"
         [dropEnabled]="!this.questionSession.doneAt"
         [dragData]="choice"
         (onDragEnd)="onChange(choice, index)"> -->
<!--            <span class="choice__position">-->
<!--                {{ isAnswerFailed(choice) ? getCorrectAnswer(choice.choice) : index + 1 }}-->
<!--            </span>-->

<!-- <span class="choice__text">{{ choice.choice }}</span>
    </label>
</div> -->
