import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { Folder } from './folder';
import { getParams } from "../helpers";

@Injectable()
export class FolderProvider {

	constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

	getFolders(): Observable<Folder> {
		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folder/0`)
		.pipe(map((res) => new Folder(res, this.http, this.stuplayConfig)));
	}

	getHomeFolders(slug?: string, params?: any): Observable<any> {
		let data = {
			pagination: {},
			folders: []
		};

		if (slug) {
			return this.http.get(`${this.stuplayConfig.envVar.API_URL}/me/folders/${slug}`, {params: getParams(params)})
			.pipe(map((res: any) => {
				res.folders.map((folder) => data.folders.push(new Folder(folder, this.http, this.stuplayConfig)));
				data.pagination = res.pagination;
				return data;
			}));
		} else {
			return this.http.get(`${this.stuplayConfig.envVar.API_URL}/me/folders/0`, {params: getParams(params)})
			.pipe(map((res: any) => {
				res.folders.map((folder) => data.folders.push(new Folder(folder, this.http, this.stuplayConfig)));
				data.pagination = res.pagination;
				return data;
			}));
		}
	}

	loadMoreFolders(pageNumber:number): Observable<Folder> {
		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folder/0?page=${pageNumber}`)
		.pipe(map((res) => new Folder(res, this.http, this.stuplayConfig)));
	}

	getFolder(slug:string): Observable<Folder> {
		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folder/${slug}`)
		.pipe(map((res) => new Folder(res, this.http, this.stuplayConfig)));
	}

	getFoldersCollaboration(): Observable<Folder> {
		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folder/collaboration`)
		.pipe(map((res) => new Folder(res, this.http, this.stuplayConfig)));
	}

	loadMoreFoldersCollaboration(pageNumber:number) {
		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folder/collaboration?page=${pageNumber}`)
		.pipe(map((res) => new Folder(res, this.http, this.stuplayConfig)));
	}

	createFolder(folder:any): Observable<Folder> {
		return this.http.post(`${this.stuplayConfig.envVar.API_URL}/courses/folder`, folder)
		.pipe(map((res: any) => new Folder(res.folder,  this.http, this.stuplayConfig)));
	}

    moveTo(id: number, folderId: number): Observable<any> {
        const params = {
            folder_id: folderId
        };

        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/courses/folder/${id}/move`, params);
    }

    getFoldersTree(params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folders/tree`, {params: getParams(params)});
	}

	getSharedFolders(slug: string, params?: any): Observable<any> {
		let response = {
			shared: []
		};

		return this.http.get(`${this.stuplayConfig.envVar.API_URL}/company/${slug}/shared-folders`, {params: getParams(params)})
			.pipe(map((data: any) => {
				response.shared = data.map((folder) => {
					return new Folder(folder, this.http, this.stuplayConfig);
				});

				return response;
			}));
	}
}
