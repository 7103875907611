<a (click)="onUpdatedMedia(item)" [style.background-image]="backgroundImage">
	<div class="info info__module">
		<span class="info__name">{{ item.title }}</span>
		<span class="info__type font-text">{{ item.type }}</span>
	</div>
	<div class="actions">
		<button class="button button-grid btn-action-mini btn-action_edit btn-color_blue"></button>
		<button class="button button-grid btn-action-mini btn-action_delete btn-color_red"></button>
	</div>
</a>
