<div class="question question_category question_linker question_multiple slide slide_question">
    <div *ngIf="!startFlashCards && status !== 'validate'">
        <div class="question__content">
            <div class="result">
                <div class="result__score">
                    <img src="https://static.myskillcamp.com/images/illustration/illu-recap/illu_recap_flashcards.svg">
                </div>

                <h3 *ngIf="question?.title">
                    <span [innerHTML]="question?.title | safe:'html'"></span>
                </h3>

                <div class="question__validation text-center">
                    <button class="btn btn_width_1" (click)="changeStartFlashCards()">
                        {{ 'player.question.button.start-flash-card' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="startFlashCards">
        <div class="question__content" *ngFor="let choice of questionSession.answer.choices; let indexChoice = index;">
            <div class="question__explanation" *ngIf="indexChoice === indexSelected">
                {{ 'player.question.instruction.flash-cards' | translate }}
            </div>

            <div *ngIf="indexChoice === indexSelected && status !== 'validate'">
                <div class="cards">
                    <div class="m-category-description" *ngIf="question?.description">
                        <span [innerHTML]="question.description | safe: 'html'"></span>
                    </div>

                    <div class="card">
                        <div class="card__media" *ngIf="question?.content?.choices[indexChoice]?.type === 'media'">
                            <media-holder [media]="question?.content?.choices[indexChoice]?.media" [cover]="'http://fakeimg.pl/540x400/'"></media-holder>
                        </div>

                        <div class="card__text" *ngIf="question?.content?.choices[indexChoice]?.type === 'text'">
                            {{ question.content.choices[indexChoice].value }}
                        </div>
                    </div>

                    <div class="cards__navigation" *ngIf="checked === true">
                        <button class="previous" (click)="indexSelected = indexSelected - 1"
                                *ngIf="indexSelected !== 0"><
                        </button>

                        <button class="next" (click)="indexSelected = indexSelected + 1"
                                *ngIf="questionSession?.answer?.choices?.length !== indexSelected">>
                        </button>
                    </div>
                </div>

                <div>
                    <label class="choice" *ngFor="let category of question.content.categories"
                           [class.answer_success]="isAnswerSuccess(indexChoice, category)"
                           [class.answer_failed]="isAnswerFailed(indexChoice, category)">
                        <input type="checkbox" name="button" (click)="nextCard(choice, category)">

                        <span class="choice__text">{{ category }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="questionSession.answer.choices.length === indexSelected || this.status === 'validate'">
        <div class="question__content">
            <div class="result">
                <div class="result__score">
                    <img src="https://static.myskillcamp.com/images/illustration/illu-recap/illu_recap_flashcards.svg">
                    <span class="score">{{ getRatio | number:'1.0-0' }}</span>
                </div>

                <h3>{{ 'player.question.flash-cards.end-label' | translate }}</h3>

                <span class="ratio">{{ 'player.question.flash-cards.end' | translate:{
                    goodAnswerCount: goodAnswerCount,
                    answerCount: answerCount
                } }}</span>

                <div class="question__validation">
                    <button class="restart" *ngIf="questionSession.attempt < question.tryMax" (click)="restart()">
                        {{ 'player.question.restart'  | translate }}
                    </button>

                    <button class="btn btn_width_1 btn_color_grey" *ngIf="questionSession.doneAt" (click)="checkAnswer()">
                        {{ 'player.question.button.check-answer' | translate }}
                    </button>

                    <msc-next-prev-btn></msc-next-prev-btn>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer [timeline]="timeline" [context]="'question'" [contextId]="question?.id"></app-footer>

<style>
    .cards .m-category-description {
        margin: 0;
        padding: 2rem 0 0;
        text-align: center;
        color: #424667;
        font-size: 24px;
    }
</style>
