import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { StuplayConfig } from '@stuplay';
import { StorageService } from './storage.service';

@Injectable()
export class UserProvider {
    public currentUser: any;

    constructor(
        private http: HttpClient,
        private stuplayConfig: StuplayConfig,
        private storageService: StorageService
    ) { }

    getCurrentUser(): Observable<any> {
        if (this.currentUser) { return of(this.currentUser); }
        return this.http
            .get(`${this.stuplayConfig.envVar.API_URL}/me`)
            .pipe(
                tap((response) => {
                    this.storageService.getAndSet('me', response.account);
                    return this.currentUser = response.account
                })
            );
    }

    getRoles(slug: string | number): Observable<any> {
        return this.http
            .get(`${this.stuplayConfig.envVar.API_URL}/company/${slug}/roles`)
            .pipe(
                map((response) => response),
            );
    }
}
