import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StuplayConfig } from '../../../stuplay-config';
import { Account } from '../../../account/account';
import { cleanObject } from '../../../helpers';

export class Comment {
    id: number;
    account: Account;
    content: any;
    created_at: any;

    constructor(comment: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(comment);
    }

    extends(comment: any) {
        Object.assign(this, comment);
        return this;
    }

    update(): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/timeline/comment/${this.id}`, cleanObject(this));
    }
}
