import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post } from './post/post';
import { StuplayConfig } from '../stuplay-config';
import { Media } from '../media';
import { cleanObject } from '../helpers';

export class Timeline {
    id: number;
    comment_validation: boolean;
    posts: Post[] = [];
    currentPage: number = 1;
    lastPage: number = 1;

    constructor(timeline: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(timeline);
    }

    extends(timeline: any) {
        Object.assign(this, timeline);
        this.getNextPosts(1).subscribe();
        return this;
    }

    getNextPosts(id?): Observable<Post[]> {
        if (this.lastPage >= this.currentPage) {
            id ? this.currentPage = id : this.currentPage += 1;
            return this.http.get(`${this.stuplayConfig.envVar.API_URL}/timeline/${this.id}?page=${this.currentPage}`)
                .pipe(map((timeline: any) => {
                    this.lastPage = timeline.lastPage;
                    timeline.items.forEach((post) => {
                        this.posts.push(new Post(post, this.http, this.stuplayConfig))
                    });
                    return this.posts;
                }))
        } else {
            return of(null);
        }
    }

    update(): Observable<Timeline> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/timeline/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }

    addPost(postMessage: string, timelineId: number, media?: Media, crawl?: any, companyId?: number): Observable<Post> {
        const post = {
            content: {
                link: crawl,
                media_id: media ? media.id : null,
                message: postMessage,
            },
            timeline_id: timelineId,
            type: 'mixed',
            company_id: companyId
        };

        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/timeline`, post)
            .pipe(map(res => {
                const post = new Post(res, this.http, this.stuplayConfig);
                this.posts = [post].concat(this.posts);
                return post;
            }));
    }

    deletePost(timelineId: number, postId: number): Observable<void> {
        const selectedPostIndex = this.posts.findIndex(post => post?.id === postId);
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/timelines/${timelineId}/items/${postId}`)
            .pipe(map(() => {
                this.posts.splice(selectedPostIndex, 1);
            }));
    }
}
