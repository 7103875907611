import { Component, Input, OnInit } from '@angular/core';
import { Section } from '@stuplay'
import { ActivatedRoute } from '@angular/router';
import { DataHelper } from '../../../utils/helpers';

@Component({
    selector: 'msc-section-presentation',
    templateUrl: './section-presentation.component.html',
    styleUrls: ['./section-presentation.component.less']
})
export class SectionPresentationComponent implements  OnInit {
    @Input() section: Section;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.paramMap.subscribe(() => {
            this.section = this.route.snapshot.data['section'];
        });
    }

    navigate(section: any): any {
        if (section?.sectionContents[0]?.context === 'external_link') {
            return ['external-link', section?.sectionContents[0][DataHelper.changeCase(section?.sectionContents[0]?.context, 'camel')]?.id];
        } else {
            return [section?.sectionContents[0]?.context, section?.sectionContents[0][DataHelper.changeCase(section?.sectionContents[0]?.context, 'camel')]?.id];
        }
    }
}
