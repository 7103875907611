import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimelineProvider } from '@stuplay';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
})

export class TimelineComponent {
    @Input() context: string;
    @Input() contextId: number;
    @Output() updateMedia = new EventEmitter();
    @Output() interactionEmitter = new EventEmitter();
    course: any;
    company: any;
    timelineId: number;
    items: any[] = [];
    pagination: any;
    ready: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private timelineProvider: TimelineProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.course = this.storageService.get('course');
        this.company = this.storageService.get('company');
    }

    ngOnChanges() {
        this.ready = false;
        this.getTimeline();
    }

    getTimeline(): void {
        const params = {
            context: this.context,
            context_id: this.contextId
        };

        this.timelineProvider.getTimelineId(params).subscribe((data: any) => {
            this.timelineId = data?.id;

            this.timelineProvider.getTimeline(this.timelineId).subscribe((timeline: any) => {
                this.items = timeline?.items;
                this.pagination = timeline?.pagination;
                this.ready = true;
            });
        });
    }

    addPost(post: any): void {
        const params = {
            timeline_id: this.timelineId,
            content: {
                message: post?.message,
                media_id: post?.media ? post?.media?.id : null
            },
            type: 'mixed',
            company_id: this.company && this.company?.id,
            context: this.context,
            context_id: this.contextId,
            instance_id: this.course?.id
        };

        if (this.course.isTrajectContent) {
            params['traject_id'] = this.course?.trajectId;
        }

        this.timelineProvider.send(params).subscribe((data: any) => {
            this.items.splice(0, 0, data);
            this.sendEmitterToExchange();
        });
    }

    sendEmitterToExchange(): void {
        this.interactionEmitter.emit(true);
    }

    nextPosts(): void {
        const params = {
            page: this.pagination.currentPage + 1
        };

        this.timelineProvider.getTimeline(this.timelineId, params).subscribe((timeline: any) => {
            this.items = this.items.concat(timeline?.items);
            this.pagination = timeline?.pagination;
        });
    }

    deletePost(post: any): void {
        const index = this.items.findIndex((item) => {
            return item.id === post;
        });

        if (index > -1) {
            this.items.splice(index, 1);
        }
    }
}
