<div class="slide slide_exchange exchange"  >
    <div class="column-left" style="overflow:hidden;">
        <h2 class="heading heading_border_none">
            <span>{{ 'player.exchange.name'| translate }}</span> {{ sectionContent.title || ('player.common.untitled' | translate) }}
        </h2>

        <p *ngIf="sectionContent.description" class="multiline" [innerHTML]="sectionContent?.description | safe:'html'"></p>
        <media-holder *ngIf="exchange.media" [media]="exchange?.media"></media-holder>
       <!-- <app-timeline *ngIf="exchange" (interactionEmitter)="updateExchange()" [context]="'exchange'" [contextId]="exchange?.id"></app-timeline>-->
        <msc-next-prev-btn></msc-next-prev-btn>
    </div>
</div>

<div class="exchange-timeline" style="margin:3.5rem;">
    <app-timeline *ngIf="exchange" (interactionEmitter)="updateExchange()" [context]="'exchange'" [contextId]="exchange?.id"></app-timeline>
</div> 
<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
