<div class="question question_{{ question.type }} slide slide_question" [class.open-hint]="hintShowed === true">
    <div class="question__content">
        <div class="question-evaluation" *ngIf="sectionContent?.isScorable">
            <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_evaluation.svg">

            <div class="">
                {{ 'words.evaluation' | translate }}
            </div>
        </div>

        <div *ngIf="question.hint || question.hintMedia" class="hint">
            <button class="btn btn_color_grey hint__trigger" (click)="toggleHint()">
                {{ 'player.question.hint.button' | translate }}
            </button>

            <div class="hint__content" style="padding: 2.5rem; overflow: auto;">
                <button class="close" (click)="toggleHint()">+</button>
                <h4>{{ 'player.question.hint.title' | translate }}</h4>

                <div class="">
                    <p [innerHTML]="question.hint | safe:'html'"></p>
                </div>
            </div>
        </div>

        <h3 class="heading">
            <span>{{ 'player.question.instruction.' + question.type | translate}}</span>
            <span class="m-question-title" [innerHTML]="question?.title | safe:'html'" *ngIf="question?.title"></span>
        </h3>

        <div class="">
            <span class="" [innerHTML]="question?.description | safe: 'html'"></span>
        </div>

        <!-- <div #questionDetail></div> -->
        <ng-template questionHost></ng-template>


        <div class="feedback" *ngIf="show()">
            <h4 class="feedback__title">{{ 'question.correction.title' |translate }}</h4>

            <div class="feedback__content" *ngIf="(!questionSession.isCorrect && !question.feedbackWrongAnswer) || questionSession.isCorrect">
                <div [innerHTML]="question.feedbackDefault | safe:'html'"></div>
                <div *ngIf="questionSession.comment" [innerHTML]="questionSession.comment | safe:'html'"></div>
            </div>

            <div class="feedback__content" *ngIf="!questionSession.isCorrect && question.feedbackWrongAnswer">
                <div [innerHTML]="question.feedbackWrongAnswer | safe:'html'"></div>
            </div>
        </div>

        <div class="question__validation" style="margin: 1rem 0 0 0;">
            <span class="remaining-tries" *ngIf="!questionSession.doneAt && questionSession.attempt < question.tryMax && question.tryMax > 1" [innerHTML]="(question.tryMax - questionSession.attempt > 1) ?  ('player.question.attempts' | translate:{value: question.tryMax - questionSession.attempt}) : ('player.question.attempt' | translate)"></span>

            <span class="remaining-tries" *ngIf="questionSession.type === 'open-write' && questionSession.doneAt && !questionSession.correctedAt">
                {{ 'player.question.waiting-teacher-correction' | translate }}
            </span>

            <button class="remaining-tries" *ngIf="showBtnCorrectAnswer" (click)="toggleCorrectAnswer()">
                {{ questionDetailRef.instance.showCorrectAnswer ? ('player.question.button.hide-correct-answer' | translate) : ('player.question.button.show-correct-answer' | translate) }}
            </button>

            <button class="btn" *ngIf="status !== 'validate' || questionSession.doneAt === null" (click)="validate()">
                <div class="lds-ring" *ngIf="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <span>{{ 'player.question.button.validate' | translate }}</span>
            </button>

            <msc-next-prev-btn *ngIf="status === 'validate' && questionSession.doneAt" [doneAt]="questionSession.doneAt" [lastQuestion]="lastQuestion"></msc-next-prev-btn>
        </div>
    </div>
</div>

<app-footer [context]="'question'" [contextId]="question?.id"></app-footer>

<style>
    .feedback__media {
        float: none !important;
    }

    .m-question-title {
        color: #424667 !important;
        font-size: 29px !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }
</style>
