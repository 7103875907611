import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserProvider } from '../../../utils/services/user.provider';
import { ScormModule, SectionContent, SectionContentSession, ScormProvider } from '@stuplay';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../utils/components/toast/toast.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'app-scorm-cloud',
    templateUrl: './scorm-cloud.component.html',
})
export class ScormCloudComponent {
    @Input() scorm: ScormModule;

    public sectionContent: SectionContent;
    public sectionContentSession: SectionContentSession;
    public preview: boolean;
    public processing: boolean = false;
    public scormCloudSubscription: Subscription;
    public url: any;
    public hideIframe: string = 'none';t

    constructor(
        private route: ActivatedRoute,
        private userProvider: UserProvider,
        private scormProvider: ScormProvider,
        private sanitizer: DomSanitizer,
        private toastService: ToastService,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) {
        this.route.queryParams.subscribe((params: Params) => {
            this.preview = params['preview'] ? true : false;
        });
    }

    ngOnInit() {
        this.scormCloudSubscription = this.route.paramMap.subscribe(() => {
            this.sectionContent = this.route.snapshot.data?.scormcloud;
            this.scorm = this.sectionContent?.scormcloud;
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
        });
    }

    launchScormCloud(content: any): void {
        this.processing = true;
        let new_tab = null;
        if (this.scorm.displayType === 'new_tab') {
            new_tab = window.open('', '_blank');
        }
        this.scormProvider.launchScorm(this.scorm?.id, this.sectionContent?.id, this.preview).subscribe((data) => {
            this.processing = false;
            if (new_tab) {
                new_tab.location.href = data.url;
            } else {
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
                const options: NgbModalOptions = { windowClass: 'force-fullscreen-lesson-media', backdropClass: 'backdrop-custom' };
                this.modalService.open(content, options);
                this.handleDisplay();
            }
        }, () => {
            this.processing = false;
            this.toastService.push('error-occurred', 'error');
        });
    }

    closeModal(): void {
        this.modalService.dismissAll();
        this.processing = true;

        const timeOut = setTimeout(() => {
            this.sectionContentSession.get().subscribe((data) => {
                if (data.progress !== null && data.progress === 100 && !data.doneAt) {
                    this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe();
                }

                this.url = null;
                this.processing = false;
                clearTimeout(timeOut);
            });
        }, 5000);
    }

    handleDisplay(): void {
        this.hideIframe = 'none';
        const timeout = setTimeout(() => {
            this.hideIframe = 'block';
            clearTimeout(timeout);
        }, 6500);
    }
}
