import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StuplayConfig } from '../stuplay-config';
import { getParams } from '../helpers';

@Injectable()
export class SkillProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) { }

    getSkills(params: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/skills`, { params: getParams(params) });
    }

    getCompanySkills(params: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/skillables/skills`, { params: getParams(params) });
    }

    create(params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/skills`, params);
    }

    attach(id: number, params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/skills/${id}/attach`, params);
    }

    detach(id: number, params: any): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/skills/${id}/detach`, { params: getParams(params) });
    }

    updateLevel(id: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/skills/${id}/level`, params);
    }
}
