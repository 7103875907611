import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslationService } from 'src/app/utils/services/translation.service';
import { TimerService } from '../../../../../utils/components/timer/timer.service';
import { TrueFalseQuestion, TrueFalseQuestionSession } from '@stuplay';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-true-false',
    templateUrl: 'true-false.component.html',
})
export class TrueFalseComponent implements OnInit, OnDestroy {
    @Input() question: TrueFalseQuestion;
    @Input() questionSession: TrueFalseQuestionSession;
    @Output() questionSessionChange: EventEmitter<TrueFalseQuestionSession> = new EventEmitter();
    subscription: Subscription;

    constructor(
        public translate: TranslationService,
        public timerService: TimerService,
        public storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onChange() {
        this.questionSessionChange.emit(this.questionSession);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    isAnswerSuccess(statement: boolean): boolean {
        if (this.questionSession.doneAt) {
            if (statement === this.questionSession.answer.choice && this.questionSession.isCorrect) {
                return true;
            }
        }
        return false;
    }

    isAnswerFailed(statement: boolean) {
        if (this.questionSession.doneAt) {
            if (statement === this.questionSession.answer.choice && !this.questionSession.isCorrect) {
                return true;
            }
        }
        return false;
    }

    getStatement(statement: string): Observable<string> {
        const yesOrNo = {
            true: 'yes',
            false: 'no'
        };

        return this.translate.instant(`question.true-false.${this.question.param.type === 'true' ? statement : yesOrNo[statement]}`);
    }

    cursorDisable() {
        if (this.questionSession.isDisable(this.question.tryMax)) {
            return 'not-allowed';
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }
}
