import { SectionContentSession } from './section-content-session';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, interval, of } from 'rxjs';
import { map, timeInterval } from 'rxjs/operators';
import { StuplayConfig } from "../stuplay-config";
import { Section } from '../course/section/section';
import { cleanObject } from "../helpers";
import { Account } from '../account/account';

export class CourseCampSession {
    id: number;
    courseCampId: number;
    account: Account;
    score: number;
    duration = 0;
    createdAt: number;
    updatedAt: number;
    doneAt: number;
    progress: number;
    sectionContentSessions: SectionContentSession[]  = [];
    sectionsLength: number // TODO
    timerSubscription: Subscription;
    doneSurvey: boolean;

    constructor(courseCampSession: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(courseCampSession);
    }

    extends(courseCampSession: any) {
        Object.assign(this, courseCampSession);
        if (this.sectionContentSessions.length > 0) {
            this.sectionContentSessions = this.sectionContentSessions.map((sectionContentSession) => new SectionContentSession(sectionContentSession, this.http, this.stuplayConfig));
        }
        return this;
    }

    getCampProgress(): number {
        return this.progress;
    }

    getCampScore(): number {
        return this.score;
    }

    getSectionProgress(section: Section): number {
        let total = 0;
        let count = 0;

        if (section.sectionContents.length > 0) {
            section.sectionContents.forEach((sectionContent) => {
                count++;
                let index = this.sectionContentSessions.findIndex((session) => {
                    return session?.sectionContentId === sectionContent?.id;
                });

                if (index > -1) {
                    if (this.sectionContentSessions[index].doneAt) {
                        total += 1;
                    }
                }
            });
        }

        return count > 0 ? (total / count) : 0;
    }

    getSectionScore(section: Section): number {
        let total = 0;
        let count = 0;

        section.sectionContents.forEach((sectionContent) => {
            if (sectionContent?.isScorable) {
                let index = this.sectionContentSessions.findIndex((session) => {
                    return session?.sectionContentId === sectionContent?.id;
                });

                if (index !== -1 && this.sectionContentSessions[index] && this.sectionContentSessions[index]?.score !== null) {
                    count ++;
                    total += (this.sectionContentSessions[index]?.score || 0);
                }
            }
        });

        return count > 0 ? Math.round(total / count) : null;
    }

    startTimer() {
        this.timerSubscription = interval(1000)
            .pipe(timeInterval())
            .subscribe(() => {
                this.duration += 1;
            });
    }

    stopTimer() {
        this.update().subscribe(() => {
            this.timerSubscription.unsubscribe();
        });
    }

    getDuration(): number {
        return this.duration;
    }

    getOrCreateSectionContentSession(sectionContentId: number): Observable<SectionContentSession> {
        const sectionContentSession = this.sectionContentSessions.find((sectionContentSession) => sectionContentSession?.sectionContentId === sectionContentId);
        return sectionContentSession ? of(sectionContentSession) : this.addSectionContentSession(sectionContentId);
    }

    addSectionContentSession(sectionContentId: number): Observable<SectionContentSession> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/session/section-content-sessions`, { sectionContentId, courseCampSessionId: this.id })
            .pipe(map(res => {
                const sectionContentSession = new SectionContentSession(res, this.http, this.stuplayConfig);
                this.sectionContentSessions.push(sectionContentSession);
                return sectionContentSession;
            }));
    }

    update(): Observable<CourseCampSession> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/session/course-camp-sessions/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
