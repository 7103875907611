import { Account } from '../../../../account/account';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventSession } from './event-session';
import { EventTrainer } from './event-trainer';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { Media } from '../../../../media';
import { cleanObject } from "../../../../helpers";

export class Event {
    id: number;
    account: Account;
    type: string;
    title: string;
    info: string;
    content: any;
    duration: number;
    link: string;
    createdAt: number | string;
    updatedAt: number | string;
    folderId: number;
    autoValidation: boolean;
    autoSubscription: boolean;
    skill?: string;
    tags: string[];
    url?: string;
    eventSessions: EventSession[];
    media?: Media;
    companyId?: number;
    eventTrainers?: EventTrainer[];
    integration?: string;

    constructor(event: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(event);
    }

    extends(event: any): Event {
        Object.assign(this, event);
        if (this.eventSessions && this.eventSessions.length > 0) {
            this.eventSessions = this.eventSessions.map((eventSession) => new EventSession(eventSession, this.http, this.stuplayConfig));
        }
        if (this.eventTrainers && this.eventTrainers.length > 0) {
            this.eventTrainers = this.eventTrainers.map((eventTrainer) => new EventTrainer(eventTrainer, this.http, this.stuplayConfig));
        }
        return this;
    }

    get(): Observable<Event> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/events/${this.id}`).pipe(map((data: any) => this.extends(data.event)));
    }

    update(): Observable<Event> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/events/${this.id}`, cleanObject(this)).pipe(map((data: any) => this.extends(data.event)));
    }

    addEventSession(): Observable<Event> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/events/${this.id}/session`, cleanObject(this)).pipe(map((eventSessions: any) => {
            this.eventSessions.push(new EventSession(eventSessions, this.http, this.stuplayConfig));
            return this;
        }));
    }

    addEventTrainer(id: number, name: string): Observable<Event> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/events/${this.id}/trainers`, {'account_id': id, 'name': name}).pipe(map((eventTrainer: any) => {
            if(!eventTrainer.message) {
                this.eventTrainers.push(new EventTrainer(eventTrainer, this.http, this.stuplayConfig));
            }
            return this;
        }));
    }

    deleteEventTrainer(trainer: EventTrainer): Observable<Event> {
        const index = this.eventTrainers.indexOf(trainer);

        return trainer.delete().pipe(map(() => {
            this.eventTrainers.splice(index, 1);
            return this;
        }));
    }

    createOrUpdateWebinar(description: string, timezone: any, update?: boolean): Observable<Event> {
        const object = {
            'subject': this.title,
            'description': description,
            'times': [{
                'startTime': this.eventSessions[0].startAt,
                'endTime': this.eventSessions[0].endAt
            }],
            'timeZone': timezone,
            'type': 'single_session',
            'isPasswordProtected': false
        };

        if(!update) {
            return this.http.post(`${this.stuplayConfig.envVar.API_URL}/oauth/gotowebinar/create_webinar`, object)
                .pipe(map((data: any) => {
                    this.link = data.registration_url;
                    this.eventSessions[0].createIntegration(data.webinarKey, 'gotowebinar');
                    this.update().subscribe();
                    return this
                }));
        } else {
            return this.http.put(`${this.stuplayConfig.envVar.API_URL}/oauth/gotowebinar/webinars`, object)
                .pipe(map((data: any) => {
                    this.link = data.registration_url;
                    this.eventSessions[0].createIntegration(data.webinarKey, 'gotowebinar');
                    this.update().subscribe();
                    return this
                }));
        }
    }

    deleteWebinar(id: number): Observable<Event> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/oauth/gotowebinar/webinars/${id}`)
            .pipe(map((data: any) => {
                this.link = '';
                this.update().subscribe();
                return this
            }));
    }

    createGotowebinar(id: number, params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/events/${id}/integration`, params)
            .pipe(map((data: any) => {
                return this
            }));
    }

    deleteGotowebinar(id: number, params?: any): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/create/events/${id}/integration`, {params})
            .pipe(map((data: any) => {
                return this
            }));
    }

    createOrUpdateVirtualClass(params?: any): Observable<Event> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/learncube/rooms`, params)
            .pipe(map((data: any) => {
                this.url = data.url;
                this.update().subscribe();
                return this
            }));
    }
}
