<div class="answer">
    <label class="choice" [ngStyle]="{'cursor': cursorDisable()}" [class.answer_success]="isAnswerSuccess(true)" [class.answer_failed]="isAnswerFailed(true)" [ngClass]="{'choice_selected': questionSession?.answer.choice === true}">
        <input
                type="radio"
                [disabled]="questionSession.doneAt"
                [value]="true"
                [(ngModel)]="questionSession?.answer.choice"
                (change)="onChange()">
        <span class="choice__text">{{ getStatement('true') }}</span>
    </label>

    <label class="choice" [ngStyle]="{'cursor': cursorDisable()}" [class.answer_success]="isAnswerSuccess(false)" [class.answer_failed]="isAnswerFailed(false)" [ngClass]="{'choice_selected': questionSession?.answer.choice === false}">
        <input
               type="radio"
               [disabled]="questionSession.doneAt"
               [value]="false"
               [(ngModel)]="questionSession?.answer.choice"
               (change)="onChange()">
        <span class="choice__text">{{ getStatement('false') }}</span>
    </label>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>