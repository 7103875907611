<!-- <div>
    <div class="studio-content">
        <button class="button btn-action_close" id="test-section-button-modal" (click)="close()">+</button>

        <div class="upload" *ngIf="!files[0]">
            <label class="upload__placeholder" style="cursor: pointer;">
                <div class="title">
                    <span>{{ 'studio.media.upload.title' | translate }}</span>
                </div>
                <div class="content">
                    {{ 'player.media.single-upload.file-accepted' | translate }} {{ extension }}
                    <span>{{ 'player.media.single-upload.max-weight' | translate }}</span>
                    <input style="text-align: center; width: auto; margin-top: 1rem; " type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [accept]="extension">
                </div>
            </label>
        </div>

        <div class="upload upload_processing" *ngIf="files[0]">
            <h2 class="heading">
                {{ files[0].name }}
            </h2>
            <div class="progress">
                <div class="progress__text">{{ ((files[0]?.progress.data.percentage * 0.8 | number:'1.1-1' )|| 0)+'%' }}</div>
                <div class="progress-bar">
                    <div class="progress-bar__progress" [ngStyle]="{'width': (files[0]?.progress.data.percentage * 0.8 || 0)+'%'}"></div>
                </div>
            </div>
            <p *ngIf="files[0].progress.status !== 2">{{ 'player.media.upload.waiting' | translate}}</p>
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="upload" *ngIf="!files[0]">
        <label class="upload__placeholder" style="cursor: pointer;">
            <div class="title">
                <span>{{ 'studio.media.upload.title' | translate }}</span>
            </div>
            <div class="content">
                {{ 'player.media.single-upload.file-accepted' | translate }} {{ extension }}
                <span>{{ 'player.media.single-upload.max-weight' | translate }}</span>
                <input style="text-align: center; width: auto; margin-top: 1rem; " type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [accept]="extension">
            </div>
        </label>
    </div>
    <div class="upload upload_processing" *ngIf="files[0]">
        <h2 class="heading">
            {{ files[0].name }}
        </h2>
        <div class="progress">
            <div class="progress__text">{{ ((files[0]?.progress.data.percentage * 0.8 | number:'1.1-1' )|| 0)+'%' }}</div>
            <div class="progress-bar">
                <div class="progress-bar__progress" [ngStyle]="{'width': (files[0]?.progress.data.percentage * 0.8 || 0)+'%'}"></div>
            </div>
        </div>
        <p *ngIf="files[0].progress.status !== 2">{{ 'player.media.upload.waiting' | translate}}</p>
    </div>
</div>
