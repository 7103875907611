import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'library-item',
    templateUrl: './library-item.component.html'
})

export class LibraryItemComponent {
    @Output() updateMedia = new EventEmitter();
    @Input() item: any;

    constructor() {
        //
    }

    get backgroundImage() {
        return `url('${this.item.type == 'IMG' ? 'http://studio-api.myskillcamp.biz/api/stockage/' + this.item.url + '?size=512' : (this.item.video?.thumbnailUrl ? this.item.video.thumbnailUrl + '?mh=48' : '')}')`
    }

    ngOnInit() {
    }

    onUpdatedMedia(media: any) {
        this.updateMedia.emit(media);
    }
}
