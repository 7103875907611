<div class='timeline'>
	<div class="timeline__new-post">
		<form #commentForm class="new-post">
			<span>{{'player.timeline.discussion.subtitle' | translate}}</span>

			<timeline-add-content (addContent)="addPost($event)" [placeholder]="'player.timeline.placeholder.ask' | translate"></timeline-add-content>
		</form>
	</div>

	<div *ngIf="timelineId && ready">
		<div class="post" *ngFor="let item of items">
			<msc-post (updateExchange)="sendEmitterToExchange()" [context]="context" [contextId]="contextId" [isTrajectContent]="course.isTrajectContent" [post]='item' [timelineId]='timelineId' (onDelete)="deletePost($event)"></msc-post>
		</div>

		<div class="load-more grey" *ngIf="pagination?.lastPage > pagination?.currentPage">
			<button class="btn light-grey load-more__button btn_round btn_height_small" (click)="nextPosts()">
				<span>{{ 'player.load-more' | translate }}</span>
			</button>

			<div class="dot"></div>
		</div>
	</div>
</div>
