import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Course, CourseSubscriptionSession, CourseSubscriptionSessionProvider } from '@stuplay';
import { BackUrlService } from '../../services/back-url.service';
import { ExternalAppsService } from '../../services/external-apps.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'msc-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less']
})
export class TopBarComponent {
    @Input() course: Course | any;
    @Input() courseSubscriptionSession: CourseSubscriptionSession;
    @Input() showSidebar: boolean;
    @Input() displaySidebar: boolean;
    @Output() toggle: EventEmitter<any> = new EventEmitter();
    preview: boolean = false;
    isFromLearningPath: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fromService: BackUrlService,
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private externalAppsService: ExternalAppsService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            this.preview = !!params['preview'];
            this.isFromLearningPath = !!params['isFromLearningPath'];
        });
    }

    back(): void {
        const me = this.storageService.get('me');
        const company = this.storageService.get('company');

        document.location.replace(this.fromService.getBackUrl());
    }

    toggleSidebar(): void {
        this.showSidebar = !this.showSidebar;
        this.toggle.emit();
    }

    resetCurrentSubscriptionSession(): void {
        this.courseSubscriptionSessionProvider.resetCourseSubscriptionSession(this.courseSubscriptionSession?.id).subscribe(() => {
            this.router.navigate(['course', this.course?.id], { queryParams: { preview: this.preview } });
            window.location.reload();
        });
    }
}
