import { Component, OnDestroy, OnInit } from '@angular/core';
import { Section, Certificate, CourseSubscriptionSession, SectionContent, SectionContentSession } from '@stuplay';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserProvider } from '../../../utils/services/user.provider';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from '../../../utils/services/storage.service';
import { StatsService } from '../../../utils/services/stats.service';

declare global { // new typescript removed msSaveBlob since it will be a deprecated spec
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

@Component({
    selector: 'msc-certificate',
    templateUrl: 'certificate.component.html'
})
export class CertificateComponent implements OnInit, OnDestroy {
    certificate: Certificate;
    moduleSubscription: Subscription;
    account: any;
    section: Section;
    courseSubscriptionSession: CourseSubscriptionSession;
    sectionContent: SectionContent;
    sectionContentSession: SectionContentSession;
    achieved: boolean = false;
    preview: boolean = false;
    isOrdered: boolean = false;
    course: any;
    company: any;
    pending;

    constructor(
        private route: ActivatedRoute,
        private userProvider: UserProvider,
        public timerService: TimerService,
        private router: Router,
        private storageService: StorageService,
        private statsService: StatsService,
    ) {
        this.moduleSubscription = this.route.paramMap.subscribe(() => {
            this.certificate = this.route.snapshot.data.certificate.certificate;
            this.sectionContent = this.route.snapshot.data.certificate;
            this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
            this.course = this.route.parent.parent.snapshot.data.course;
            this.timerInit();
        });
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.course = this.storageService.get('course');
        this.certificate = this.route.snapshot?.data?.certificate?.certificate;
        this.sectionContent = this.route.snapshot?.data?.certificate;
        this.sectionContentSession = this.route.snapshot?.data?.sectionContentSession;
        this.section = this.route.parent.snapshot.data?.section;
        this.courseSubscriptionSession = this.route.parent?.parent?.snapshot?.data?.courseSubscriptionSession;

        this.userProvider.getCurrentUser().subscribe((user) => {
            this.account = user;
        });

        this.route.queryParams.subscribe((params: Params) => {
            this.preview = !!params.preview;

            if (this.preview) {
                this.achieved = true;
            } else {
                this.checkAchieved();
            }
        });

        this.isOrdered = this.storageService.get('course')?.isOrdered;
    }

    ngOnDestroy(): void {
        this.moduleSubscription.unsubscribe();
    }

    sendMail(): void {
        this.certificate.sendMail(this.course?.id, this.company?.id).subscribe();
    }

    downloadCertificate() {
        this.certificate.download().subscribe((blob) => {
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, this.course?.title + '.pdf');
            } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.download = this.course?.title + '.pdf';
                a.click();
                URL.revokeObjectURL(url);
                a.remove();
            }
        });
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }

    private checkAchieved(): void {
        if (!(this.achieved = this.storageService.get('certificate'))) {
            this.certificate.checkAchieved(this.course?.id, this.sectionContentSession?.sectionContentId, this.company?.id, this.account.id).subscribe({
                next: (data) => {
                    this.achieved = data?.type == "subscription_certificated";
                    this.pending = true;
                    this.storageService.set('certificate', this.achieved);
                    if (!this.sectionContentSession?.doneAt) {
                        this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe(() => {
                            this.statsService.refresh(true);
                        });
                    }
                },
                error: () => {
                    this.achieved = false;
                }
            });
        }
    }
}
