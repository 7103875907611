import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getParams } from '../helpers';
import { StuplayConfig } from '../stuplay-config';

@Injectable()
export class CompanyProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) { }

    getCompany(slug: string, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/company/${slug}?includes=experience`, { params: getParams(params) });
    }
}
