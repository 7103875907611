<div class="media-button-upload" style="margin: 20px 0 0 0;">
    <button *ngIf="!media && !hidden" class="check-document add check" (click)="modalOpen(modalMedia)">
        {{ 'player.media.add-document' | translate }}
    </button>
    <button *ngIf="media" class="check-document check" (click)="displayMedia()">
        <div class="interior">
            {{ 'player.media.see-document' | translate }}
            <div class="document-name">
                {{ media.title | slice:0:20 }}
            </div>
        </div>
        <img *ngIf="type === 'upload' && media && !hidden" src="https://static.myskillcamp.com/images/studio/icons/icon_trash.svg" alt="" (click)="deletedMedia(media)">
    </button>
    <a *ngIf="media" #downloadableMedia [href]="dynamicUrl(media)" target="_blank"></a>
</div>

<ng-template #modalMedia let-modal>
    <single-upload (closeModal)="modal.close()" (updateMedia)="onUpdateMedia($event)"></single-upload>
</ng-template>
