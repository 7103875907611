import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { cleanObject } from '../helpers';

export class Tag {
    id: number;
    title: string;

    constructor(tag: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(tag);
    }

    extends(tag: any): Tag {
        Object.assign(this, tag);
        return this;
    }

    update(): Observable<Tag> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/module/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }
}
