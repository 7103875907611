<div id="footer" class="footer">
    <div class="footer__content" [ngClass]="{ 'disabled': previewMode }">
        <div class="tabs">
            <div class="tab__nav">
                <ng-container *ngIf="course?.discussionEnabled">
                    <div class="tab__item icon icon-custom icon-message-circle-outline" (click)="changeState('discussion')" [class.tab__item_active]="linkState === 'discussion'">
                        <img src="https://static.myskillcamp.com/images/studio/icons/icon_discussion.svg" alt="" class="d-none">
                        {{ 'player.footer.tab-discussion' | translate }}
                    </div>
                </ng-container>

                <div class="tab__item icon-custom icon icon-browser-outline" (click)="changeState('notes')" [class.tab__item_active]="linkState === 'notes'">
                    <img src="https://static.myskillcamp.com/images/studio/icons/icon_notes.svg" alt="" class="d-none">
                    {{ 'player.footer.tab-notes' | translate }}
                </div>
            </div>
            <div class="tab__content" *ngIf="linkState === 'notes'">
                <msc-note></msc-note>
            </div>

            <div class="tab__content" *ngIf="linkState === 'discussion'">
                <app-timeline [context]="context" [contextId]="contextId"></app-timeline>
            </div>
        </div>
    </div>
    <div class="footer__overlay" *ngIf="previewMode">
        {{ 'player.discussion-note-disabled' | translate }}
    </div>
</div>
