<div class="slide certificate">
    <div class="certificate__content" *ngIf="achieved">
        <div class="certificate__header">
            <ul class="certificate__share">
                <!--<li><a href="#"><i class="icon icon--view"></i></a></li>-->
                <li *ngIf="!preview">
                    <a (click)="sendMail()">
                        <i class="icon icon-email-outline"></i>
                    </a>
                </li>
                <!--<li><a href="#"><i class="icon icon--linkedin"></i></a></li>-->
            </ul>
        </div>

        <div class="row">
            <div class="column medium-8">
                <h2 class="heading">
                    <span>{{ 'words.'+certificate.type | translate }}</span> {{ sectionContent?.title }}
                </h2>

                <p class="multiline" [innerHTML]="certificate?.description | safe:'html'"></p>
                <label class="duration">{{ certificate?.duration }}</label>
            </div>

            <div class="column medium-4">
                <media-holder *ngIf="certificate?.media" [media]="certificate?.media"></media-holder>
            </div>
        </div>

        <div class="row">
            <div class="column medium-8">
                <div class="receiver">
                    {{ 'player.certificate.document.send-to' | translate}}<br/>
                    <span class="name">{{ account?.name}}</span><br/>
                    <span class="email">{{ account?.email}}</span><br/>
                </div>
            </div>

            <div class="column medium-4">
                <div class="deliver">
                    <strong>{{ 'player.certificate.document.send-by' | translate}}</strong>
                    <div class="row">
                        <div class="columns small-6">
                            {{ certificate?.firstName}}
                        </div>

                        <div class="columns small-6">
                            {{ certificate?.lastName}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns small-12">
                            {{ certificate?.email}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns small-12">
                            {{ certificate?.jobTitle}}
                        </div>
                    </div>

                    <strong>{{ 'player.certificate.document.delivered-by' | translate }}</strong>
                    <div class="row">
                        <div class="columns small-12">
                            {{ certificate?.companyName }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns small-12">
                            {{ certificate?.phoneNumber }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns small-12">
                            {{ certificate?.address }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn" (click)="downloadCertificate()" style="margin: 1rem 0 0 0">
            {{ 'player.certificate.document.download' | translate}}
        </button>
    </div>

    <div class="certificate__content" *ngIf="!achieved">
        <div class="card">
            <div class="card__image">
                <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_lock.svg" alt="">
            </div>

            <div class="card__title" [innerHTML]="(!pending ? 'player.certificate.not-allowed' : 'player.certificate.pending') | translate"></div>
            <div class="card__text"></div>
        </div>
    </div>

    <msc-next-prev-btn *ngIf="isOrdered"></msc-next-prev-btn>
</div>

<app-footer [context]="'certificate'" [contextId]="certificate?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
