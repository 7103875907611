import { Component, ViewChild, ComponentRef, Output, Input, EventEmitter } from '@angular/core';
import { Question, QuestionSession, Exercise, Timeline, SectionContent } from '@stuplay';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { questionComponentFactory } from './question-component.factory';
import { StatsService } from '../../../../utils/services/stats.service';
import { ToastService } from '../../../../utils/components/toast/toast.service';
import { QuestionHostDirective } from '../../../../utils/directives/question-host.directive';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-question',
    templateUrl: 'question.component.html',
    styleUrls: ['./question.component.less'],
})

export class QuestionComponent {
    @ViewChild(QuestionHostDirective, { static: true }) questionHost!: QuestionHostDirective;
    @Input() exercise: Exercise;
    @Input() question: Question;
    @Input() questionSession: QuestionSession;
    @Input() sectionContent: SectionContent;
    @Input() timeline: Timeline;
    @Output() nextEvent: EventEmitter<string> = new EventEmitter();

    public answer: any;
    public hintShowed: boolean = false;
    public lastQuestion: boolean = false;
    public loading: boolean = false;
    public questionDetailRef: ComponentRef<any>;
    public questionSessionSubscription: Subscription;
    public status: string = 'untouched';

    constructor(
        private storageService: StorageService,
        private statsService: StatsService,
        private toastService: ToastService,
    ) { }

    ngOnInit() {
        const isTouched = this.questionSession.updatedAt && (this.questionSession.updatedAt > this.questionSession.createdAt);
        if (isTouched && !this.questionSession.correctedAt) {
            this.status = 'touched'
        }

        if (isTouched && this.questionSession.doneAt) {
            this.status = 'validate'
        }
        this.renderComponent();
    }

    ngAfterContentInit() {
        this.renderComponent();
    }

    ngOnChanges() {
        this.hintShowed = this.lastQuestion = false;
        const isTouched = this.questionSession.updatedAt && (this.questionSession.updatedAt > this.questionSession.createdAt);
        if (isTouched && !this.questionSession.correctedAt) {
            this.status = 'touched'
        }

        if (isTouched && this.questionSession.doneAt) {
            this.status = 'validate'
        }
        this.renderComponent();
        this.lastQuestion = this.isLastQuestion();
    }

    ngOnDestroy() {
        this.questionSessionSubscription.unsubscribe();
    }

    get showBtnCorrectAnswer(): boolean {
        return this.question.type === 'linker' && !!this.questionSession.doneAt;
    }

    renderComponent(): void {
        const viewContainerRef = this.questionHost.viewContainerRef;
        viewContainerRef.clear();

        this.questionDetailRef = viewContainerRef.createComponent(questionComponentFactory(this.question.type));

        if (this.questionDetailRef) {
            this.questionDetailRef.instance.question = this.question;
            this.questionDetailRef.instance.exercise = this.exercise;
            this.questionDetailRef.instance.questionSession = this.questionSession;
        }
        if (this.questionSessionSubscription) {
            this.questionSessionSubscription.unsubscribe();
        }
        this.questionSessionSubscription = this.questionDetailRef.instance.questionSessionChange.subscribe((questionSession: any) => {
            this.questionSession = questionSession;
        });
    }

    toggleHint(): void {
        this.hintShowed = !this.hintShowed;
        this.questionSession.hintClick += 1;
        this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
    }

    validate(): void {
        if (this.isValidate() && !this.loading) {
            this.loading = true;
            const params = {
                requestCorrection: true,
                company_id: this.storageService.get('company')?.id
            }

            this.questionSession
                .update(params)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(() => {
                    this.loading = false;
                    this.status = 'validate';
                    this.statsService.refresh(true);

                    if (!this.questionSession.doneAt) {
                        this.toastService.push('question-retry', 'warning');
                        for (const choice of this.questionSession.answer?.choices) {
                            choice.state = null;
                        }
                        this.renderComponent();
                    }
                });
        }
    }

    isValidate(): boolean {
        if (this.questionSession.type === 'true-false') {
            return this.questionSession.answer?.choice !== null;
        } else if (this.questionSession.type === 'multiple') {
            let findResponse = false;
            this.questionSession.answer = this.questionDetailRef?.instance?.internalQuestionSession?.answer;

            this.questionSession.answer?.choices?.forEach((choice: any) => {
                if (choice.state !== null) {
                    findResponse = true;
                }
            });

            return findResponse;
        } else if (this.questionSession.type === 'fill-in') {
            const answers = this.questionDetailRef?.instance?.questionSession?.answer?.choices[0]?.choice;

            return answers.indexOf('[undefined]') === -1;
        } else {
            return true;
        }
    }

    toggleCorrectAnswer(): void {
        this.questionDetailRef.instance.showCorrectAnswer = !this.questionDetailRef?.instance?.showCorrectAnswer;
    }

    next(): void {
        this.nextEvent.emit();
    }

    checkQuestionMedia(): number {
        return this.question.media ? 142 : 200;
    }

    isLastQuestion(): boolean {
        return this.question?.id === this.exercise?.questions[this.exercise.questions.length - 1]?.id;
    }

    show(): boolean {
        if (this.questionSession.doneAt) {
            if (this.questionSession.type !== 'open-write' && (this.question.feedbackWrongAnswer || this.question.feedbackDefault)) {
                return true;
            } else if (this.questionSession.type === 'open-write' && this.questionSession.correctedAt) {
                return true;
            }
        }
        return false;
    }
}
