<div class="notes-listing">
    <div class="row">
        <ng-container *ngIf="!currentNote">
            <div style="width: 100%;">
                <div class="columns" (click)="createNote()">
                    <div class="create-note">
                        <div class="create-note__title">
                            {{ 'player.footer.notes.create' | translate}}
                        </div>
                    </div>
                </div>

                <div (click)="setCurrentNote(note)" *ngFor="let note of notes" class="columns">
                    <a class="note">
                        <div class="note__title" [innerHtml]="note.name"></div>
                        <div class="note__date">
                            {{ 'player.footer.notes.last-modification' | translate}}
                            <span>{{note.updated_at | amTimeAgo}}</span>
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>

        <msc-note-detail [note]="currentNote" (deleteSelectedNote)="deleteNote($event)" (editor)="resetCurrentNote()"
            *ngIf="currentNote"></msc-note-detail>
    </div>
</div>

<style>
    msc-note-detail {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 1rem;
    }
    @media (max-width: 1024px) {
        msc-note-detail {
        overflow: hidden;
        }
    }
</style>