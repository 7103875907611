import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './utils/services/storage.service';
import { CompanyProvider } from '@stuplay';
import { lastValueFrom } from 'rxjs';
import { UrlHelper } from './utils/helpers';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompanyGuard implements CanActivate {
    constructor(private router: Router, private companyProvider: CompanyProvider, private storageService: StorageService, private cookieService: CookieService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.cookieService.check('current_company')) {
            const companyData: any = await lastValueFrom(this.companyProvider.getCompany(this.cookieService.get('current_company'), { includes: 'roles,experience' }));
            this.storageService.set('company', companyData);
            if (!route.params.companyId) {
                this.router.navigateByUrl(`${companyData.slug}${state.url}`);
            }
            return true;
        } else {
            if (route.params.companyId) {
                this.cookieService.set('current_company', route.params.companyId, 10, '/', UrlHelper.getDomain(), false, 'Lax');
                window.location.reload();
            }
            return false;
        }
    }
}
