import { Component, EventEmitter } from '@angular/core';
import { SectionContent, SectionContentSession, SelfAssessment, TimelineProvider } from '@stuplay'
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { ToastService } from '../../../utils/components/toast/toast.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from 'src/app/utils/services/translation.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-self-assessment',
    templateUrl: './self-assessment.component.html',
    styleUrls: ['./self-assessment.component.less']
})
export class SelfAssessmentComponent {
    selfAssessment: SelfAssessment;
    sectionContent: SectionContent;
    sectionContentSession: SectionContentSession;
    selfAssessmentSubscription: Subscription;
    range: any[];
    medias: any[];
    mediasId: any[];
    index: number;
    validate: boolean = false;
    error: boolean = false;
    studentComment: string = '';
    timelineId: number;
    loading: boolean = false;
    company: any;
    content: any;
    displayNote: boolean = false;
    navigateEmitter: EventEmitter<boolean> = new EventEmitter();
    ratio: number;

    constructor(
        private route: ActivatedRoute,
        public timerService: TimerService,
        private timelineProvider: TimelineProvider,
        private translate: TranslationService,
        private toastService: ToastService,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.company = this.storageService.get('company');

        this.selfAssessmentSubscription = this.route.paramMap.subscribe(() => {
            this.selfAssessment = this.route.snapshot.data?.selfassessment?.selfassessment;
            this.sectionContent = this.route.snapshot.data?.selfassessment;
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
            this.index = this.sectionContentSession?.sectionContentId;
            this.range = [];
            this.medias = [];
            this.mediasId = [];
            this.validate = false;
            this.initArrays();
            this.reloadButtonMedia();
            this.timerInit();
            this.content = this.sectionContentSession?.selfassessmentAnswer?.content ? this.sectionContentSession?.selfassessmentAnswer?.content : '';
            this.ratio = this.sectionContentSession?.selfassessmentAnswer?.ratio ? this.sectionContentSession?.selfassessmentAnswer?.ratio : 50;
            this.studentComment = '';

            if (this.content || (this.sectionContentSession?.selfassessmentAnswer && this.sectionContentSession?.selfassessmentAnswer?.medias)) {
                this.displayNote = true;
            } else {
                this.displayNote = this.selfAssessment?.type === 'none';
            }

            if (this.sectionContentSession?.selfassessmentAnswer?.id) {
                this.getTimelineId();
            }
        });
    }

    ngOnDestroy() {
        this.selfAssessmentSubscription.unsubscribe();
    }

    update(): void {
        this.onChange(this.content);
    }

    onChange(content: any): void {
        this.content = content;
    }

    checkSelfAssessmentAnswer(content: any): void {
        if (this.selfAssessment.type === 'both' && !this.displayNote) {
            if (this.content.length <= 0 || this.mediasId[this.index].length <= 0) {
                this.openModal(content)
            } else {
                this.createAnswer();
            }
        } else {
            if (this.displayNote) {
                this.updateAnswer();
            } else {
                this.createAnswer();
            }
        }
    }

    checkMedias(): boolean {
        return this.mediasId[this.index].length > 0
    }

    validateModal(): void {
        if (this.displayNote) {
            this.closeModal();
            this.updateAnswer();
        } else {
            this.closeModal();
            this.createAnswer();
        }
    }

    updateAnswer(): void {
        const ratioParams = {
            ratio: this.ratio,
            companyId: this.company?.id
        };
        const params = {
            sectionContentSessionId: this.sectionContentSession?.id,
            content: this.content,
            medias: this.medias?.length > 0 ? this.medias[this.index] : null,
        };

        const post = {
            message: this.studentComment || this.translate.instant('selfassessment.correction.empty-message'),
            media: null,
            link: null
        };
        this.loading = true;
        this.sectionContentSession.createOrUpdateSelfassessmentAnswer(this.selfAssessment?.id, params)
            .subscribe((data) => {
                this.sectionContentSession
                    .updateSelfAssessmentRatio(data?.selfassessmentId, data?.id, ratioParams)
                    .pipe(
                        switchMap(() => this.sectionContentSession.update(true, this.company?.id))
                    )
                    .subscribe(() => {
                        if (!this.timelineId) {
                            this.getTimelineId(post);
                        } else {
                            this.sendMessage(post);
                        }
                    });
            });
    }

    activityFinished(): void {
        this.createAnswer();
    }

    onUpdateMedia(media: any): void {
        this.medias[this.index].push(media);
        this.mediasId[this.index].push(media.id);
        this.reloadButtonMedia();
    }

    onDeleteMedia(index: number): void {
        this.medias[this.index].splice(index, 1);
        this.mediasId[this.index].splice(index, 1);
        this.reloadButtonMedia();
    }

    onError(): void {
        this.error = true;

        const timeOut = setTimeout(() => {
            this.error = false;
            clearTimeout(timeOut);
        }, 5000);
    }

    reloadButtonMedia(): void {
        this.range[this.index] = [0];
        const length = (this.sectionContentSession?.selfassessmentAnswer?.medias) ? this.sectionContentSession?.selfassessmentAnswer?.medias.length : this.mediasId[this.index].length;
        for (let i = 0; i < length; i++) {
            this.range[this.index].push(i + 1);
        }

        if (this.sectionContentSession?.selfassessmentAnswer?.medias && !this.selfAssessment?.allowRevaluation) {
            this.range[this.index].pop();
        }
    }

    createAnswer(): void {
        const params = {
            sectionContentSessionId: this.sectionContentSession?.id,
            content: this.content,
            medias: this.mediasId.length > 0 ? this.mediasId[this.index] : null,
            ratio: this.ratio,
            student_comment: this.studentComment
        };
        this.validate = true;
        this.error = false;
        this.loading = true;
        this.sectionContentSession.createOrUpdateSelfassessmentAnswer(this.selfAssessment?.id, params).subscribe(() => {
            this.getTimelineId();

            const timeOut = setTimeout(() => {
                this.loading = false;
                this.toastService.push('selfassessment.sent.files', 'success');
                this.displayNote = true;
                clearTimeout(timeOut);
            }, 1500);
        });
    }

    initArrays(): void {
        if (!this.mediasId[this.index]) {
            this.mediasId[this.index] = [];
        }
        if (!this.medias[this.index]) {
            this.medias[this.index] = [];
        }
        if (this.sectionContentSession?.selfassessmentAnswer?.medias && this.sectionContentSession?.selfassessmentAnswer?.medias.length > 0) {
            this.sectionContentSession.selfassessmentAnswer.medias.forEach((media) => {
                this.mediasId[this.index].push(media.id);
                this.medias[this.index].push(media);
            });
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }

    translateButton(): string {
        if (this.displayNote) {
            return '.selfassessment.finished';
        } else {
            if (this.selfAssessment.type === 'media') {
                return '.selfassessment.media';
            } else if (this.selfAssessment.type === 'text') {
                return '.selfassessment.text'
            } else if (this.selfAssessment.type === 'both') {
                return '.selfassessment.both'
            }
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    getTimelineId(post?: any): void {
        const params = {
            context: 'selfassessment-answer',
            context_id: this.sectionContentSession?.selfassessmentAnswer?.id
        };

        this.timelineProvider.getTimelineId(params).subscribe((data) => {
            this.timelineId = data?.id;

            if (post) {
                this.sendMessage(post);
            }
        });
    }

    sendMessage(post: any): void {
        this.timelineProvider.addPost(post?.message, this.timelineId, post?.media, post?.link, this.company?.id).subscribe(() => {
            this.toastService.push('selfassessment.sent', 'success');
            this.loading = false;
            this.navigateEmitter.emit(true);
        });
    }
}
