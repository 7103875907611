<ng-template #modalInactivity let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center;" (click)="modal.close()">
        <div class="title">{{ 'player.inactivity.title' | translate }}</div>
        <p style="margin-bottom: 30px;">{{ 'player.inactivity.description' | translate }}</p>
        <div class="button">
            <button class="btn btn-action">{{ 'player.inactivity.action' | translate }}</button>
        </div>
    </div>
</ng-template>
