<div class="slide slide-result">
    <div class="course-final">
        <h1>{{ 'player.course-end.title' | translate }}</h1>
        <span *ngIf="course?.progress === 100" class="cheer">{{ 'player.course-end.finished' | translate }}</span>

        <h2>{{ 'player.course-end.recap' | translate }}</h2>
        <div class="scores">
            <div class="score course-score">
                <span class="score__ratio">{{ courseSubscriptionSession?.getCampProgress() | number:'1.0-0' }} %</span>
                <span class="score__title">{{ 'player.course-end.overall-progress' | translate }}</span>
            </div>

            <div class="score course-score">
                <span class="score__ratio" *ngIf="courseSubscriptionSession?.getCampScore() !== null">
                    {{ courseSubscriptionSession?.getCampScore() | number:'1.0-0' }}%
                </span>

                <span class="score__ratio" *ngIf="courseSubscriptionSession?.getCampScore() === null">
                    {{ courseSubscriptionSession?.getCampScore() | result }}
                </span>

                <span class="score__title">{{ 'player.course-end.grade' | translate }}</span>
            </div>

            <div class="score course-score" *ngIf="courseSubscriptionSession?.getDuration">
                <span class="score__ratio">{{ courseSubscriptionSession?.getDuration() | time:true }}</span>
                <span class="score__title">{{ 'player.course-end.time-passed' | translate }}</span>
            </div>
        </div>

        <div class="course-final__footer">
            <a class="btn btn_width_1" [href]="backUrl">
                {{ 'player.course-end.back' | translate }}
            </a>
        </div>

        <div class="section" *ngFor="let section of course.sections; let index = index">
            <h3 class="section__title">{{ section.title || ('player.common.untitled' | translate ) }}</h3>

            <div class="scores">
                <div class="score">
                    <span class="score__ratio">
                        {{ (courseSubscriptionSession?.getSectionProgress(section) * 100) | number:'1.0-0' }}%
                    </span>

                    <span class="score__title">{{ 'player.course-end.progress' | translate }}</span>
                </div>

                <div class="score">
                    <span class="score__ratio">
                        {{ courseSubscriptionSession?.getSectionScore(section) | result }}
                    </span>

                    <span class="score__title">
                        {{ 'player.course-end.exercise.success' | translate }}
                    </span>
                </div>
            </div>

            <!--<div class="section-content" *ngFor="let sectionContent of section.sectionContents; let exerciseIndex = index">
                <div *ngIf="sectionContent.context === 'exercise' && courseSubscriptionSession">
                    <div class="section-content__title">{{'player.course-end.exercise.number' | translate }}{{ exerciseIndex + 1}}</div>

                    <div class="section-content__stats">
                        <div class="stat">
                            <div class="stat__value">
                                <img src="https://static.myskillcamp.com/images/icon/icon-studio/picto_success.svg" alt=""> {{ (getSectionContentSession(sectionContent.id).getScore() * 100) | number:'1.0-0'}}%
                            </div>
                            <div class="stat__title">
                                {{'player.course-end.exercise.success' | translate }}
                            </div>
                        </div>

                        <div class="stat">
                            <div class="stat__value">
                                <img src="https://static.myskillcamp.com/images/icon/icon-studio/picto_score.svg" alt=""> {{ (getSectionContentSession(sectionContent.id).getRightQuestionNumber())}}
                            </div>
                            <div class="stat__title">
                                {{'player.course-end.exercise.right-answers' | translate }}
                            </div>
                        </div>

                        <div class="stat">
                            <div class="stat__value">
                                <img src="https://static.myskillcamp.com/images/icon/icon-studio/picto_fail.svg" alt=""> {{ getSectionContentSession(sectionContent.id).getWrongQuestionsNumber()}}
                            </div>
                            <div class="stat__title">
                                {{'player.course-end.exercise.wrong-answers' | translate }}
                            </div>
                        </div>

                        <div class="stat">
                            <div class="stat__value">
                                <img src="https://static.myskillcamp.com/images/icon/icon-studio/picto_time.svg" alt=""> {{ getSectionContentSession(sectionContent.id).getExerciseDuration() | time}}
                            </div>
                            <div class="stat__title">
                                {{'player.course-end.time-passed' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>

        <div class="course-final__footer">
            <a class="btn btn_width_1" [href]="backUrl">
                {{ 'player.course-end.back' | translate }}
            </a>
        </div>
    </div>
</div>
