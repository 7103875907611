import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UniqueQuestion, UniqueQuestionSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/utils/services/storage.service';
import { TimerService } from '../../../../../utils/components/timer/timer.service';

@Component({
    selector: 'msc-ucq',
    templateUrl: 'unique.component.html',
    styleUrls: ['./unique.component.less']
})
export class UniqueQuestionChoiceComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    internalQuestionSession: UniqueQuestionSession;
    question: UniqueQuestion;
    questionSession: UniqueQuestionSession;
    questionSessionChange: EventEmitter<UniqueQuestionSession> = new EventEmitter();

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        this.refreshSession();

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refreshSession(): void {
        let change = false;

        for (const i in this.question.content.choices) {
            if (!this.questionSession.answer.choices[i]) {
                this.questionSession.answer.choices[i] = {
                    choice: this.question.content.choices[i].choice,
                    state: null,
                    isCorrect: null
                };
                change = true;
            } else {
                if (this.question.content.choices[i].choice !== this.questionSession.answer.choices[i].choice) {
                    this.questionSession.answer.choices[i].choice = this.question.content.choices[i].choice;
                    change = true;
                }
            }
        }

        if (change) {
            const params = {
                requestCorrection: false,
                forceRefresh: true,
                company_id: this.storageService.get('company')?.id
            }
            this.questionSession.update(params).subscribe();
        }

        this.internalQuestionSession = Object.assign({}, this.questionSession);
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    resetChoices(): void {
        for (const choice of this.internalQuestionSession.answer.choices) {
            choice.state = false;
        }
    }

    onChange(index: number): void {
        this.resetChoices();
        this.internalQuestionSession.answer.choices[index].state = this.questionSession.answer.choices[index].state = true;
        this.questionSessionChange.emit(this.questionSession);
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }
}
