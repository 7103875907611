import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionContent, SectionContentSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataHelper } from '../../../utils/helpers';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'app-external-link',
    templateUrl: './external-link.component.html',
})
export class ExternalLinkComponent implements OnInit {
    @Input() lti: any;

    public timers: any;
    public sectionContent: SectionContent;
    public ltiSubscription: Subscription;
    public sectionContentSession: SectionContentSession;
    public provider: any;
    public internalTime: number = 0;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.ltiSubscription = this.route.paramMap.subscribe(() => {
            this.sectionContent = this.route.snapshot.data.externalLink;
            this.lti = this.sectionContent.externalLink;
            this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
            this.provider = this.route.parent.parent.snapshot.data.course?.provider;
            this.internalTime = 0 || this.sectionContentSession.duration;
        });

        this.timers = {
            duration: null,
            internal: null
        };
    }

    closeModal(): void {
        this.modalService.dismissAll();
        clearInterval(this.timers.duration);
        clearInterval(this.timers.internal);
    }

    launchLti(content: any): void {
        if (this.lti.mode === 'embed') {
            this.buildLTIForm(this.lti, 'embed-iframe')
        } else {
            this.buildLTIForm(this.lti, '_blank');
        }

        if (this.provider && this.provider.progressEnabled === false) {
            return;
        }

        const options: NgbModalOptions = { size: 'lg', centered: true, backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);

        this.timers.internal = setInterval(() => {
            this.internalTime++;
        }, 1000);

        this.updateTime();
    }

    updateTime(): void {
        this.timers.duration = setInterval(() => {
            this.sectionContentSession.duration += 5;
            this.sectionContentSession.update(false, this.storageService.get('company')?.id).subscribe();
        }, 5000);
    }

    finishLti(): void {
        this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe();
        this.closeModal();
    }

    buildLTIForm(integration: any, mode: string): void {
        const form = document.createElement('form');
        form.target = mode;
        form.method = 'POST';
        form.action = integration.url;
        for (const key in integration.fields) {
            if (integration.fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = DataHelper.changeCase(key);
                input.setAttribute('value', integration.fields[key]);
                form.appendChild(input);
            }
        }
        if (mode === '_blank') {
            document.body.appendChild(form);
        } else {
            document.querySelector('#embed-code').appendChild(form);
        }
        form.submit();
    }
}
