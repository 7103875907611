<div class="slide event event_mode_single">
    <div class="event-presentation">
        <div class="row">
            <div class="columns event-presentation__infos">
                <h2 class="heading">
                    <span>{{ 'player.sidebar.section-content.context-event' | translate}}</span>
                    {{ event.title }}
                </h2>

                <p class="event-presentation__description multiline" [innerHTML]="sectionContent?.description | safe:'html'"></p>
            </div>

            <div class="columns" *ngIf="eventSession && eventSession?.icsCalendar">
                <div class="event-export">
                    <a class="event-export__link" [href]="eventSession?.icsCalendar" target="_blank">
                        <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_calendar.svg" alt="">
                        <span class="event-export__title">{{ 'event.event-session.download.add-calendar' | translate}}</span>
                        <span class="event-export__format">{{ 'event.event-session.download.title' | translate}}</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="columns event-presentation__img">
                <media-holder [height]="410" [media]="event?.media" [cover]="'https://static.myskillcamp.com/images/studio/img_default_event.jpg'" ></media-holder>
            </div>
        </div>

        <div class="row">
            <div class="columns medium-6">
                <div class="row">
                    <div class="columns medium-6 micro-data" *ngIf="event?.type === 'place'">
                        <span class="micro-data__title">
                            <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_pin.svg">
                            {{ 'event.event-session.type.place.description' | translate}}
                        </span>
                        <span class="micro-data__info">
                            {{ eventSession.address }}
                        </span>
                    </div>

                    <div class="columns medium-6 micro-data" *ngIf="event?.type === 'visio'">
                        <span class="micro-data__title">
                            {{ 'event.event-session.type.visio.description' | translate}}
                        </span>
                        <span class="micro-data__info" *ngIf="event.link">
                            <a [attr.href]="event.link" target="_blank">{{event.link}}</a>
                        </span>
                    </div>

                    <div class="columns medium-12 micro-data">
                        <span class="micro-data__title">
                            <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_langue.svg">
                            {{ 'language' | translate }}
                        </span>

                        <span class="micro-data__info">
                            {{ course.language?.name }}
                        </span>
                    </div>

                    <div class="columns medium-12 micro-data" *ngIf="event?.type === 'virtual-class'">
                        <span class="micro-data__title">
                            <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_langue.svg">
                            {{ 'common.virtual-class' | translate}}
                        </span>

                        <a class="btn" [href]="event?.url" target="_blank" *ngIf="event.url">{{ 'event.virtual-class.join' | translate }}</a>
                    </div>

                    <div class="columns medium-12 micro-data">
                        <span class="micro-data__title">
                            <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_competence.svg">
                            {{ 'event.event-session.additional-informations' | translate}}
                        </span>

                        <span class="micro-data__info multiline" [innerHTML]="event?.info | safe:'html'"></span>

                        <span class="micro-data__info" *ngFor="let eventSessionMedia of eventSession.eventSessionMedia; let index = index">
                            <button class="check-document check" (click)="downloadEventSessionMedia(index)" style="margin-top: 10px;">
                                <div class="interior">
                                    {{ 'event.event-session.show-document' | translate  }}
                                    <div class="document-name">
                                        {{eventSessionMedia.media.title | slice:0:20 }}
                                    </div>
                                </div>
                            </button>

                            <a #downloadableEventSessionMedia [href]="dynamicUrl(eventSessionMedia.media)" target="_blank"></a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="columns medium-6">
                <div class="event__dates date_number_many">
                    <div class="date">
                        <div class="date__title">
                            {{ 'event.event-session.start-at' | translate}}
                        </div>

                        <div class="date__content">
                            <span class="date__day">{{ formatDate(eventSession?.startAt) | date:'dd' }}</span>
                            <span class="date__month">{{('date.' + (formatDate(eventSession?.startAt) | date:'MMMM')) | translate }}</span>
                        </div>
                    </div>

                    <div class="date">
                        <div class="date__title">
                            {{ 'event.event-session.end-at' | translate}}
                        </div>

                        <div class="date__content">
                            <span class="date__day">{{ formatDate(eventSession?.endAt) | date:'dd' }}</span>
                            <span class="date__month">{{ ('date.' + (formatDate(eventSession?.endAt) | date:'MMMM')) | translate }}</span>
                        </div>
                    </div>

                    <div class="hours">
                        <div class="hour">
                            {{ formatDate(eventSession?.startAt) | date:'HH:mm' }}
                        </div>

                        <div class="hour">
                            {{ formatDate(eventSession?.endAt) | date:'HH:mm' }}
                        </div>
                    </div>
                </div>

                <div *ngIf="event.link">
                    <button class="btn" (click)="goToEvent()" style="width:100%; margin-top:5vh;">
                        {{ 'player.event.goto-event' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="columns medium-6 micro-data">
        <span class="micro-data__title">
            <img src="https://static.myskillcamp.com/images/icon/icon-studio/icon_manager.svg" alt="">{{ 'event.speakers' | translate }}
        </span>

                <span class="micro-data__info">
            <ul class="accounts_list">
                <li class="account" *ngFor="let trainer of event.eventTrainers">
                    <img *ngIf="trainer.account" class="account__image" [src]="trainer?.account?.picture+'?size=32'">
                    <img *ngIf="!trainer.account" class="account__image" [src]="'https://ui-avatars.com/api/?background=cb96ff&color=a35ff0&font-size=0.6&length=1&name='+trainer.name+'?size=32'">
                    <span class="account__name">{{ trainer?.name }}</span>
                </li>
            </ul>
        </span>
            </div>
        </div>

        <div class="row" *ngIf="preview && !sectionContentSession.doneAt">
            <div class="">
                <button class="btn" (click)="finished()">
                    {{ 'words.mark-completed' | translate }}
                </button>
            </div>
        </div>
    </div>
    <msc-next-prev-btn *ngIf="course?.isOrdered"></msc-next-prev-btn>
</div>

<app-footer [context]="'event'" [contextId]="event?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
