<div class="row linker-group" [class.grabbed]="grabbed">
    <div class="columns medium-6">
        <ng-container *ngFor="let link of questionSession.answer.links; let linkIndex = index;">
            <div class="linker-group__label choice m-custom-choice">
                <span class="choice__text">{{link}}</span>
            </div>
        </ng-container>
    </div>

    <div class="columns medium-6">
        <ng-container *ngIf="!showCorrectAnswer">
            <div class="procedure__choices"
                cdkDropList
                [cdkDropListData]="questionSession.answer.choices"
                (cdkDropListDropped)="dropped($event)">
                <ng-container *ngFor="let choice of questionSession.answer.choices; let choiceIndex = index;">
                    <div class="choice m-custom-choice"
                        cdkDrag
                        [cdkDragDisabled]="questionSession.doneAt"
                        [ngStyle]="{'cursor': cursorDisable()}"
                        [class.grabbed]="grabbed"
                        [class.answer_success]="isAnswerSuccess(choice)"
                        [class.answer_failed]="isAnswerFailed(choice)">
                        <span class="choice__text">{{choice.name}}</span>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <div class="procedure__choices" *ngIf="showCorrectAnswer">
            <ng-container *ngFor="let choice of question.content.choices">
                <div class="choice answer_success m-custom-choice">
                    <span class="choice__text">{{choice}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>


<!-- old code -->
<!-- <div class="procedure__choices"
     dnd-sortable-container
     [sortableData]="questionSession.answer.choices"
     *ngIf="!showCorrectAnswer">
        <div
                class="choice m-custom-choice"
                *ngFor="let choice of questionSession.answer.choices; let choiceIndex = index;"
                [ngStyle]="{'cursor': cursorDisable()}"
                [class.grabbed]="grabbed"
                [class.answer_success]="isAnswerSuccess(choice)"
                [class.answer_failed]="isAnswerFailed(choice)"
                dnd-sortable
                [sortableIndex]="choiceIndex"
                [dragEnabled]="!questionSession.doneAt"
                [dropEnabled]="!questionSession.doneAt"
                [dragData]="choice"
                (onDragStart)="onDragStart()"
                (onDragEnd)="onChange(choice, choiceIndex)">
            <span class="choice__text">{{choice.name}}</span>
        </div>
</div> -->
