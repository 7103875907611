import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Media } from '@stuplay';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
    selector: 'media-embed',
    templateUrl: './embed.component.html',
    styleUrls: ['./embed.component.less']
})
export class MediaEmbedComponent implements OnInit, OnChanges {
    @ViewChild('iframe', { static: false }) iframe: any;
    @Input() media: Media;
    @Input() reloadUrl: boolean;
    public url: SafeResourceUrl;
    public disableIframe: boolean = false;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (this.media.url.indexOf('zoom.us') > -1) {
            this.disableIframe = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.url);
    }
}
