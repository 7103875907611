import { OnInit, AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';
import { EmitterService } from './utils/services/emitter.service';
import { DataHelper } from './utils/helpers';
import { TranslationService } from './utils/services/translation.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

    private favicon: any;
    public theme: any;

    constructor(
        private translate: TranslationService,
        private router: Router,
        private emitterService: EmitterService,
    ) {
        router.events.forEach((event: NavigationEvent) => {
            if (event instanceof NavigationStart) {
                this.progressBar.start();
            }
            if (event instanceof NavigationEnd) {
                this.progressBar.complete();
            }
        });
        translate.setLang('en');
    }

    ngOnInit() {
        this.favicon = document.querySelector('#favicon');

        this.emitterService.get('favicon.change').subscribe((data) => {
            if (!DataHelper.isEmpty(data)) {
                this.favicon.href = data;
            }
        });
    }

    ngAfterViewInit() {
        const preloaderElementRef = document.getElementById('preloader');
        if (preloaderElementRef !== null) {
            preloaderElementRef.style['display'] = 'none';
        }
    }


}