<div class="slide slide_presentation">
    <div class="column-left">
        <h2 class="heading">
            <span>{{ 'player.video.name' | translate }}</span> {{sectionContent?.title}}
        </h2>
        <video controls src="{{ video?.url }}" style="width: 100%"></video>
        <msc-next-prev-btn (isFinished)="activityFinished()" [validate]="true" [doneAt]="sectionContentSession?.doneAt"></msc-next-prev-btn>
    </div>
</div>

<app-footer [context]="'video'" [contextId]="video?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
