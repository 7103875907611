<img class="author__image avatar" [src]="comment.account.picture+'?size=48'" *ngIf="comment.account">
<span class="author__name" *ngIf="comment.account">
    {{ comment.account.name }}
</span>

<span class="date">{{ getDate(comment.createdAt) }}</span>

<div>
	<timeline-show-content [content]="comment.content"></timeline-show-content>

    <msc-link-manager [content]="comment.content.message" [class]="'m-link--blue'"></msc-link-manager>
</div>

<timeline-delete-content *ngIf="checkCanDelete()" (deleteContent)="onDeleteComment()"></timeline-delete-content>
