import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course, CourseSubscriptionSession, CourseSubscriptionSessionProvider } from '@stuplay';
import { SectionContentSession } from '@stuplay';
import { BackUrlService } from '../../utils/services/back-url.service';
import { TimerService } from '../../utils/components/timer/timer.service';
import { UserProvider } from '../../utils/services/user.provider';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
})
export class ResultsComponent {
    public course: Course | any;
    public courseSubscriptionSession: CourseSubscriptionSession;
    public lang: string = 'fr';

    constructor(
        private route: ActivatedRoute,
        private backUrlService: BackUrlService,
        public timerService: TimerService,
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private userProvider: UserProvider,
    ) {
        this.route.parent.data.subscribe(data => {
            this.lang = this.userProvider?.currentUser?.lang;
            this.course = data.course;
            this.courseSubscriptionSession = data.courseSubscriptionSession;

            const timeout = setTimeout(() => {
                this.courseSubscriptionSessionProvider.getCourseSubscriptionSession(this.courseSubscriptionSession?.id).subscribe((data) => {
                    this.courseSubscriptionSession = data;
                    clearTimeout(timeout);
                });
            }, 500);
        });
    }

    getSectionContentSession(id: number): SectionContentSession {
        return this.courseSubscriptionSession.sectionContentSessions.find(({ sectionContentId }) => sectionContentId === id);
    }

    get backUrl(): string {
        return this.backUrlService.backUrl;
    }
}
