import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../utils/components/toast/toast.service';
import { UrlHelper } from '../../utils/helpers';
import { samlConfig } from '../../../assets/saml/saml.config';
import { TranslationService } from 'src/app/utils/services/translation.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    public redirection: string;
    public credentials: any = {
        email: '',
        password: '',
    };
    public writePassword: boolean = false;
    public loading: boolean = false;
    public url: string;
    public isAuth: boolean = false;

    constructor(private auth: AuthService, private translate: TranslationService, private router: Router,
        private route: ActivatedRoute, private toastService: ToastService) { }

    ngOnInit() {
        this.redirection = this.route.snapshot.queryParams['returnUrl'] || null;
        this.translate.setLang('fr');

        this.redirectIfsaml();

        if (this.auth.loggedIn()) {
            this.isAuth = true;
            if (this.redirection && this.redirection !== '/') {
                this.router.navigateByUrl(this.redirection);
            } else {
                document.location.replace(UrlHelper.getUrl('app'))
            }
        }
    }

    private redirectIfsaml(): void {
        const config = samlConfig;
        const referrer = document.referrer;
        let findSaml = null;

        for (let i = 0; i < config.length; i++) {
            if (referrer.indexOf(config[i].archetype) > -1) {
                findSaml = i;
            }
        }

        if (findSaml !== null) {
            this.credentials.email = config[findSaml].email;
            this.login();
        }
    }

    login(): void {
        if (!this.loading) {
            this.loading = true;

            if (this.writePassword) {
                this.auth.login(this.credentials).subscribe(() => {
                    if (this.redirection && this.redirection !== '/') {
                        this.router.navigateByUrl(this.redirection);
                    } else {
                        document.location.replace(UrlHelper.getUrl('app'))
                    }
                }, () => {
                    this.loading = false;
                    this.toastService.push('invalid-credentials', 'error');
                });
            } else {
                const params = {
                    origin: document.location.href,
                    email: this.credentials.email
                };

                if (document.referrer) {
                    params['referrer'] = document.referrer;
                }

                this.auth.authenticateMethod(params).subscribe((data) => {
                    if (data.type === 'sso') {
                        if (data.method === 'saml') {
                            this.samlForm(data.params, data.url);
                        } else {
                            UrlHelper.navigate({ parts: [data.url], params: { state: data.params.state } }, '_self');
                        }
                    } else {
                        this.writePassword = true;
                        this.loading = false;

                        const timeOut = setTimeout(() => {
                            document.getElementById('password').focus();
                            clearTimeout(timeOut);
                        }, 100);
                    }
                }, () => {
                    this.loading = false;
                });
            }
        }
    }

    private samlForm(params: any, url: string): void {
        const form = document.createElement('form');
        form.target = '_self';
        form.method = 'POST';
        form.action = url;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.setAttribute('value', params[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }
}
