import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class StatisticsService {

    constructor(private http: HttpClient) { }

    launchActivity(id: number): Observable<any> {
        return this.http
            .post(`${environment.envVar.API_URL}/learning/section-contents/${id}/launch`, {})
            .pipe(map((data) => data));
    }

    launchContent(id: number, params: any): Observable<any> {
        return this.http
            .post(`${environment.envVar.API_URL}/learning/contents/${id}/launch`, params)
            .pipe(map((data) => data));
    }
}
