import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';

export class SurveyQuestionAnswer {
    id: number;
    title: string;
    courseId: number;
    accountId: number;
    createdAt: number;
    content: any;
    notApplicable: boolean;
    surveyId: number;

    constructor(surveyQuestionAnswer: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(surveyQuestionAnswer);
    }

    extends(surveyQuestionAnswer: any): SurveyQuestionAnswer {
        Object.assign(this, surveyQuestionAnswer);
        return this;
    }

    save(data: any, surveyQuestionId: number): Observable<SurveyQuestionAnswer> {
        if (this.id) {
            return this.http.put(`${this.stuplayConfig.envVar.API_URL}/survey/question/${surveyQuestionId}/answer/${this.id}`, data)
                .pipe(map(res => this.extends(res)));
        } else {
            return this.http.post(`${this.stuplayConfig.envVar.API_URL}/survey/question/${surveyQuestionId}/answer`, data)
                .pipe(map((questionAnswer: any) => {
                    this.id = questionAnswer?.id;
                    return this;
                }));
        }
    }
}
