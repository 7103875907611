import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { Survey } from './survey';

@Injectable()
export class SurveyProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getSurvey(courseId: number, companyId: number): Observable<Survey> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/survey?course_id=${courseId}&company_id=${companyId}&include=survey_groups.survey_questions.survey_question_answers`)
            .pipe(map((data) => new Survey(data, this.http, this.stuplayConfig)));
    }
}
