import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { OpenWriteQuestion, OpenWriteQuestionSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/utils/services/storage.service';
import { TimerService } from '../../../../../utils/components/timer/timer.service';

@Component({
    selector: 'msc-open-write',
    templateUrl: 'open-write.component.html',
})
export class OpenWriteComponent implements OnInit, OnDestroy {
    @Input() question: OpenWriteQuestion;
    @Input() questionSession: OpenWriteQuestionSession;
    @Output() questionSessionChange: EventEmitter<OpenWriteQuestionSession> = new EventEmitter();
    subscription: Subscription;
    content: any;

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.content = this.questionSession.answer.text;
        this.timerInit();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    update(): void {
        this.questionSession.answer.text = this.content;
        this.questionSessionChange.emit(this.questionSession);
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }
}
