import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './utils/services/auth.service';
import { environment } from '../environments/environment';
import { UrlHelper } from './utils/helpers';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.loggedIn()) {
            return true;
        } else {
            if (route.queryParams && route.queryParams.sso_attempt === 1) {
                UrlHelper.navigate({parts: [environment.envVar.CLOUD_URL, 'sso', 'gateway'], params: {portal: route.queryParams.sso_portal, callback_url: UrlHelper.getUrl('player') + state.url}}, '_self');
            } else {
                this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            }
            return false;
        }
    }
}
