<div class="slide slide_presentation">
    <h2 class="heading">
        <span>{{ 'player.scorm.name' | translate }}</span>{{ sectionContent?.title }}
    </h2>

    <div (click)='launchScormCloud(modalFullScreen)' class="card card-background" *ngIf="(!scorm.upload || scorm.upload?.status === 'done') && !url">
        <div class="card__text" style="margin: auto;" *ngIf="!processing">
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="56px" height="56px" viewBox="0 0 24 24" aria-labelledby="videoIconTitle videoIconDesc" stroke="#5b70ff" stroke-width="1.2857142857142858" stroke-linecap="square"
                stroke-linejoin="miter" fill="none" color="#5b70ff">
                <polygon points="18 12 9 16.9 9 7" />
                <circle cx="12" cy="12" r="10" />
            </svg>
        </div>

        <div class="card__text" style="margin: auto;" *ngIf="processing">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <p>{{ 'player.scorm.loading-content' | translate }}</p>
        </div>
    </div>

    <div class="card card-background" *ngIf="url && processing">
        <div class="card__text" style="margin: auto;">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <p>{{ 'player.scorm.retrieve-progress' | translate }}</p>
        </div>
    </div>

    <msc-next-prev-btn></msc-next-prev-btn>
</div>

<ng-template #modalFullScreen let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close(); closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="display: flex; flex-direction: column;">
        <div class="modal-scorm">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="modal-scorm" style="height: 100%;" [ngStyle]="{'display': hideIframe}">
            <iframe *ngIf="url" [src]="url" frameborder="0" width="100%" height="100%" allowfullscreen></iframe>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalFullScreen let-modal>
    <div class="modal-dialog modal-full" *ngIf="modalFullScreen.visible">
        <div class="modal-content">
            <span style="z-index: 1000" (click)="modal.close()" class="button btn-action_close">+</span>

            <div class="modal-scorm media-holder">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div class="modal-scorm media-holder" style="height: 100%;max-height: calc(100% - 8rem);" [ngStyle]="{'display': hideIframe}">
                <iframe *ngIf="url" [src]="url" frameborder="0" width="100%" height="100%" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</ng-template> -->

<app-footer [context]="'scorm'" [contextId]="scorm?.id"></app-footer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
