import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UserProvider } from '../../services/user.provider';
import { AuthService } from '../../services/auth.service';
import { RedirectService } from '../../services/redirect.service';

@Component({
    selector: 'account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.less']
})
export class AccountComponent implements OnInit {
    lang: string;
    currentUser;
    isOpen: boolean = false;
    preview: boolean = false;

    constructor(private router: Router, public userProvider: UserProvider, private auth: AuthService,
                private eRef: ElementRef, private activatedRoute: ActivatedRoute, private redirectService: RedirectService) {}

    ngOnInit() {
        this.currentUser = this.userProvider.currentUser;
        this.lang = this.currentUser.lang;

        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (params['preview']) {
               this.preview = true;
            }
        });
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    navigateProfile(): void {
        this.redirectService.navigate('app', ['account', 'profile']);
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    }
}
