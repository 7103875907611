import { Injectable } from '@angular/core';
import { UrlHelper } from '../helpers';

@Injectable()
export class BackUrlService {
    public backUrl: string;

    setBackUrl(): void {
        if (!sessionStorage.getItem('msc_referrer')) {
            sessionStorage.setItem('msc_referrer', document.referrer);
        }

        this.backUrl = sessionStorage.getItem('msc_referrer');
    }

    getBackUrl(): string {
        sessionStorage.removeItem('msc_referrer');
        return this.backUrl || UrlHelper.getUrl('app');
    }
}
