import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { CategoryQuestion, CategoryQuestionSession, CategoryAnswerChoice, Exercise, Timeline } from '@stuplay';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-flash-cards-question',
    templateUrl: 'flash-cards.component.html'
})
export class MscFlashCardsQuestionComponent implements OnInit, OnChanges  {
    @Input() question: CategoryQuestion;
    @Input() questionSession: CategoryQuestionSession;
    @Input() exercise: Exercise;
    @Input() timeline: Timeline;
    @Output() nextEvent: EventEmitter<string> = new EventEmitter();
    startFlashCards: boolean = false;
    indexSelected: number = 0;
    status = 'untouched';
    checked: boolean = false;

    constructor(private storageService: StorageService) { }

    ngOnInit() {
        const isTouched = this.questionSession.updatedAt && (this.questionSession.updatedAt > this.questionSession.createdAt);
        if (isTouched && !this.questionSession.doneAt) {
            this.status = 'touched'
        }

        if (isTouched && this.questionSession.doneAt) {
            this.status = 'validate'
        }
    }

    ngOnChanges(changes: any) {
        if (changes.questionSession.previousValue) {
            changes.questionSession.previousValue.stopTimer();
            changes.questionSession.previousValue.startTimer(this.storageService.get('company')?.id);
        }
    }

    nextCard(choice: CategoryAnswerChoice, category: string): void {
        choice.category = category;
        const isTheLastCard = this.indexSelected === this.questionSession.answer.choices.length - 1;
        if (this.status !== 'validate') {
            const params = {
                requestCorrection: isTheLastCard,
                company_id: this.storageService.get('company')?.id
            }

            this.questionSession.update(params).subscribe(() => {
                if (isTheLastCard) {
                    this.status = 'validate';
                }
                this.indexSelected += 1;
            });
        } else {
            this.indexSelected += 1;
        }
    }

    get goodAnswerCount(): number {
        return this.questionSession.answer.choices.reduce(function(count, choice) {
            return choice.isCorrect ? count + 1 : count;
        }, 0)
    }

    get answerCount(): number {
        return this.questionSession.answer.choices.length;
    }

    isAnswerSuccess(indexChoice: number, category: string): boolean {
        if (this.questionSession.doneAt) {
          const isCorrect = this.questionSession.answer.choices[indexChoice].isCorrect && category === this.questionSession.answer.choices[indexChoice].category;
          const isNotCorrect = !this.questionSession.answer.choices[indexChoice].isCorrect && category === this.question.content.choices[indexChoice].category;
          return isCorrect || isNotCorrect;
        }
    }

    isAnswerFailed(indexChoice: number, category: string): boolean {
        if (this.questionSession.doneAt) {
            return !this.questionSession.answer.choices[indexChoice].isCorrect && category === this.questionSession.answer.choices[indexChoice].category;
        }
    }

    next(): void {
        this.nextEvent.emit();
    }

    checkAnswer(): void {
        this.status = 'touched';
        this.indexSelected = 0;
        this.checked = true;
        this.startFlashCards = true;
    }

    restart(): void {
        this.status = 'untouched';
        this.indexSelected = 0;
    }

    get getRatio(): number {
        return (this.goodAnswerCount * 100) / this.answerCount;
    }

    changeStartFlashCards(): void {
        this.startFlashCards = !this.startFlashCards;
    }
}
