import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'timeline-delete-content',
    templateUrl: './timeline-delete-content.component.html'
})
export class TimelineDeleteContentComponent {
    @Output() deleteContent = new EventEmitter();
    isOpen: boolean = false;

    onDeleteContent(): void {
        this.deleteContent.emit();
        this.closeSettings();
    }

    openSettings(): void {
        this.isOpen = true;
    }

    closeSettings(): void {
        this.isOpen = false;
    }
}
