import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Note, StuplayConfig } from '@stuplay';

@Component({
    selector: 'msc-note-detail',
    templateUrl: './note-detail.component.html'
})

export class NoteDetailComponent {
    @Input() note: Note;
    @Input() notes: Note[];
    @Output() deleteSelectedNote: EventEmitter<any> = new EventEmitter();
    @Output() editor: EventEmitter<any> = new EventEmitter();

    public options: Object;

    private readonly froalaKey: string = 're1H1qC4C2C6E6C5C4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1G4F1C11A8C2C5F5==';

    constructor(
        private stuplayConfig: StuplayConfig,
    ) { }

    ngOnInit(): void {
        const onChange = function(content: any) {
            this.onChange(content);
        }.bind(this);

        this.options = {
            key: this.froalaKey,
            placeholderText: '',
            events: {
                'contentChanged': function() {
                    onChange(this.html.get(true));
                },
            },
            theme: 'custom',
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable',
                'embedly', 'entities', 'fontFamily', 'fontSize', 'lineBreaker', 'link', 'lists', 'paragraphFormat',
                'quickInsert', 'quote', 'table', 'url', 'wordPaste'],
            toolbarSticky: false,
            toolbarVisibleWithoutSelection: true,
            charCounterCount: false,
            videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
            imageUploadURL: `${this.stuplayConfig.envVar.API_URL}/media?froala=true`,
            imageUploadMethod: 'POST',
            imageMaxSize: 5 * 1024 * 1024,
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            imageManagerLoadURL: `${this.stuplayConfig.envVar.API_URL}/folder/0?froala=true`,
            requestHeaders: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('id_token')
            },
            linkAlwaysBlank: true,
            heightMin: 400
        };
    }

    closeEditor(): void {
        this.editor.emit();
    }

    update(): void {
        this.note.update().subscribe();
    }

    deleteNote(): void {
        this.editor.emit();
        this.deleteSelectedNote.emit(this.note);
    }

    onChange(content: any): void {
        this.note.content = content;
        this.update();
    }
}
