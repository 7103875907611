<div class="slide">
    <div class="course-final">
        <h1> {{ 'player.course-end.congratulations' | translate }}&nbsp;!</h1>
        <h2> {{ 'player.course-end.finished' | translate }}</h2>

        <div class="scores">
            <div class="score">
                <span class="score__ratio">{{ (courseSubscriptionSession.getCampProgress() * 100) }} %</span>
                <span class="score__title"> {{ 'player.course-end.grade' | translate }}</span>
            </div>
        </div>

        <div class="course-final__footer">
            <a class="btn" [href]="backUrl"> {{ 'player.course-end.back' | translate }}</a>
        </div>
    </div>
</div>
