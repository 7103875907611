import { Component, OnDestroy, OnInit } from '@angular/core';
import { Exchange, Timeline, SectionContentSession, TimelineProvider, SectionContent } from '@stuplay';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-exchange',
    templateUrl: 'exchange.component.html'
})
export class ExchangeComponent implements OnInit, OnDestroy {
    exchange: Exchange;
    exchangeSubscription: Subscription;
    sectionContentSession: SectionContentSession;
    sectionContent: SectionContent;
    timeline: Timeline;

    constructor(
        private route: ActivatedRoute,
        private timelineProvider: TimelineProvider,
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.exchangeSubscription = this.route.paramMap.subscribe(() => {
            this.exchange = this.route.snapshot.data?.exchange?.exchange;
            this.sectionContent = this.route.snapshot.data?.exchange;
            this.sectionContentSession = this.route.snapshot.data?.sectionContentSession;
            this.timerInit();
        });
    }

    ngOnDestroy(): void {
        this.sectionContentSession.update(false, this.storageService.get('company')?.id).subscribe();
        this.exchangeSubscription.unsubscribe();
    }

    updateExchange(): void {
        this.sectionContentSession.update(true, this.storageService.get('company')?.id).subscribe();
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
