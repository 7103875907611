import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Section, CourseSubscriptionSession } from '@stuplay';

@Injectable()
export class OrderedService {
    private sections: number[];
    private sessions: CourseSubscriptionSession;

    constructor() {
        this.sections = [];
    }

    setSession(sessions: CourseSubscriptionSession): void {
        this.sessions = sessions;
    }

    setSection(sections: Section[]): void {
        sections.forEach((section, index) => {
            if (index === 0) {
                this.pushSection(section?.id);
            } else {
                let counter = 1;
                for (const content of sections[index - 1].sectionContents) {
                    if (content.context === 'event') {
                        const date = content.event.eventSessions.map((session: any) => session.endAt).filter((endAt: any) => moment(endAt).isAfter(moment(new Date()))).shift();

                        if (!date) {
                            counter++;
                        }
                    }
                }
                const maxScore = parseInt(Number(1 / counter).toPrecision(2), 10);
                if (this.sessions.getSectionProgress(sections[index - 1]) >= maxScore || sections[index - 1].sectionContents.length === 0 || sections[index]?.sectionContents.length === 0) {
                    this.pushSection(section?.id);
                }
            }
        });
    }

    private pushSection(sectionId: number): void {
        if (!this.isSectionExist(sectionId)) {
            this.sections.push(sectionId);
        }
    }

    isSectionExist(sectionId: number): boolean {
        const index = this.sections.findIndex((id) => {
            return id === sectionId;
        });

        return index > -1;
    }
}
