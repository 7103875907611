<div id="sidebar" [ngClass]="{'showSidebar': showSidebar, 'sidebar__external': external, 'showNav': displayNav}">
    <div class="sidebar__course-content" *ngIf="course">
        <!--logo-->
        <div class="logo-container d-flex">
            <!-- logo company -->
            <div class="logo-sidebar" [style.backgroundImage]="'url(' + logo + ')'">
            </div>
            <!--icon arrow-->
            <span>
                <div class="icon-leave-player" id="tooltip2" [title]="'words.leave-course' | translate"
                    (click)="back()">
                    <a>
                        <img src="https://static.myskillcamp.com/images/icon/icon-player/icon-salir.jpg" width="100"
                            style="width: 100%;">
                    </a>
                </div>
            </span>
        </div>

        <!--icon-title press-->
        <div class="course-title d-flex">
            <span>
                <div class="press-player-sidebar">
                </div>
            </span>

            <a class="enlace-sidebar" [routerLink]="['/course', course?.id]" [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge">

                <span [title]="'course.course-presentation' | translate">{{ (course.isTrajectContent ?
                    'course.course-presentation' : 'studio.presentation.template') |
                    translate }}</span>
            </a>
        </div>


        <ol class="sections rounded-list">
            <li *ngFor="let section of course.sections; let index=index" class="section"
                [ngClass]="{'section_open': sectionOpen[index] && !isDisabled(section), 'section__disabled': isDisabled(section)}">

                <div class="section__tooltip tooltip" [attr.data-tooltip]="'player.section.disabled' | translate"
                    *ngIf="isDisabled(section)">
                    <img src="https://static.myskillcamp.com/images/guidance/question-mark-circle.svg">
                </div>

                <div class="list-item-container">
                    <!-- progress -->
                    <!-- Contenedor del círculo de progreso -->
                    <div class="progress-circle-container">
                        <svg class="progress-circle" width="120" height="120" viewBox="0 0 120 120">
                            <!-- Definición del filtro de sombra -->
                            <defs>
                                <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                                    <!-- Primera sombra -->
                                    <feDropShadow dx="0" dy="54" stdDeviation="55" flood-color="rgba(0, 0, 0, 0.25)" />
                                    <!-- Segunda sombra -->
                                    <feDropShadow dx="0" dy="-12" stdDeviation="30" flood-color="rgba(0, 0, 0, 0.12)" />
                                    <!-- Tercera sombra -->
                                    <feDropShadow dx="0" dy="4" stdDeviation="6" flood-color="rgba(0, 0, 0, 0.12)" />
                                    <!-- Cuarta sombra -->
                                    <feDropShadow dx="0" dy="12" stdDeviation="13" flood-color="rgba(0, 0, 0, 0.17)" />
                                    <!-- Quinta sombra -->
                                    <feDropShadow dx="0" dy="-3" stdDeviation="5" flood-color="rgba(0, 0, 0, 0.09)" />
                                </filter>
                            </defs>

                            <!-- Pista del círculo de progreso -->
                            <circle class="progress-circle__track" stroke="#080a2100" stroke-width="5" fill="none"
                                r="52" cx="60" cy="60" />

                            <!-- Barra de progreso con filtro de sombra -->
                            <circle class="progress-circle__bar"
                                [style.stroke-dashoffset]="(329 * (1 - courseSubscriptionSession?.getSectionProgress(section)))"
                                [style.stroke]="getProgressColor(courseSubscriptionSession?.getSectionProgress(section))"
                                stroke-width="8" fill="none" r="52" cx="60" cy="60" filter="#" />
                            <!-- Aplicación del filtro de sombra -->
                        </svg>
                        <!-- num de progreso -->
                        <div class="progress">{{ courseSubscriptionSession.getSectionProgress(section) * 100 | number :
                            '1.0-0' }}%</div>
                    </div>


                    <a id="a" class="section__title enlace-sidebar" [ngClass]="{'disabled': isDisabled(section)}"
                        [routerLink]="['section', section?.id]" queryParamsHandling="merge"
                        [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                        (click)="openSection(section, 'template'); $event.stopPropagation(); $event.preventDefault();">

                        <span style="font-size:1rem;" [title]="section.title" class="title" 
                            [innerHTML]="section.title || ('player.common.untitled' | translate)"></span>

                        <span  class="section__toggle"></span>
                        <!--<span *ngIf="!isDisabled(section) && courseSubscriptionSession.getSectionProgress(section) !== 1" class="section__toggle"></span> -->
                        <!--<span class="check-secondary-title" *ngIf="courseSubscriptionSession.getSectionProgress(section) === 1"></span>-->
                    </a>
                </div>

                <ol
                    [ngStyle]="{'border-left': '2px solid', 'border-image': getVerticalProgressGradient(courseSubscriptionSession.getSectionProgress(section)) + ' 1'}">
                    <li class="section__content"
                        *ngFor="let sectionContent of section?.sectionContents; let index=index"
                        [class.section__scorm]="sectionContent?.context === 'scorm' || sectionContent?.context === 'scormcloud'"
                        [class.section__project]="sectionContent?.context === 'project' || sectionContent?.context === 'selfassessment'"
                        [ngClass]="'section__'+sectionContent?.context">
                        <span>
                            <i class="icon-sub" [ngClass]="getIconesClasses(sectionContent.context)"></i>
                        </span>

                        <a class="enlace-sidebar" *ngIf="sectionContent?.context !== 'exercise'"
                            queryParamsHandling="merge" [routerLink]="navigate(sectionContent, section)"
                            [ngClass]="getSectionContentClasses(sectionContent?.id)" [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{exact:true}" (click)="scroller()">

                            <span [title]="sectionContent.title" class="title"> {{ sectionContent.title || ('player.sidebar.section-content.context-'+sectionContent?.context | translate )}}</span>

                            <span class="check-secondary" [ngClass]="getSectionContentClasses(sectionContent?.id)"></span>
                        </a>



                        <ul *ngIf="sectionContent.context === 'exercise'">
                            <span class="context bold m-flex m-between">
                                {{ sectionContent.exercise.title ? (sectionContent.exercise.title | truncate) :
                                'player.sidebar.section-content.context-'+sectionContent?.context | translate }}

                                <div class="context__item" *ngIf="!sectionContent?.isScorable">
                                    <div class="reset" (click)="openModalReset(sectionContent?.id, modalReset)">
                                        <i class="icon icon-refresh-outline"></i>
                                    </div>
                                </div>
                            </span>

                            <li class="question section__content"
                                *ngFor="let question of sectionContent.exercise?.questions; let qIndex=index">
                                <span>
                                    <i class="icon-question fa fa-question"></i>
                                </span>
                                <a class="enlace-sidebar"
                                    [routerLink]="['section', section?.id, 'exercise', sectionContent?.exercise?.id, 'question', question?.id]"
                                    [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                                    queryParamsHandling="merge" [class]="getClasses(question?.id)">
                                    <span class="title" *ngIf="!question?.title">{{
                                        'player.sidebar.section-content.exercise.question-' + question.type | translate
                                        }}</span>

                                    <span class="title" *ngIf="question?.title"
                                        [innerHTML]="question?.title | clean">
                                    </span>
                                    
                                    <span class="check-secondary-question" *ngIf="question?.title" [class]="getClasses(question?.id)"></span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ol>
            </li>
        </ol>

        <a class="course-title survey-sidebar enlace-sidebar" [routerLink]="['/course', course?.id, 'survey']"
            [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"
            style="margin-top: .66rem !important;" *ngIf="course?.hasSurvey"
            [ngClass]="{'disabled-survey': courseSubscriptionSession?.progress < 100}">
            <span>{{ 'course.survey' | translate }}</span>
        </a>
    </div>
</div>

<ng-template #modalReset let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="m-modal-content">
            {{ 'player.modal.reset-quiz' | translate }}
        </div>
        <div class="m-modal-actions">
            <div>
                <button class="btn btn_color_grey" (click)="modal.close()">
                    {{ 'words.cancel' | translate }}
                </button>
            </div>

            <div class="">
                <button class="btn" (click)="resetQuiz()">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'player.common.validate' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalReset let-modal>
    <span class="btn-action_close" (click)="modal.close()">+</span>

    <div class="m-modal-content">
        {{ 'player.modal.reset-quiz' | translate }}
    </div>

    <div class="m-modal-actions">
        <div class="">
            <button class="btn btn_color_grey" (click)="modal.close()">
                {{ 'words.cancel' | translate }}
            </button>
        </div>

        <div class="">
            <button class="btn" (click)="resetQuiz()">
                <div class="lds-ring" *ngIf="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <span>{{ 'player.common.validate' | translate }}</span>
            </button>
        </div>
    </div>
</ng-template> -->