import { FillInComponent } from './fill-in/fill-in.component';
import { MultipleComponent } from './multiple/multiple.component';
import { OpenWriteComponent } from './open-write/open-write.component';
import { OrderedComponent } from './ordered/ordered.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { TrueFalseComponent } from './true-false/true-false.component';
import { OpenAudioComponent } from './open-audio/open-audio.component';
import { LinkerComponent } from './linker/linker.component';
import { UniqueQuestionChoiceComponent } from './unique/unique.component';

export function questionComponentFactory(type: string): any {
    switch (type) {
        case 'fill-in': {
            return FillInComponent;
        }
        case 'multiple': {
            return MultipleComponent;
        }
        case 'open-write': {
            return OpenWriteComponent;
        }
        case 'ordered': {
            return OrderedComponent;
        }
        case 'procedure': {
            return ProcedureComponent;
        }
        case 'true-false': {
            return TrueFalseComponent;
        }
        case 'open-audio': {
            return OpenAudioComponent;
        }
        case 'linker': {
            return LinkerComponent;
        }
        case 'ucq': {
            return UniqueQuestionChoiceComponent
        }
    }
}
