<!-- <ng-container *ngIf="folder">
    <div class="folder folder_display_block">
        <div class="folder__item folder__item_folder" *ngFor="let item of medias" [ngClass]="{'folder__item_active': (media == item)}">
            <library-item [item]="item" (updateMedia)="onUpdatedMedia($event)"></library-item>
        </div>

        <div class="load-more" [ngClass]="{'animate': processing}">
            <button class="btn btn_color_dark load-more__button btn_round btn_height_small" *ngIf="folder.pagination.currentPage < folder.pagination.lastPage" (click)="loadMore()">
                <span>{{ (!processing ? 'player.load-more' : '') | translate }}</span>
            </button>
            <div class="dot"></div>
        </div>
    </div>
</ng-container> -->


<ng-container *ngIf="folder">
    <div class="folder folder_display_block">
        <ng-container *ngFor="let item of medias">
            <div class="folder__item folder__item_folder" [ngClass]="{'folder__item_active': (media == item)}">
                <library-item [item]="item" (updateMedia)="onUpdatedMedia($event)"></library-item>
            </div>
        </ng-container>
    </div>

    <div class="load-more" [ngClass]="{'animate': processing}">
        <ng-container *ngIf="folder.pagination.currentPage < folder.pagination.lastPage">
            <button class="btn btn_color_dark load-more__button btn_round btn_height_small" (click)="loadMore()">
                <span>{{ (!processing ? 'player.load-more' : '') | translate }}</span>
            </button>
        </ng-container>
    </div>
</ng-container>
