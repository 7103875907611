import { Component } from '@angular/core';
import { CourseSubscriptionSession } from '@stuplay';
import { BackUrlService } from '../../../utils/services/back-url.service';

@Component({
    selector: 'msc-course-end',
    templateUrl: './course-end.component.html'
})
export class CourseEndComponent {
    public courseSubscriptionSession: CourseSubscriptionSession;

    constructor(private backUrlService: BackUrlService) {}

    get backUrl(): string {
        return this.backUrlService.backUrl;
    }
}
