import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { Course } from './course';
import { Instance } from "./instance";
import { getParams } from "../helpers";

@Injectable()
export class CourseProvider {
    defaultLang: string;

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) { }

    getCourses(): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course`);
    }

    getHomeCourses(slug?: string, params?: any): Observable<any> {
        let data = {
            pagination: {},
            courses: []
        };

        if (slug) {
            return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folders/${slug}`, { params: getParams(params) })
                .pipe(map((res: any) => {
                    data.pagination = res.pagination;
                    res.courses.map((course) => data.courses.push(new Course(course, this.http, this.stuplayConfig)));
                    return data;
                }))
        } else {
            return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folders/0`, { params: getParams(params) })
                .pipe(map((res: any) => {
                    data.pagination = res.pagination;
                    res.courses.map((course) => data.courses.push(new Course(course, this.http, this.stuplayConfig)));
                    return data;
                }))
        }
    }

    getSharedCourses(slug: string, params?: any): Observable<any> {
        let response = {
            pagination: {},
            courses: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/company/${slug}/shared-courses`, { params: getParams(params) })
            .pipe(map((data: any) => {
                response.pagination = data.pagination;
                data.courses.map((course) =>
                    response.courses.push(new Course(course, this.http, this.stuplayConfig))
                );
                return response;
            }));
    }

    getSharedCollaborations(params?: any): Observable<any> {
        let response = {
            pagination: {},
            courses: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/collaborations`, { params: getParams(params) })
            .pipe(map((data: any) => {
                response.pagination = data.pagination;
                data.courses.map((course) =>
                    response.courses.push(new Course(course, this.http, this.stuplayConfig))
                );
                return response;
            }));
    }

    getTemplates(perPage?: number, instancesNumber?: number, q?: string, all: number = 0): Observable<any> {
        let data = {
            pagination: {},
            templates: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/courses/folders/0?includes=instances,camps&per_page=${perPage}&instances_per_page=${instancesNumber}&all=${all}`, { params: getParams({ q: q }) })
            .pipe(map((res: any) => {
                data.pagination = res.pagination;
                res.courses.map((course) => data.templates.push(new Course(course, this.http, this.stuplayConfig)));
                return data;
            }));
    }

    getCourse(hash: string, params?: any): Observable<Course> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/create/courses/${hash}`, { params: getParams(params) })
            .pipe(map((data) => new Course(data, this.http, this.stuplayConfig)));
    }

    getCoursePlayer(id: number, params?: any): Observable<Course> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course/${id}`, { params: getParams(params) })
            .pipe(map((data) => new Course(data, this.http, this.stuplayConfig)));
    }

    createCourse(course: any): Observable<Course> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/course`, course)
            .pipe(map((data) => new Course(data, this.http, this.stuplayConfig)));
    }

    createInstance(templateId: number, instance: any, events?: any[]): Observable<any> {
        if (events) {
            instance.events = events;
        }
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${templateId}/instances`, instance)
            .pipe(map((data) => new Instance(data)));
    }

    updateInstance(templateId: number, instance: any, events?: any[]): Observable<any> {
        if (events) {
            instance.events = events;
        }
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${templateId}/instances/${instance?.id}`, instance)
            .pipe(map((data) => new Instance(data)));
    }

    deleteInstance(params?: any): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/instances`, { params: getParams(params) });
    }

    getSpecificTemplate(slug: string, params?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/templates/${slug}`, { params: getParams(params) });
    }

    getTemplateInstances(templateId: number, includes?: string): Observable<any> {
        const including = (includes) ? '?includes=' + includes : '';
        let data = {
            instances: [],
            events: []
        };

        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${templateId}/instances${including}`)
            .pipe(map((response: any) => {
                response.instances.map((instance) => data.instances.push(new Instance(instance)));
                if (response?.events) {
                    response.events.map((event) => data.events.push(event));
                }
                return data;
            }));
    }

    getInstance(templateId: number, instanceId: number, includes?: string): Observable<any> {
        const including = (includes) ? '?includes=' + includes : '';
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${templateId}/instances/${instanceId}${including}`);
    }

    cloneCourse(courses: any[], type: string = 'template'): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/course/duplicate`, { type: type, courses: courses })
            .pipe(map((course) => course));
    }

    publishInstance(instances: number[], camps: number[], settings: any): Observable<any> {
        const publish = {
            courses: instances,
            camps: camps,
            settings: settings
        };

        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/course-camp`, publish);
    }

    resetInstance(instance: any, params?: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${instance.templateId}/instances/${instance?.id}/reset`, params);
    }

    realign(id: number, params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${id}/reset-instances`, params);
    }

    moveTo(id: number, folderId: number): Observable<any> {
        const params = {
            folder_id: folderId
        };

        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/template/${id}/move`, params);
    }

    companyContent(id: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/courses/${id}/shared-settings`, params);
    }

    massiveSubscription(params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/course-subscriptions/mass-import`, params);
    }

    getTemplateMeta(templateId: number): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/course/${templateId}/meta`);
    }

    updateOnDemand(templateId: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/template/${templateId}/on-demand`, params);
    }

    learnersOnDemand(templateId: number, q?: any): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.API_URL}/course/${templateId}/saved`, { params: getParams({ q: q }) });
    }
}
