<div class="modal-header">
    <span></span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <h3 class="heading">
        <span>{{ 'studio.media.library' | translate }}</span> {{ 'studio.media.manage-media' | translate }}
    </h3>
    <nav>
        <div class="nav nav-tabs" role="tablist">
            <button class="nav-link" [ngClass]="{'active': isSelected(1)}" (click)="setTab(1)" type="button" role="tab">{{ 'studio.media.library' | translate }}</button>
            <button class="nav-link" [ngClass]="{'active': isSelected(2)}" (click)="setTab(2)" type="button" role="tab">{{ 'studio.media.import' | translate }}</button>
            <button class="nav-link" [ngClass]="{'active': isSelected(3)}" (click)="setTab(3)" type="button" role="tab">{{ 'studio.media.embed' | translate }}</button>
        </div>
    </nav>
    <div class="tab-content">
        <div [ngSwitch]="tab">
            <div *ngSwitchCase="1">
                <library-folders (updateMedia)="onUpdatedMedia($event)"></library-folders>
            </div>
            <div *ngSwitchDefault>
                <library-upload (updateMedia)="onUpdatedMedia($event)"></library-upload>
            </div>
            <div *ngSwitchCase="3">
                <library-embed (updateMedia)="onUpdatedMedia($event)"></library-embed>
            </div>
        </div>
    </div>
</div>
