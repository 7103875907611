import { Upload } from '../../../../media';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../../../../stuplay-config';
import { cleanObject } from "../../../../helpers";

export class Video {
    upload?: Upload;
    url: string;
    content?: any;
    thumbNail?: string;
    id: number;

    constructor(video: any, protected http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(video);
    }

    extends(project: any): Video {
        Object.assign(this, project);
        return this;
    }

    update(): Observable<Video> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/video/${this.id}`, cleanObject(this)).pipe(map(res => this.extends(res)));
    }
}
