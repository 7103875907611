import { Component, OnDestroy, EventEmitter, OnInit } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ProcedureQuestion, ProcedureQuestionSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-procedure',
    templateUrl: 'procedure.component.html',
    styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit, OnDestroy {
    question: ProcedureQuestion;
    questionSession: ProcedureQuestionSession;
    questionSessionChange: EventEmitter<ProcedureQuestionSession> = new EventEmitter();
    subscription: Subscription;

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    getCorrectAnswer(answerChoice: string): number {
        return this.question.content.choices.findIndex(({ choice }) => choice === answerChoice) + 1;
    }

    isAnswerSuccess(choice: any): boolean {
        return this.questionSession.doneAt && choice.isCorrect;
    }

    isAnswerFailed(choice: any): boolean {
        return this.questionSession.doneAt && !choice.isCorrect;
    }

    onChange(choice?: any, index?: number): void {
        index = index + 1;
        choice.position = index;
        this.questionSessionChange.emit(this.questionSession);
    }

    cursorDisable(): string {
        if (this.questionSession.doneAt) {
            return 'not-allowed';
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }

    dropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.onChange(event.item, event.currentIndex);
    }
}
