import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TimerService } from './timer.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html'
})

export class TimerComponent {
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Input() timerService: TimerService;
    @ViewChild('modalInactivity') modalInactivity: ElementRef;

    public subscription: Subscription = new Subscription();

    constructor(
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.subscription = this.timerService.inactivity.subscribe(() => {
            const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom', centered: true };
            this.modalService.open(this.modalInactivity, options);
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    modalClose(): void {
        this.close.emit(true);
        this.modalService.dismissAll();
    }
}
