import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProvider } from './utils/services/user.provider';
import { BackUrlService } from './utils/services/back-url.service';
import { Title } from '@angular/platform-browser';
import { ToastService } from './utils/components/toast/toast.service';
import {
    Section, SectionContent, Event, Project, Question, Course, Exercise, CheatSheet, Media, CampProvider,
    ScormModule, Lti, CourseProvider, SectionContentSession, QuestionSession, Camp, Video, Exchange, Certificate,
    CourseSubscriptionSession, CourseSubscriptionSessionProvider, SelfAssessment, StatisticsProvider, CompanyProvider
} from '@stuplay';
import { NavigationProvider } from './utils/services/navigation.provider';
import { Observable, throwError, of, lastValueFrom } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { DataHelper } from './utils/helpers';
import { TranslationService } from './utils/services/translation.service';
import { StorageService } from './utils/services/storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserResolver implements Resolve<any> {
    constructor(
        private userProvider: UserProvider,
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.userProvider.getCurrentUser();
    }
}

@Injectable()
export class CampResolver implements Resolve<Camp[]> {
    constructor(private campProvider: CampProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Camp[]> {
        return this.campProvider
            .getCamps()
            .pipe(
                map((camps) => camps)
            );
    }
}

@Injectable()
export class CourseResolver implements Resolve<Course> {
    private company;
    public theme: any;
    constructor(
        private courseProvider: CourseProvider,
        private navigation: NavigationProvider,
        private titleService: Title,
        private translate: TranslationService,
        private toastService: ToastService,
        private router: Router,
        private backUrlService: BackUrlService,
        private statisticsProvider: StatisticsProvider,
        private storageService: StorageService,
        private companyProvider: CompanyProvider,
        private cookieService: CookieService
    ) {
        this.company = this.storageService.get('company');
    }

        // calculos colors
     // Método para ajustar la luminosidad del color en HSL
     private adjustLightness(color: string, percent: number): string {
        const hex = color.replace('#', '');
        const num = parseInt(hex, 16);

        let r = (num >> 16) & 0xff;
        let g = (num >> 8) & 0xff;
        let b = num & 0xff;

        // Ajustar la luminosidad sumando el porcentaje a cada componente de color
        r = Math.min(255, Math.max(0, r + Math.round((255 - r) * percent / 100)));
        g = Math.min(255, Math.max(0, g + Math.round((255 - g) * percent / 100)));
        b = Math.min(255, Math.max(0, b + Math.round((255 - b) * percent / 100)));

        return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0').toUpperCase()}`;
    }

    // Método para establecer el tema con colores derivados
    public async setTheme(): Promise<void> {
        const companyData: any = await lastValueFrom(this.companyProvider.getCompany(this.cookieService.get('current_company'), { includes: 'roles,experience' }));
        this.theme = companyData;
        const primaryColor = (this.theme.experience.colorTemplate.primaryColor === "#ffffff" || this.theme.experience.colorTemplate.primaryColor === "#fff")? "#949494" : this.theme.experience.colorTemplate.primaryColor;
        const secondaryColor = (this.theme.experience.colorTemplate.secondaryColor === "#ffffff" || this.theme.experience.colorTemplate.secondaryColor === "#fff")? "#949494" : this.theme.experience.colorTemplate.secondaryColor;
       
        // Generar colores derivados del color base
        const primary100 = this.adjustLightness(primaryColor, 95); // Muy claro
        const primary200 = this.adjustLightness(primaryColor, 60); 
        const primary300 = this.adjustLightness(primaryColor, 30);
        const primary400 = this.adjustLightness(primaryColor, 10);
        const primary500 = primaryColor; // Color base
        const primary600 = this.adjustLightness(primaryColor, -10); 
        const primary700 = this.adjustLightness(primaryColor, -30);
        const primary800 = this.adjustLightness(primaryColor, -60);
        const primary900 = this.adjustLightness(primaryColor, -80); 

        // Generar colores derivados del color secundario
        const secondary100 = this.adjustLightness(secondaryColor, 95); // Muy claro
        const secondary200 = this.adjustLightness(secondaryColor, 60); 
        const secondary300 = this.adjustLightness(secondaryColor, 30);
        const secondary400 = this.adjustLightness(secondaryColor, 10);
        const secondary500 = secondaryColor; // Color base
        const secondary600 = this.adjustLightness(secondaryColor, -10); // Oscuro
        const secondary700 = this.adjustLightness(secondaryColor, -30);
        const secondary800 = this.adjustLightness(secondaryColor, -60);
        const secondary900 = this.adjustLightness(secondaryColor, -80); // Muy oscuro

        // Asignar las variables CSS
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--background-color', primaryColor);

        document.documentElement.style.setProperty('--primary-100', primary100);
        document.documentElement.style.setProperty('--primary-200', primary200);
        document.documentElement.style.setProperty('--primary-300', primary300);
        document.documentElement.style.setProperty('--primary-400', primary400);
        document.documentElement.style.setProperty('--primary-500', primary500);
        document.documentElement.style.setProperty('--primary-600', primary600);
        document.documentElement.style.setProperty('--primary-700', primary700);
        document.documentElement.style.setProperty('--primary-800', primary800);
        document.documentElement.style.setProperty('--primary-900', primary900);

        document.documentElement.style.setProperty('--secondary-100', secondary100);
        document.documentElement.style.setProperty('--secondary-200', secondary200);
        document.documentElement.style.setProperty('--secondary-300', secondary300);
        document.documentElement.style.setProperty('--secondary-400', secondary400);
        document.documentElement.style.setProperty('--secondary-500', secondary500);
        document.documentElement.style.setProperty('--secondary-600', secondary600);
        document.documentElement.style.setProperty('--secondary-700', secondary700);
        document.documentElement.style.setProperty('--secondary-800', secondary800);
        document.documentElement.style.setProperty('--secondary-900', secondary900);
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Course> {
        const params = {
            preview: route.queryParams.preview ? 1 : 0,
            direct_play: route.queryParams.direct_play ? 1 : 0,
            company_id: route.queryParams.company_id || this.company ? this.company?.id : null,
            isFromLearningPath: !!route.queryParams.isFromLearningPath ? 1 : 0
        };

        return this.courseProvider
            .getCoursePlayer(parseInt(route.paramMap.get('courseId'), 10), params)
            .pipe(
                map((course) => {
                    this.backUrlService.setBackUrl();

                    const sections = course.sections;
                    for (const section of sections) {
                        section.sectionContents = section.sectionContents.filter((sectionContent) => !sectionContent.exercise || sectionContent.exercise.questions?.length > 0);
                    }

                    this.navigation.currentCourse = course;
                    course.title ? this.titleService.setTitle(course.title) : this.titleService.setTitle(this.translate.instant('player.untitled-course'));
                    this.storageService.getAndSet('course', course);
                    if (!params.preview) {
                        this.statisticsProvider.launchContent(course?.id, {
                            context: "course"
                        }).subscribe(() => {
                            this.setTheme();
                        });
                    }

                    if (!params.preview) {
                        this.statisticsProvider.launchContent(course?.id, {
                            context: "course"
                        }).subscribe(() => {
                            this.setTheme();
                        });
                    }
                    this.setTheme();
                    return course;
                }),
                catchError((error: any) => {
                    this.router.navigate(['']);
                    if (error.status === 403) {
                        this.toastService.push('forbidden', 'error', 5000, 'camp');
                    } else if (error.status === 404) {
                        this.toastService.push('course-camp-not-found', 'error', 5000);
                    } else {
                        this.toastService.push('error-occurred', 'error', 5000);
                    }
                    return throwError(error);
                })
            )
    }
}

@Injectable()
export class CourseSubscriptionSessionResolver implements Resolve<CourseSubscriptionSession> {
    constructor(private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider, private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<CourseSubscriptionSession> {
        return this.courseSubscriptionSessionProvider
            .getOrCreateCourseSubscriptionSession(parseInt(route.paramMap.get('courseId'), 10), route.queryParams.preview, route.queryParams.direct_play)
            .pipe(
                map((courseSubscriptionSession: any) => {
                    this.navigation.currentCourseSubscriptionSession = courseSubscriptionSession;
                    return courseSubscriptionSession;
                }),
                catchError((error: any) => {
                    window.location.href = document.referrer;
                    return throwError(error);
                })
            );
    }
}

@Injectable()
export class SectionResolver implements Resolve<Section> {
    section: Observable<Section>;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Section> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        if (route.parent.data['course']) {
            const section = route.parent.data['course'].sections.find(({ id }) => id === +route.paramMap.get('sectionId'));
            this.navigation.currentSection = section;
            return of(section);
        } else {
            return of(null);
        }
    }
}

@Injectable()
export class ExerciseResolver implements Resolve<Exercise> {
    section: Section;
    exercise: Exercise;

    resolve(route: ActivatedRouteSnapshot): Observable<Exercise> {
        this.section = route.parent.data['section'];

        for (const data of this.section.sectionContents) {
            if (data.context === 'exercise' && data?.exercise?.id === +route.paramMap.get(data.context + 'Id')) {
                this.exercise = data['exercise'];
            }
        }
        return of(this.exercise);
    }
}

@Injectable()
export class QuestionResolver implements Resolve<Question> {
    section: Section;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Question> {
        this.section = route.parent.parent.data['section'];

        for (const data of this.section.sectionContents) {
            if (data.context === 'exercise' && data?.exercise?.id === +route.paramMap.get(data.context + 'Id')) {
                for (const question of data.exercise.questions) {
                    if (question?.id === +route.paramMap.get('questionId')) {
                        this.navigation.currentSectionContent = data;
                        this.navigation.currentQuestion = question;
                        return of(question)
                    }
                }
            }
        }
    }
}

@Injectable()
export class SectionContentSessionResolver implements Resolve<SectionContentSession> {
    private courseSubscriptionSession: CourseSubscriptionSession;
    private section: Section;

    constructor(private statisticsProvider: StatisticsProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContentSession> {
        this.courseSubscriptionSession = route.parent.parent.data['courseSubscriptionSession'];
        this.section = route.parent.data['section'];
        const sectionContent = this.section.sectionContents.find((sectionContent: any) => {
            return sectionContent[DataHelper.changeCase(sectionContent.context, 'camel')]?.id === +route.paramMap.get(`${DataHelper.changeCase(sectionContent.context, 'camel')}Id`)
        });
        if (sectionContent) {
            let preview = route.queryParams.preview ? 1 : 0;
            return this.courseSubscriptionSession.getOrCreateSectionContentSession(sectionContent?.id)
                .pipe(
                    switchMap((sectionContentSession: any) => {
                        if (!preview) {
                            this.statisticsProvider.launchActivity(sectionContent?.id).subscribe();
                        }
                        return of(sectionContentSession)
                    })
                )
        } else {
            return throwError('Not Found');
        }
    }
}

@Injectable()
export class QuestionSessionResolver implements Resolve<QuestionSession> {
    sectionContentSession: SectionContentSession;

    resolve(route: ActivatedRouteSnapshot): Observable<QuestionSession> {
        this.sectionContentSession = route.parent.data['sectionContentSession'];
        return this.sectionContentSession.getOrCreateQuestionSession(+route.paramMap.get('questionId'));
    }
}

@Injectable()
export class MediaResolver implements Resolve<SectionContent> {
    media: Media;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'media' && data?.media?.id === +route.paramMap.get(data.context + 'Id') && data?.id === +route.paramMap.get('contentId')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.media = data['media'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class VideoResolver implements Resolve<SectionContent> {
    video: Video;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'video' && data?.video?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.video = data['video'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class CheatSheetResolver implements Resolve<SectionContent> {
    cheat: CheatSheet;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'cheatsheet' && data?.cheatsheet?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.cheat = data['cheatsheet'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class ScormResolver implements Resolve<SectionContent> {
    scorm: ScormModule;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'scorm' && data?.scorm?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.scorm = data['scorm'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class ScormCloudResolver implements Resolve<SectionContent> {
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];

        for (const data of this.section.sectionContents) {
            if (data.context === 'scormcloud' && data?.scormcloud?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class ExternalLInkResolver implements Resolve<SectionContent> {
    lti: Lti;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data.section;

        for (const sectionContent of this.section.sectionContents) {
            if (sectionContent.context === 'external_link' && sectionContent.externalLink.id === parseInt(route.paramMap.get('externalLinkId'), 10)) {
                this.navigation.currentSectionContent = sectionContent;
                this.sectionContent = sectionContent;
                this.lti = sectionContent.externalLink;
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class EventResolver implements Resolve<SectionContent> {
    event: Event;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'event' && data?.event?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.event = data['event'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class ProjectResolver implements Resolve<SectionContent> {
    project: Project;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'project' && data?.project?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.project = data['project'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class SelfAssessmentResolver implements Resolve<SectionContent> {
    selfAssessment: SelfAssessment;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'selfassessment' && data?.selfassessment?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.selfAssessment = data['selfassessment'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class ExchangeResolver implements Resolve<SectionContent> {
    exchange: Exchange;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'exchange' && data?.exchange?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.exchange = data['exchange'];
            }
        }
        return of(this.sectionContent);
    }
}

@Injectable()
export class CertificateResolver implements Resolve<SectionContent> {
    certificate: Certificate;
    section: Section;
    sectionContent: SectionContent;

    constructor(private navigation: NavigationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SectionContent> {
        this.navigation.currentSectionContent = null;
        this.navigation.currentQuestion = null;
        this.section = route.parent.data['section'];
        for (const data of this.section.sectionContents) {
            if (data.context === 'certificate' && data?.certificate?.id === +route.paramMap.get(data.context + 'Id')) {
                this.navigation.currentSectionContent = data;
                this.sectionContent = data;
                this.certificate = data['certificate'];
            }
        }
        return of(this.sectionContent);
    }
}
