<header>
    
    <div class="show-sidebar d-flex" *ngIf="displaySidebar">
        <img *ngIf="!showSidebar" class="menu-on" src="https://static.myskillcamp.com/images/icon/icon-player/icon-menu-2.png" (click)="toggleSidebar()" style="width: 70px;">
        <img *ngIf="showSidebar" class="menu-of" src="https://static.myskillcamp.com/images/icon/icon-player/icon-close-2.svg" (click)="toggleSidebar()">
    </div>
    <div class="header__item course">
        <span class="course__name">
            <span *ngIf="isFromLearningPath">{{ 'words.journey' | translate }} :</span>
            {{ course?.title || ('player.untitled-course' | translate) }} <span *ngIf="isFromLearningPath">- {{ course?.template?.title || ('player.untitled-course' | translate) }}</span>
            <span *ngIf="isFromLearningPath && course.sections[0].sectionContents[0]">- {{ 'player.sidebar.section-content.context-' + course.sections[0].sectionContents[0]?.context | translate }}</span>
        </span>
    </div>

    <div class="header__item header__item--head d-none"
        *ngIf="(courseSubscriptionSession.getSectionProgress(course?.sections[0]) * 100) > 99">
        <a class="btn btn_color_green btn--header" [routerLink]="['/course', course?.id, 'results']">
            <span>{{ 'player.course.results' | translate }}</span>
        </a>
    </div>

    <div class="header__item header__item--refresh" *ngIf="preview">
        <div class="refresh tooltip" [attr.data-tooltip]="'player.reset-session' | translate"
            (click)="resetCurrentSubscriptionSession()">
            <i class="icon icon-refresh-outline"></i>
        </div>
    </div>

    <div id="tooltip" [title]="'player.course.results' | translate " class="header__item header__item--head "
        *ngIf="(courseSubscriptionSession.getSectionProgress(course?.sections[0]) * 100) > 99">
        
        <a class=" btn--header" [routerLink]="['/course', course?.id, 'results']">
            <img class="menu-result" src="https://webservices.griky.co/resources/img/assets/msc/icon-result-3.png" >
            <!-- <span>{{ 'player.course.results' | translate }}</span> -->
        </a>
    </div>

    <div  class="header__items">
        <div class="header__item account">
            <div id="divisor"></div>
            <account></account>
        </div>

        <!--icon back d-none-->
        <div class="header__item d-none" (click)="back()">
            <a class="leave-player"></a>
        </div>
    </div>

   
</header>



