<div>
    <ng-container *ngIf="question.param.type !== 'none'; else altTemplate">
        <div>
            <ng-container *ngFor="let answerChoice of questionSession.answer.choices; let choiceIndex = index; trackBy: trackByFn">
                <div class="choices"
                    [ngStyle]="{'cursor': cursorDisable()}"
                    [class.answer_success]="isAnswerSuccess(answerChoice)"
                    [class.answer_failed]="isAnswerFailed(answerChoice)">
                    <div class="words" cdkDropListGroup>
                        <ng-container *ngFor="let word of answerChoice.choice; let i = index">
                            <div cdkDropList
                                cdkDropListOrientation="horizontal"
                                [cdkDropListData]="{word: word, index: i}"
                                (cdkDropListDropped)="dropped($event, answerChoice.choice)">
                                <span cdkDrag [cdkDragDisabled]="questionSession.doneAt">{{ word }}</span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="correction" *ngIf="isAnswerFailed(answerChoice)">
                        {{ 'word.correct-answer' | translate}}: {{ question.content.choices[choiceIndex].choice }}
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #altTemplate>
        <div>
            <ng-container *ngFor="let answerChoice of questionSession.answer.choices; let choiceIndex = index;">
                <div class="choices"
                    [class.answer_success]="isAnswerSuccess(answerChoice)"
                    [class.answer_failed]="isAnswerFailed(answerChoice)">
                    <div class="hints">
                        <span *ngFor="let word of answerChoice.statement">{{word}}&nbsp;</span>
                    </div>
                    <input type="text" class="answer" [(ngModel)]="answers[choiceIndex]" (blur)="onChange(choiceIndex)">
                    <div class="correction" *ngIf="isAnswerFailed(answerChoice)">
                        {{ 'word.correct-answer' | translate}}: {{ question.content.choices[choiceIndex].choice }}
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
</div>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<!-- // old drag code -->
<!-- <div class="choices"
     *ngFor="let answerChoice of questionSession.answer.choices; let choiceIndex = index; trackBy: trackByFn"
     [ngStyle]="{'cursor': cursorDisable()}"
     [class.answer_success]="isAnswerSuccess(answerChoice)"
     [class.answer_failed]="isAnswerFailed(answerChoice)">
    <div class="words"
         dnd-sortable-container
         [sortableData]="answerChoice.choice"
         [dropZones]="[choiceIndex]">
    <span
        *ngFor="let word of answerChoice.choice; let i = index"
        dnd-sortable
        dnd-draggable
        [dropZones]="[choiceIndex]"
        [sortableIndex]="i"
        [dragEnabled]="!questionSession.doneAt"
        [dropEnabled]="!questionSession.doneAt"
        [dragData]="word"
        (onDragEnd)="onChange()">
        {{ word }}
    </span>
  </div>
  <div class="correction" *ngIf="isAnswerFailed(answerChoice)">
      {{ 'word.correct-answer' | translate}}: {{ question.content.choices[choiceIndex].choice }}
  </div>
</div> -->
