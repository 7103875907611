import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { LibraryModalComponent } from './library-modal.component';
import { LibraryItemComponent } from './library-item.component';
import { LibraryFoldersComponent } from './library-folders.component';
import { LibraryUploadComponent } from './library-upload.component';
import { LibraryEmbedComponent } from './library-embed.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TranslationService } from '../../services/translation.service';
import { StuplayModule } from '@stuplay';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        StuplayModule.forRoot(),
        NgxUploaderModule,
        Angular2PromiseButtonModule.forRoot(),
    ],
    declarations: [
        LibraryModalComponent,
        LibraryItemComponent,
        LibraryFoldersComponent,
        LibraryUploadComponent,
        LibraryEmbedComponent,
        TranslatePipe,
    ],
    exports: [
        TranslatePipe,
        LibraryModalComponent,
        LibraryItemComponent,
        LibraryFoldersComponent,
        LibraryUploadComponent,
        LibraryEmbedComponent,
    ],
    providers: [
        TranslationService
    ]

})

export class LibraryModule { }
