import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CheatSheet, SectionContent, SectionContentSession } from '@stuplay'
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TimerService } from '../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-cheatsheet',
    templateUrl: './cheatsheet.component.html'
})
export class CheatSheetComponent implements OnInit, OnDestroy {
    @Input() cheat: CheatSheet;

    public loading: boolean = false;
    public cheatSheetSubscription: Subscription;
    public sectionContent: SectionContent;
    public sectionContentSession: SectionContentSession;

    constructor(
        private route: ActivatedRoute,
        public timerService: TimerService,
        private storageService: StorageService,
        private modalService: NgbModal,
    ) {
        this.cheatSheetSubscription = route.paramMap.subscribe(() => {
            this.cheat = this.route.snapshot.data.cheatsheet.cheatsheet;
            this.sectionContent = this.route.snapshot.data.cheatsheet;
            this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
            this.timerInit();
        });
    }

    ngOnInit() {
        this.sectionContent = this.route.snapshot.data.cheatsheet;
        this.cheat = this.route.snapshot.data.cheatsheet.cheatsheet;
        this.sectionContentSession = this.route.snapshot.data.sectionContentSession;
    }

    ngOnDestroy(): void {
        this.cheatSheetSubscription.unsubscribe();
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    openModal(content: any) {
        const options: NgbModalOptions = { size: 'lg', backdropClass: 'backdrop-custom' };
        this.modalService.open(content, options);
    }

    activityFinished() {
        this.loading = true;
        this.sectionContentSession
            .update(true, this.storageService.get('company')?.id)
            .pipe(finalize(() => this.loading = false))
            .subscribe();
    }

    timerInit(): void {
        this.timerService.setTimer(this.sectionContentSession, 'content');
    }
}
