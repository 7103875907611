import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { StuplayConfig, Media, OpenAudioQuestion, QuestionSession } from '@stuplay';

const RecordRTC = require('recordrtc/RecordRTC.min');
const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;

@Component({
    selector: 'msc-open-audio',
    templateUrl: 'open-audio.component.html',
})

export class OpenAudioComponent {
    @Input() media: Media;
    @Input() question: OpenAudioQuestion;
    @Input() questionSession: QuestionSession;
    @Output() questionSessionChange: EventEmitter<QuestionSession> = new EventEmitter();
    @Output() updateMedia = new EventEmitter();
    @ViewChild('audio', { static: false }) audio: any;

    public audioRecordedSafeURL = [];
    public promiseSetBySomeAction: any;
    public recordRTC: any;
    public stream: MediaStream | MediaSource | Blob;

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private stuplayConfig: StuplayConfig,
    ) {
        this.question = this.route.snapshot.data.question;
    }

    ngAfterViewInit(): void {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        audio.muted = false;
        audio.controls = true;
        audio.autoplay = false;
    }

    blobToFile(theBlob: Blob, fileName: string): File {
        const blob: Blob = theBlob;
        const file = new File([blob], fileName, { type: 'audio/mp3' });
        return file;
    }

    onToggleControls(): void {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        audio.muted = !audio.muted;
        audio.autoplay = !audio.autoplay;
    }

    onStartRecording(): void {
        const mediaConstraints = { audio: true };
        navigator.mediaDevices
            .getUserMedia(mediaConstraints)
            .then(
                (stream: any) => this.onStartRecordingSuccess(stream),
                () => this.onStartRecordingError(),
            );
    }

    onStartRecordingSuccess(stream: any): void {
        const options = { mimeType: 'audio/mp3', recorderType: StereoAudioRecorder };
        const audio: HTMLAudioElement = this.audio.nativeElement;
        audio.src = window.URL.createObjectURL(stream as any);
        this.stream = stream;
        this.recordRTC = RecordRTC(stream, options);
        this.recordRTC.startRecording();
        this.onToggleControls();
    }

    onStartRecordingError(): void {
        return;
    }

    onStopRecording(): void {
        const recordRTC = this.recordRTC;
        const stream: any = this.stream;
        recordRTC.stopRecording(this.onProcessAudio.bind(this));
        stream.getAudioTracks().forEach((track: any) => track.stop());
    }

    onProcessAudio(audioVideoWebMURL: any): void {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        const recordRTC = this.recordRTC;
        const recordedBlob = recordRTC.getBlob();
        audio.src = audioVideoWebMURL;
        this.audioRecordedSafeURL.push(this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(recordedBlob)));
        this.onToggleControls();
        this.onSend(this.blobToFile(recordedBlob, 'sound'));
    }

    onDownload(): void {
        this.recordRTC.save('audio.mp3');
    }

    onUpload(): void {
        return;
    }

    onSend(src: any): void {
        const formData = new FormData();
        formData.append('file', src, 'sound.mp3');
        formData.append('fileType', 'audio/mpg');

        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${this.stuplayConfig.envVar.API_URL}/media`, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
        xhr.send(formData);
    }

    onUpdatedMedia(media: any) {
        this.updateMedia.emit(media);
    }
}
