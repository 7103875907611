import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Comment } from './comment/comment';
import { StuplayConfig } from '../../stuplay-config';
import { Account } from '../../account/account';
import { cleanObject } from '../../helpers';

export class Post {
    id: number;
    timelineId: number;
    account: Account;
    comments: Comment[];
    content: any;
    created_at: any;

    constructor(post: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(post);
    }

    extends(post: any) {
        Object.assign(this, post);
        this.comments = post.comments.map((comment: any) => new Comment(comment, this.http, this.stuplayConfig)).reverse();
        return this;
    }

    update(): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/timeline/${this.id}`, cleanObject(this));
    }

    addComment(postId: number, params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/timeline/${postId}/content`, params)
            .pipe(map(res => {
                const comment = new Comment(res, this.http, this.stuplayConfig);
                this.comments.push(comment);
                return this;
            }));
    }

    deleteComment(id: number): Observable<any> {
        const selectedCommentIndex = this.comments.findIndex(comment => comment?.id === id);
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/timeline/comment/${id}`)
            .pipe(map(() => {
                this.comments.splice(selectedCommentIndex, 1);
                return this;
            }));
    }
}
