import {
    TrueFalseAnswer,
    OpenWriteAnswer,
    MultipleAnswer,
    LinkerAnswer,
    CategoryAnswer,
    FillInAnswer,
    OrderedAnswer,
    ProcedureAnswer,
    UniqueAnswer
} from './answer/answer';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, interval } from 'rxjs';
import { map, timeInterval } from 'rxjs/operators';
import { StuplayConfig } from "../../../stuplay-config";
import {
    TrueFalseQuestion,
    OpenWriteQuestion,
    MultipleQuestion,
    LinkerQuestion,
    CategoryQuestion,
    FillInQuestion,
    OrderedQuestion,
    ProcedureQuestion,
    UniqueQuestion
} from '../../../course';
import { cleanObject } from "../../../helpers";
import { Account } from '../../../account/account';

export class QuestionSession {
    id: number;
    questionId: number;
    account: Account;
    sessionToken: string;
    isCorrect: boolean;
    attempt: number;
    score: number;
    type: string;
    createdAt: number;
    updatedAt: number;
    doneAt?: number;
    correctedAt?: number;
    durationGlobal = 0;
    durationBeforeAnswering = 0;
    hintClick?: number;
    hintDuration?: number;
    answer?: any;
    timerSubscription?: Subscription;
    comment:string;
    ratio?: number;
    companyId?: number;
    email?: boolean = true;

    constructor(question: any, public http: HttpClient, public stuplayConfig: StuplayConfig) {
        this.extends(question);
    }

    extends(question:any) {
        Object.assign(this, question);
        return this;
    }

    getScore(): number {
        return this.ratio; // TODO: play with scoreMax or evenBetter check isCorrect inside the answer.
    }

    startTimer() {
        this.timerSubscription = interval(1000)
            .pipe(timeInterval())
            .subscribe(() => {
                if (!this.updatedAt) { this.durationBeforeAnswering += 1; }
                this.durationGlobal += 1;
            });
    }

    stopTimer() {
        this.timerSubscription.unsubscribe();
        this.update();
    }

    update(params?: any): Observable<QuestionSession> {
        const payload = this;
        payload.timerSubscription = null;
        let requestCorrection = !!params?.requestCorrection;
        let forceRefresh = !!params?.forceRefresh;
        payload.email = params?.notifyByMail === false ? false : true;
        payload.companyId = params?.company_id;
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/session/question-sessions/${this.id}?requestCorrection=${requestCorrection}&forceRefresh=${forceRefresh}`, cleanObject(payload))
            .pipe(map(res => this.extends(res)));
    }


    isDisable(tryMax: number): boolean {
        return (this.attempt === tryMax && !!this.correctedAt) || this.isCorrect;
    }
}


export class TrueFalseQuestionSession extends QuestionSession {
    question?: TrueFalseQuestion;
    answer: TrueFalseAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class OpenWriteQuestionSession extends QuestionSession {
    question?: OpenWriteQuestion;
    answer: OpenWriteAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class MultipleQuestionSession extends QuestionSession {
    question?: MultipleQuestion;
    answer: MultipleAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class LinkerQuestionSession extends QuestionSession {
    question?: LinkerQuestion;
    answer: LinkerAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class CategoryQuestionSession extends QuestionSession {
    question?: CategoryQuestion;
    answer: CategoryAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class FillInQuestionSession extends QuestionSession {
    question?: FillInQuestion;
    answer: FillInAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class OrderedQuestionSession extends QuestionSession {
    question?: OrderedQuestion;
    answer: OrderedAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class ProcedureQuestionSession extends QuestionSession {
    question?: ProcedureQuestion;
    answer: ProcedureAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}

export class UniqueQuestionSession extends QuestionSession {
    question?: UniqueQuestion;
    answer: UniqueAnswer;
    constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
        super(question, http, stuplayConfig);
    }
}
