import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from '../../../../../media';
import { StuplayConfig } from '../../../../../stuplay-config';
import { factoryQuestion } from './question.factory';
import { cleanObject } from '../../../../../helpers';

export class Question {
    id: number;
    title: string;
    description: string;
    type: string;
    position: number;
    isValid: boolean;
    hint: string;
    hintMedia: Media;
    feedbackDefault: string;
    feedbackDefaultMedia: Media;
    feedbackWrongAnswer: string;
    feedbackWrongAnswerMedia: Media;
    scoreMax: number;
    tryMax: number;
    timer: number;
    media: Media;
    ratio?: number;

    constructor(question: any, protected http: HttpClient, protected stuplayConfig: StuplayConfig) {
        this.extends(question);
    }

    extends(question: any): Question {
        Object.assign(this, question);
        return this;
    }

    postAnswer(answer: any): Observable<any> {
        return this.http.post('', answer);
    }

    update(questionType: string): Observable<any> {
        this.beforeSave();
        let baseObs: Observable<any>;
        if (this.id) {
            baseObs = this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/question/${this.id}`, cleanObject(this))
        } else {
            baseObs = this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/question`, cleanObject(this));
        }

        return baseObs.pipe(map((question) => factoryQuestion(questionType, question, this.http, this.stuplayConfig)),
            map((response) => { return response }));
    }

    delete(): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/question/${this.id}`).pipe(map(() => null));
    }

    protected beforeSave(): void {
        //
    }
}
