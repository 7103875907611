import { Question } from '../question';
import { ProcedureAnswer } from '../../../../../../course-camp-session';
import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../../../stuplay-config';

export class ProcedureQuestion extends Question {
  content: ProcedureQuestionContent = new ProcedureQuestionContent(); //(stringify);
  correction? : ProcedureAnswer;

  constructor(question: any, http: HttpClient, stuplayConfig: StuplayConfig) {
    super(question, http, stuplayConfig);
    this.extends(question);
  }

  extends(question: any): ProcedureQuestion {
    Object.assign(this, question);

    if (!this.content) {
      this.content = new ProcedureQuestionContent();
      this.content.addChoice();
      this.content.addChoice();
      this.content.addChoice();
      this.content.addChoice();
    }
    if (question.content) {
      if (this.content instanceof ProcedureQuestionContent) {
        this.content.extends(question.content);
      } else {
        this.content = new ProcedureQuestionContent(question.content);
      }
    }
    return this;
  }
  protected beforeSave(): void {
    this.content.choices.map((choice: ProcedureQuestionChoice, index) => choice.pos = index + 1);

    super.beforeSave();
  }

// getCorrection(answer): Observable<ProcedureAnswer> {
  //   return this.postAnswer(answer)
  //   .map((correction: ProcedureAnswer) => {
  //     this.correction = correction;
  //     return correction;
  //   });
  // }


}

export class ProcedureQuestionContent {
  choices: ProcedureQuestionChoice[] = [];

  constructor(data: any = {}) {
    this.extends(data);
  }

  extends(data: any = {}): ProcedureQuestionContent {
    Object.assign(this, data);

    if (data.choices) {
      this.choices = [];
      data.choices.map((choice) => this.choices.push(new ProcedureQuestionChoice(choice)));
    }
    return this;
  }

  addChoice(): ProcedureQuestionChoice {
    const newChoice = new ProcedureQuestionChoice();
    newChoice.choice = '';
    // newChoice.pos = (this.choices.length + 1)
    this.choices.push(newChoice);
    return newChoice;
  }

  removeChoice(index: number) {
    this.choices.splice(index, 1);
  }
}

export class ProcedureQuestionChoice {
  choice: string;
  pos: number;

  constructor(data: any = {}) {
    this.extends(data);
  }

  extends(data: any = {}): ProcedureQuestionChoice {
    Object.assign(this, data);

    return this;
  }
}
