import { Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Media } from '@stuplay';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../../environments/environment";

@Component({
    selector: 'media-doc',
    templateUrl: 'doc.component.html'
})

export class MediaDocComponent implements OnInit, OnChanges {
    @Input() media: Media;
    @Input() downloadable: boolean = true;
    url;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.envVar.STORAGE_URL + this.media.url + ((!this.downloadable) ? '#toolbar=0' : '#toolbar=1'));
    }
}
