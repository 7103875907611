<div class="slide" *ngIf="section">
    <div class="section-presentation">
        <h2 class="heading">
            <span>{{ 'player.section.name' | translate}}</span>
            {{ section.title }}
        </h2>

        <p *ngIf="section.description" class="section-presentation__description" [innerHTML]="section?.description | safe:'html'"></p>

        <div class="section-presentation__media" *ngIf="section?.media" style="text-align: center;">
            <media-holder [media]="section?.media"></media-holder>
        </div>

        <div *ngIf="section?.sectionContents[0]">
            <a class="btn" style="float: right;"
                *ngIf="section?.sectionContents[0]?.context === 'media'"
                [routerLink]="[section?.sectionContents[0]?.context, section?.sectionContents[0][section?.sectionContents[0]?.context].id, 'content', section?.sectionContents[0]?.id]"
                queryParamsHandling="merge">
                {{ 'player.section.presentation.begin' | translate }}
            </a>
            <a class="btn" style="margin-top: 20px; float: right"
                *ngIf="section?.sectionContents[0]?.context !== 'exercise' && section?.sectionContents[0]?.context !== 'media'"
                [routerLink]="navigate(section)"
                queryParamsHandling="merge">
                {{ 'player.section.presentation.begin' | translate }}
            </a>
        </div>

        <div *ngIf="section.sectionContents[0]">
            <a class="btn" style="margin-top: 20px; float: right"
                *ngIf="section?.sectionContents[0]?.context === 'exercise'"
                [routerLink]="['exercise', section?.sectionContents[0]?.exercise?.id, 'question', section?.sectionContents[0]?.exercise?.questions[0]?.id]"
                queryParamsHandling="merge">
                {{ 'player.section.presentation.begin' | translate }}
            </a>
        </div>

        <msc-next-prev-btn *ngIf="!section.sectionContents[0]"></msc-next-prev-btn>
    </div>
</div>

<app-footer [context]="'section'" [contextId]="section?.id"></app-footer>
