<div class="slide survey" *ngIf="!courseSubscriptionSession.doneSurvey">
    <h3 class="heading">
        <span>{{ 'course.survey.title' | translate }}</span> {{ 'course.survey.subtitle' | translate }}
    </h3>
    <div class="survey_group" *ngFor="let group of survey?.surveyGroups; let indexSurveyGroups = index;">
        <div class="survey_group__title" *ngIf="indexSurveyGroups === indexSelected">
            {{ group?.title || ('course.survey.objectives' | translate) }}
            <span class="survey__progress">{{ indexSelected + 1}}/{{ survey.surveyGroups.length}}</span>
        </div>

        <div *ngIf="indexSurveyGroups === indexSelected">
            <div class="survey_question" [ngClass]="'survey_type_'+question.type" *ngFor="let question of group?.surveyQuestions; let indexSurveyQuestions = index;">
                <span class="survey_question__title">
                    {{ question?.title }}
                </span>

                <div class="input" *ngIf="question.type === 'open'">
                    <textarea [(ngModel)]="question.surveyQuestionAnswers[0].content" cols="30" rows="10" (blur)="saveSurveyQuestionAnswer(question, question.surveyQuestionAnswers[0].content, false)"></textarea>
                </div>

                <span class="right" *ngIf="question.type === 'rating'">
                    <label class="input survey_not_applicable">
                        <input type="radio" class="input-radiobox" [checked]="question.surveyQuestionAnswers[0].notApplicable === true" (click)="changeStateNA(question, null, indexSurveyQuestions)">
                        <span>{{ 'course.survey.question.not-applicable' | translate }}</span>
                    </label>

                    <div class="rating">
                        <div class="rating__title" *ngIf="question.surveyQuestionAnswers[0].content">{{ 'course.survey.rating-'+question.surveyQuestionAnswers[0].content | translate }}</div>
                        <div class="rating__title" *ngIf="!question.surveyQuestionAnswers[0].content">{{ 'course.survey.rating-null' | translate }}</div>
                        <div class="rating__items" [ngClass]="{'done' : question.surveyQuestionAnswers[0].content}">
                            <button class="rating__item" (click)="saveSurveyQuestionAnswer(question, 5, false)" [ngClass]="{'selected' : checkRating(question, 5)}"></button>
                            <button class="rating__item" (click)="saveSurveyQuestionAnswer(question, 4, false)" [ngClass]="{'selected' : checkRating(question, 4)}"></button>
                            <button class="rating__item" (click)="saveSurveyQuestionAnswer(question, 3, false)" [ngClass]="{'selected' : checkRating(question, 3)}"></button>
                            <button class="rating__item" (click)="saveSurveyQuestionAnswer(question, 2, false)" [ngClass]="{'selected' : checkRating(question, 2)}"></button>
                            <button class="rating__item" (click)="saveSurveyQuestionAnswer(question, 1, false)" [ngClass]="{'selected' : checkRating(question, 1)}"></button>
                        </div>
                    </div>
                </span>

                <span class="right" *ngIf="question.type === 'truefalse'">
                    <label class="input">
                        <input type="radio" class="input-radiobox" [checked]="question.surveyQuestionAnswers[0].content === true" name="share" (click)="saveSurveyQuestionAnswer(question, true, false)">
                        {{ 'words.yes' | translate }}
                    </label>

                    <label class="input">
                        <input type="radio" class="input-radiobox" [checked]="question.surveyQuestionAnswers[0].content === false" name="share" (click)="saveSurveyQuestionAnswer(question, false, false)">
                        {{ 'words.no' | translate }}
                    </label>
                </span>
            </div>
        </div>
    </div>

    <div class="survey_group" *ngIf="indexSelected === survey?.surveyGroups.length - 1 && !courseSubscriptionSession.doneSurvey">
        <div class="survey_question survey_type_truefalse">
            <span class="survey_question__title">
                <div class="tooltip tooltip_position_bottom">
                    <div class="tooltip__trigger"></div>
                    <div class="tooltip__content">{{ 'course.survey.tooltip.description' | translate }}</div>
                </div>
                {{ 'course.survey.anonymization' | translate }}
            </span>
            <span class="right">
                <label class="input">
                    <input type="radio" [value]="false" class="input-radiobox" name="share" [(ngModel)]="isAnonyme">
                    {{ 'words.yes' | translate }}
                </label>

                <label class="input">
                    <input type="radio" [value]="true" class="input-radiobox" name="share" [(ngModel)]="isAnonyme">
                    {{ 'words.no' | translate }}
                </label>
            </span>
        </div>

        <p>{{ 'course.survey.allow-contact.title' | translate }}
            <span *ngIf="user && user.email">{{ user.email}}</span>
            {{ 'course.survey.allow-contact.description' | translate }}
        </p>
    </div>

    <div class="footer text-right">
        <button class="skip" (click)="openModal(modalSkipSurvey)">{{ 'common.skip' | translate }}</button>
        <button class="btn btn_color_grey btn_height_small" *ngIf="indexSelected > 0" (click)="previousGroup()">{{ 'word.previous' | translate }}</button>
        <button class="btn btn_height_small" *ngIf="indexSelected < survey?.surveyGroups.length - 1" (click)="nextGroup()">{{ 'word.next' | translate }}</button>
        <button class="btn btn_height_small" *ngIf="indexSelected >= survey?.surveyGroups.length - 1" (click)="submitSurvey()">{{ 'word.submit' | translate }}</button>
    </div>
</div>

<div class="slide survey" *ngIf="courseSubscriptionSession.doneSurvey">
    <div class="survey_group">
        <div class="card">
            <div class="card__image">
                <img src="https://static.myskillcamp.com/images/studio/icons/player/illu_survey.svg" alt="">
            </div>

            <div class="card__text" style="margin-bottom: 0 !important;">{{ 'course.survey.title' | translate }}</div>
            <div class="card__title" style="margin-bottom: 2rem; font-size: 1.5rem">{{ 'course.survey.has-answered' | translate }}</div>

            <button class="btn" (click)="redirectToStats()">{{ 'player.course.results' | translate }}</button>
        </div>
    </div>
</div>

<ng-template #modalSkipSurvey let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="media-holder" style="height: 100%">
            <button class="btn btn_color_red" (click)="redirectToStats()">{{ 'course.survey.skip-and-restart' | translate }}</button>
            <button class="btn btn_color_grey" (click)="submitSurvey()">{{ 'course.survey.submit-actual-survey' | translate }}</button>
            <button class="btn" (click)="modal.close()">{{ 'course.survey.continue-survey' | translate }}</button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalSkipSurvey let-modal>
    <div class="modal-dialog modal-full">
        <div class="modal-content">
            <div class="media-holder" style="height: 100%">
                <span style="z-index: 1000" (click)="modal.close()" class="button btn-action_close">+</span>
                <button class="btn btn_color_red" (click)="redirectToStats()">{{ 'course.survey.skip-and-restart' | translate }}</button>
                <button class="btn btn_color_grey" (click)="submitSurvey()">{{ 'course.survey.submit-actual-survey' | translate }}</button>
                <button class="btn" (click)="modal.close()">{{ 'course.survey.continue-survey' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template> -->
