import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { StuplayConfig } from '@stuplay';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'msc-froala',
    templateUrl: './froala.component.html',
    styleUrls: ['./froala.component.scss']
})

export class FroalaComponent {
    @Input() autofocus: boolean = false;
    @Input() content: string | any;
    @Input() editable: boolean = true;
    @Input() height: number = 400;
    @Input() plugins: any;
    @Output() contentChange: EventEmitter<string> = new EventEmitter;

    public options: any;

    private readonly froalaKey: string = 're1H1qC4C2C6E6C5C4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1G4F1C11A8C2C5F5==';

    constructor(
        private stuplayConfig: StuplayConfig,
        private translationService: TranslationService,
    ) { }

    ngOnInit() {
        const onChange = function(content: any) {
            this.onChange(content);
        }.bind(this);

        const isEditable = function() {
            return this.editable;
        }.bind(this);

        this.options = {
            key: this.froalaKey,
            theme: 'custom',
            pluginsEnabled: this.getPlugins(),
            toolbarSticky: false,
            toolbarVisibleWithoutSelection: true,
            charCounterCount: false,
            videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed', 'videoUpload'],
            videoAllowedTypes: ['mp4', 'avi', 'ogg', 'mov', 'flv', 'webm', '3gp', 'wmv', 'mkv'],
            videoMaxSize: 1024 * 1024 * 1024,
            videoUploadURL: `${this.stuplayConfig.envVar.API_URL}/media?froala=1`,
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            imageMaxSize: 100 * 1024 * 1024,
            imageUploadURL: `${this.stuplayConfig.envVar.API_URL}/media?froala=1`,
            requestHeaders: {
                'Authorization': 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName)
            },
            linkAlwaysBlank: true,
            heightMin: this.height,
            language: this.translationService.getCurrentLang(),
            autofocus: this.autofocus,
            pasteAllowedStyleProps: ['font-family', 'font-size', 'color'],
            pasteDeniedAttrs: [],
            events: {
                'initialized': function() {
                    if (!isEditable()) {
                        this.edit.off();
                    }
                },
                'video.inserted': function($video: any) {
                    const source = $video.contents().get(0).src;
                    $video.html('<iframe src="' + source + '" style="border: none; width: 100%"></iframe>');
                    onChange(this.html.get());
                }
            },
        }
    }

    getPlugins(): any {
        if (this.plugins) {
            if (typeof this.plugins === 'object') {
                return this.plugins;
            }

            if (this.plugins === 'normal') {
                return [
                    'colors', 'align', 'fontSize',
                    'fontFamily', 'link', 'paragraphFormat',
                    'table', 'quote', 'lists',
                    'image', 'video', 'codeView'
                ];
            }

            if (this.plugins === 'mini') {
                return [
                    'colors', 'fontSize', 'fontFamily',
                    'link', 'paragraphFormat', 'lists'
                ]
            }
        }

        return [
            'align', 'charCounter', 'codeBeautifier', 'codeView',
            'colors', 'draggable', 'embedly', 'entities',
            'fontFamily', 'fontSize', 'image',
            'lineBreaker', 'link', 'lists', 'paragraphFormat',
            'quickInsert', 'quote', 'table', 'url',
            'video', 'wordPaste'
        ];
    }

    onChange(content): void {
        this.contentChange.emit(content);
    }
}
