import { EventEmitter } from '@angular/core';

export class Swipe {
    private startX: number = 0;
    private startY: number = 0;
    private sensX: number = 80;
    private sensY: number = 40;
    private move: boolean = false;
    private element: any;
    public direction: EventEmitter<any> = new EventEmitter();

    constructor(element: any) {
        this.element = element;
    }

    public run(): any {
        if (this.element) {
            this.element.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
            this.element.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
            this.element.addEventListener('touchend', this.handleToucheEnd.bind(this), false);
        }
        return this;
    }

    private handleTouchStart(event: any): void {
        this.startX = event.changedTouches[0].clientX;
        this.startY = event.changedTouches[0].clientY;
    }

    private handleTouchMove(event: any): void {
        this.move = true;
    }

    private handleToucheEnd(event: any): void {
        const endX = parseInt(event.changedTouches[0].clientX, 10) || 0;
        const endY = parseInt(event.changedTouches[0].clientY, 10) || 0;

        if (this.move && Math.abs(this.startY - endY) <= this.sensY && Math.abs(this.startX - endX) >= this.sensX) {
            if (endX < this.startX) {
                this.direction.emit('left');
            } else {
                this.direction.emit('right');
            }
            this.move = false;
        }
    }
}
