import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Course } from '../course/course';
import { StuplayConfig } from '../stuplay-config';
import { cleanObject } from "../helpers";

export class Folder {
    id: number;
    slug: string;
    courses: Course[];
    folders: Folder[];
    parents: any;
    title: string = '';
    updatedAt: string;
    pagination: any;
    folder: any;
    templatesCount: number;
    foldersCount: number;
    parentId?: number;
    permissions?: any[];
    new?: boolean;
    subfolders?: Folder[];
    editable?: boolean;
    type?: string;

    constructor(folder: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(folder);
    }

    extends(folder: any): Folder {
        Object.assign(this, folder);
        if (this.courses && this.courses.length > 0) {
            this.courses = this.courses.map((course) => new Course(course, this.http, this.stuplayConfig))
        }
        if (this.folders && this.folders.length > 0) {
            this.folders = this.folders.map((folder) => new Folder(folder, this.http, this.stuplayConfig))
        }
        if (this.subfolders && this.subfolders.length > 0) {
            this.subfolders = this.subfolders.map((folder) => new Folder(folder, this.http, this.stuplayConfig))
        }
        return this;
    }

    delete(): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/courses/folder/${this.id}`).pipe(map(() => null));
    }

    update(): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/courses/folder/${this.id}`, cleanObject(this)).pipe(map((folder) => this.extends(folder)));
    }

    addFolder(folder: any): Observable<Folder> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/courses/folder`, folder)
            .pipe(map((res: any) => {
                this.folders.push(new Folder(res.folder, this.http, this.stuplayConfig));
                return this;
            }));
    }
}
