import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Emitter Service
 */
@Injectable()
export class EmitterService {
    /**
     * Emitter collection
     */
    private subjectCollection: BehaviorSubject<any>[] = [];

    /**
     * Get emitter
     * @param {string} key - Emitter key
     * @return {BehaviorSubject<any>} - Emitter
     */
    get(key: string): BehaviorSubject<any> {
        if (!this.subjectCollection[key]) {
            this.set(key, {});
        }
        return this.subjectCollection[key];
    }

    /**
     * Set an emitter
     * @param {string} key - Emitter key
     * @param {any} data - Any data you need
     * @return {BehaviorSubject<any>} - Emitter
     */
    set(key: string, data: any): BehaviorSubject<any> {
        if (!this.subjectCollection[key]) {
            this.subjectCollection[key] = new BehaviorSubject(data);
        } else {
            this.subjectCollection[key].next(data);
        }

        return this.get(key);
    }

    /**
     * Delete an emitter or all emitters
     * @param {string} key - Emitter key
     */
    delete(key?: string): void {
        if (key) {
            delete this.subjectCollection[key];
        } else {
            this.subjectCollection = [];
        }
    }
}
