import { Question } from './questions/question';
import { Observable } from 'rxjs';
import { factoryQuestion } from './questions/question.factory';
import { HttpClient } from '@angular/common/http';
import { StuplayConfig } from '../../../../stuplay-config';
import { map } from 'rxjs/operators';
import { cleanObject } from '../../../../helpers';

export class Exercise {
    id: number;
    position: number;
    exerciseId: number;
    selectedQuestions?: number; //TODO
    questions: Question[] = [];
    randomize: boolean;

    constructor(exercise: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(exercise);
    }

    extends(exercise: any): Exercise {
        Object.assign(this, exercise);
        let exerciseQuestions = (exercise && exercise?.exercise && exercise?.exercise?.questions ? exercise?.exercise?.questions : (exercise && exercise.questions ? exercise?.questions : []));
        if (exerciseQuestions.length > 0) {
            this.questions = exerciseQuestions.map((question) => factoryQuestion(question.type, question, this.http, this.stuplayConfig));
        }

        return this;
    }

    addQuestion(param: any): Observable<Exercise> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/question`, param)
            .pipe(map((question: any) => {
                this.questions.push(factoryQuestion(question.type, question, this.http, this.stuplayConfig));
                return this;
            }));
    }

    getAllQuestion(): Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/question`);
    }

    getQuestion(id: number): Observable<Exercise> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/lesson/exercise/${id}`)
            .pipe(map(res => new Exercise(res, this.http, this.stuplayConfig)));
    }

    deleteQuestion(questionId: number): Observable<void> {
        const selectedQuestionIndex = this.questions.findIndex(question => question?.id === questionId);
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/question/${questionId}`)
            .pipe(map(() => {
                this.questions.splice(selectedQuestionIndex, 1);
                return null;
            }));
    }

    update(): Observable<Exercise> {
        return this.http.put(`${this.stuplayConfig.envVar.STUDIO_URL}/exercise/${this.id}`, cleanObject(this))
            .pipe(map(res => this.extends(res)));
    }
}
