<div class="div-note">
    <a class="btn btn_color_green" style="margin-top: .25rem; font-size: 12px; padding: .5rem 1rem; min-width: 0"
        (click)="update(); closeEditor()">
        <span>{{ 'player.footer.notes.back' | translate }}</span>
    </a>

    <a class="btn btn_color_red" style="margin-top: .25rem; font-size: 12px; padding: .5rem 1rem; min-width: 0"
        (click)="deleteNote()">
        <span>{{ 'player.footer.notes.delete' | translate }}</span>
    </a>
</div>

<live-editor [(inputData)]="note.name" (onUpdate)="update()"
    [placeholder]="('player.note.title' | translate)"></live-editor>

<div [froalaEditor]="options" [(froalaModel)]="note.content"></div>

<style>
    .div-note {
        padding-bottom: 1rem;
        display: flex;
        justify-content: flex-start;
    }

    @media (max-width: 1024px) {
        .div-note {
            padding-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
</style>