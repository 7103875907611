import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StuplayConfig } from "../../../stuplay-config";

@Injectable()
export class SectionContentProvider {

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    deleteQuestion(questionId: number): Observable<any> {
        return this.http.delete(`${this.stuplayConfig.envVar.STUDIO_URL}/question/${questionId}`);
    }

    createSectionContent(id: number, sectionId: number, params: any): Observable<any> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/create/courses/${id}/sections/${sectionId}/contents`, params);
    }

    updatePosition(id: number, sectionId: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/create/courses/${id}/sections/${sectionId}/contents/position`, params);
    }
}
