<div class="slide slide_presentation slide_video">
    <div class="open-fullscreen" (click)="openModal(modalFullScreen)">
        <img src="https://static.myskillcamp.com/images/icon/icon-player/icon_fullscreen_on.svg" alt="">
    </div>

    <div class="column-left">
        <h2 class="heading">
            <span>{{ 'player.cheatsheet.name' | translate }}</span> {{sectionContent?.title}}
        </h2>
        <div [froalaView]="cheat.content"></div>
        <msc-next-prev-btn (isFinished)="activityFinished()" [validate]="true" [doneAt]="sectionContentSession?.doneAt" [loading]="loading"></msc-next-prev-btn>
    </div>
</div>

<ng-template #modalFullScreen let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="media-holder froala-modal">
            <div [froalaView]="cheat.content"></div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #modalFullScreen let-modal>
    <div class="modal-dialog modal-full">
        <div class="modal-content">
            <span style="z-index: 1000" (click)="modal.close()" class="button btn-action_close">+</span>
            <div class="media-holder froala-modal">
                <div [froalaView]="cheat.content"></div>
            </div>
        </div>
    </div>
</ng-template> -->

<app-footer [context]="'cheatsheet'" [contextId]="cheat?.id"></app-footer>

<app-timer [timerService]="timerService" (close)="timerInit()"></app-timer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
