<div>
    <div class="upload" *ngIf="!files[0]">
        <label class="upload__placeholder" style="cursor: pointer;">
            <div class="title">
                {{ 'studio.media.upload.title' | translate }}
            </div>
            <div class="content">
                <span>{{ 'studio.media.import.hint' | translate}}</span>
                <br />
                <span>{{ 'studio.media.upload.max-weight' | translate }}</span>
                <br />
                <input style="text-align: center; width: auto; margin-top: 1rem;" type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
            </div>
        </label>
    </div>
    <div class="upload upload_processing" *ngIf="files[0]">
        <h2 class="heading heading_border_none">{{ files[0].name }}</h2>
        <div class="progress">
            <div class="progress__text">{{ (files[0]?.progress.data.percentage || 0)+'%' }}</div>
            <div class="progress-bar">
                <div class="progress-bar__progress" [ngStyle]="{'width': (files[0]?.progress.data.percentage || 0)+'%'}"></div>
            </div>
        </div>
        {{ files[0]?.response?.message }}
    </div>
</div>
