<div id="login-bloc" *ngIf="!isAuth">
    <div class="signup">
        <form (ngSubmit)="login()">
            <div>
                <div class="signup__heading">{{ 'player.login.login' | translate }}</div>
            </div>

            <div>
                <label for="email">{{ 'player.login.placeholder.email' | translate }}</label>
                <input id="email" name="email" type="email" [(ngModel)]="credentials.email">
            </div>

            <div *ngIf="writePassword">
                <label for="password">{{ 'player.login.placeholder.password' | translate }}</label>
                <input id="password" name="password" type="password" [(ngModel)]="credentials.password">
            </div>

            <div class="show_margin-top_2">
                <button class="btn btn_width_full" [disabled]="(!writePassword && !credentials.email) || (writePassword && (!credentials.email || !credentials.password))">
                    <div class="lds-ring" *ngIf="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ 'player.login.login' | translate }}</span>
                </button>
            </div>
        </form>
    </div>
</div>
