import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ToastService {
    private toast: EventEmitter<any> = new EventEmitter;

    constructor() {}

    listen(): EventEmitter<any> {
        return this.toast;
    }

    push(message: string, type: string = 'success', duration: number = 5000, redirect: string = null): void {
        this.toast.emit({message: message, type: type, duration: duration, redirect: redirect});
    }
}
