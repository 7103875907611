import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Section } from './section';
import { StuplayConfig } from '../../stuplay-config';

@Injectable()
export class SectionProvider {
    defaultLang: string;

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig) {}

    getSectionsContentList():Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section-content`)
    }

    getSections():Observable<any> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/section`);
    }

    getSection(id: number): Observable<Section> {
        return this.http.get(`${this.stuplayConfig.envVar.STUDIO_URL}/course/${id}/section`)
            .pipe(map((data) => new Section(data, this.http, this.stuplayConfig)))
    }

    createSection(section: any): Observable<Section> {
        return this.http.post(`${this.stuplayConfig.envVar.STUDIO_URL}/course/${section.courseId}/section`, section)
            .pipe(map((res) => new Section(res, this.http, this.stuplayConfig)))
    }

    updatePosition(id: number, params: any): Observable<any> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/create/courses/${id}/sections/position`, params);
    }
}
