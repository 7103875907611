import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { SurveyGroup } from './survey-groups/survey-groups';

export class Survey {
    id: number;
    title: string;
    surveyGroups: SurveyGroup[] = [];
    isAnonyme: boolean;

    constructor(survey: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(survey);
    }

    extends(survey: any): Survey {
        Object.assign(this, survey);
        if (this.surveyGroups && this.surveyGroups.length > 0) {
            this.surveyGroups = this.surveyGroups.map((surveyGroup) => new SurveyGroup(surveyGroup, this.http, this.stuplayConfig));
        }
        return this;
    }

    submit(data: any): Observable<Survey> {
        return this.http.post(`${this.stuplayConfig.envVar.API_URL}/survey/${this.id}/submit`, data).pipe(map(res => this.extends(res)));
    }
}
