declare var require: any;

export class LoadFile {
    private fileLoaded: any[] = [];

    constructor() {
        this.fileLoaded['en'] = require('../../../assets/i18n/en.json');
        this.fileLoaded['de'] = require('../../../assets/i18n/de.json');
        this.fileLoaded['fr'] = require('../../../assets/i18n/fr.json');
        this.fileLoaded['it'] = require('../../../assets/i18n/it.json');
        this.fileLoaded['nl'] = require('../../../assets/i18n/nl.json');
        this.fileLoaded['es'] = require('../../../assets/i18n/es.json');
    }

    get(job: string): any {
        return this.fileLoaded[job];
    }

    getAll(): any {
        return this.fileLoaded;
    }
}
