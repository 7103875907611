<div class="slide slide_presentation">
    <h2 class="heading">
        <span>{{ 'player.lti.name' | translate }}</span>{{ sectionContent?.title }}
    </h2>

    <p class="event-presentation__description multiline" [innerHTML]="sectionContent.description | safe:'html'"></p>

    <div (click)='launchLti(modal)' class="card card-background">
        <div class="card__text" style="margin: auto;">
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="56px" height="56px" viewBox="0 0 24 24" aria-labelledby="videoIconTitle videoIconDesc" stroke="#5b70ff" stroke-width="1.2857142857142858" stroke-linecap="square"
                stroke-linejoin="miter" fill="none" color="#5b70ff">
                <polygon points="18 12 9 16.9 9 7" />
                <circle cx="12" cy="12" r="10" />
            </svg>
        </div>
    </div>

    <msc-next-prev-btn></msc-next-prev-btn>
</div>

<ng-template #modal let-modal>
    <div class="modal-header">
        <span></span>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="lti?.mode === 'embed'">
            <div class="modal-scorm media-holder" style="height: 100%;">
                <div style="display: flex; justify-content: space-around; margin-top: 1rem">
                    <span>{{ 'player.course-end.time-passed' | translate }} : {{ internalTime | duration: true }}</span>
                    <button *ngIf="sectionContentSession?.progress !== 100" class="btn" (click)="finishLti()">
                        {{ 'player.common.ended-activity' | translate }}
                    </button>
                </div>

                <iframe width="100%" height="100%" allowfullscreen frameborder="0" name="embed-iframe"></iframe>
                <div id="embed-code"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="lti?.mode === 'blank'">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <span style="margin-bottom: 1rem">{{ 'player.course-end.time-passed' | translate }} : {{ internalTime | duration: true }}</span>
                <button *ngIf="sectionContentSession.progress !== 100" class="btn" (click)="finishLti()">
                    {{ 'player.common.ended-activity' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</ng-template>

<app-footer [context]="'external_link'" [contextId]="lti?.id"></app-footer>

<style>
    @media only screen and (min-width: 320px) {
        .slide {
            margin: 1rem 0 0.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide {
            margin: 8rem 0 1.5rem;
            width: 100%;
        }
    }
</style>
