import { Component, EventEmitter } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { OrderedQuestion, OrderedQuestionSession } from '@stuplay';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../../../utils/components/timer/timer.service';
import { StorageService } from 'src/app/utils/services/storage.service';

@Component({
    selector: 'msc-ordered',
    templateUrl: 'ordered.component.html',
})

export class OrderedComponent {
    question: OrderedQuestion;
    questionSession: OrderedQuestionSession;
    questionSessionChange: EventEmitter<OrderedQuestionSession> = new EventEmitter();
    answers = [];
    subscription: Subscription;

    constructor(
        public timerService: TimerService,
        private storageService: StorageService,
    ) { }

    ngOnInit() {
        this.timerInit();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.question.param.type === 'none' && this.questionSession.updatedAt) {
            this.initAnswerInInput();
        }
    }

    ngOnDestroy() {
        if (this.subscription && !this.questionSession.doneAt) {
            this.questionSession.update({ company_id: this.storageService.get('company')?.id }).subscribe();
        }
    }

    initAnswerInInput() {
        this.answers = this.questionSession.answer.choices.map((choice) => {
            return this.questionSession.updatedAt ? choice.choice.join(' ') : '';
        })
    }

    isAnswerSuccess(choice: any): boolean {
        if (this.questionSession.doneAt) {
            return choice.isCorrect;
        }
        return false;
    }

    isAnswerFailed(choice: any): boolean {
        if (this.questionSession.doneAt) {
            return !choice.isCorrect;
        }
        return false;
    }

    onChange(index?: number) {
        if (typeof index === 'number') {
            this.questionSession.answer.choices[index].choice = this.answers[index].split(' ');
        }

        this.questionSessionChange.emit(this.questionSession);
    }

    trackByFn(index: any) {
        return index;
    }

    cursorDisable() {
        if (this.questionSession.doneAt) {
            return 'not-allowed';
        }
    }

    timerInit(): void {
        this.timerService.setTimer(this.questionSession, 'question');
    }

    dropped(event: CdkDragDrop<any>, list) {
        moveItemInArray(list, event.previousContainer.data.index, event.container.data.index);
        this.onChange();
    }
}
