import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StuplayConfig } from '../stuplay-config';
import { cleanObject } from "../helpers";

export class Note {
    id: number;
    name: string;
    content: string;

    constructor(note: any, private http: HttpClient, private stuplayConfig: StuplayConfig) {
        this.extends(note);
    }

    extends(note: any) {
        Object.assign(this, note);
        return this;
    }

    update(): Observable<Note> {
        return this.http.put(`${this.stuplayConfig.envVar.API_URL}/note/${this.id}`, cleanObject(this)).pipe(map(() => null));
    }

    delete(id: number): Observable<void> {
        return this.http.delete(`${this.stuplayConfig.envVar.API_URL}/note/${this.id}`).pipe(map(() => null));
    }
}
