import { StuplayConfig } from '@stuplay';
import { environment } from '../environments/environment';

export function stuplayConfigFactory() {
    const stuplayConfig = new StuplayConfig();
    stuplayConfig.envVar.API_URL = environment.envVar.API_URL;
    stuplayConfig.envVar.STUDIO_URL = environment.envVar.API_URL + '/studio';
    stuplayConfig.envVar.CLOUD_URL = environment.envVar.CLOUD_URL;
    return stuplayConfig;
};

export let stuplayConfigProvider = {
    provide: StuplayConfig,
    useFactory: stuplayConfigFactory
};
