import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { StuplayConfig, Media } from '@stuplay';

@Component({
    selector: 'library-embed',
    templateUrl: './library-embed.component.html'
})
export class LibraryEmbedComponent implements OnInit {
    @Input() media: Media;
    @Output() updateMedia = new EventEmitter();
    url: string = '';
    embed: string = '';
    trustedUrl: any;
    cleanUrl: string = '';
    sites: any;

    constructor(public http: HttpClient, private stuplayConfig: StuplayConfig, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.sites = {
            'youtube': {
                'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
                'reg': /^watch\?v=([\w-_]*)/gi
            },
            'youtu': {
                'url': '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
                'reg': /^watch\?v=([\w-_]*)/gi
            },
            'vimeo'  : {
                'url'    : '//player.vimeo.com/video/{id}?dnt=1',
                'reg'    : /^(.*)/
            },
            'dailymotion': {
                'url': '//www.dailymotion.com/embed/video/{id}',
                'reg': /^video\/([\w_-]*)/gi
            },
            'scratch': {
                'url': 'http://scratch.mit.edu/projects/{id}/#editor',
                'reg': /^projects\/(\d*)/gi
            }
        };
    }

    onUpdatedMedia(media) {
        this.updateMedia.emit(media);
    }

    preview(type: string): void {
        if (type === 'embed') {
            const re = /src=[\"\''](?:https:|http:)?((?:\S[^\"\']*){1})/i;
            const result = re.exec(this.embed);

            try {
                this.cleanUrl = 'https:' + result[1];
                this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cleanUrl);
            } catch (e) {
                // nothing
            }
        } else {
            const re = /^(?:https:|http:)?\/\/(?:www\.)?([\w-]*)\.(?:[^\s\/]*)\/(.*)/i;
            const result = re.exec(this.url);

            try {
                if (this.sites[result[1]]) {
                    const url = this.sites[result[1]].url;
                    const id = this.sites[result[1]].reg.exec(result[2]);
                    this.cleanUrl = url.replace('{id}', id[1]);
                    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cleanUrl);
                } else {
                }
            } catch (e) {
                // nothing
            }
        }
    }

    createEmbed() {
        if (this.cleanUrl) {
            this.http.post(`${this.stuplayConfig.envVar.API_URL}/media/embed`, { url: this.cleanUrl })
                .subscribe(data => {
                    this.url = '';
                    this.embed = '';
                    this.onUpdatedMedia(data);
                });
        }
    }
}
