import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

const EXPIRED_TOKEN: string = 'expired_token';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

    isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(private authService: AuthService, private redirectService: RedirectService, private cookieService: CookieService) { }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            return this.authService.refresh().pipe(
                switchMap((auth: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(auth.access_token);
                    return next.handle(this.addToken(request, auth.access_token));
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addToken(request, token));
                })
            );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    if (this.cookieService.get(environment.refreshTokenName) && (err.error === EXPIRED_TOKEN || err.error.message === EXPIRED_TOKEN)) {
                        return this.handle401Error(request, next);
                    } else {
                        this.authService.logout(true);
                        return EMPTY;
                    }
                } else if (err.status === 503) {
                    this.redirectService.navigate('app', ['maintenance']);
                    return EMPTY;
                }

                return throwError(err);
            })
        );
    }
}
